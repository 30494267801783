import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {GET_ANNUAL_INCOME_BY_ID} from 'src/app/queries/api/apiEndPoints/EndPoints'

const getAnnualIncomeById = async (id: string) => {
  try {
    const response = await axios.get(`${GET_ANNUAL_INCOME_BY_ID}`.replace(':id', id))
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const useGetAnnualIncomeById = (
  setInitialData: React.Dispatch<React.SetStateAction<any | undefined>>
) => {
  return useMutation((id: string) => getAnnualIncomeById(id), {
    onSuccess: (response) => {
      if (response?.resCod == 200) {
        setInitialData(response?.data)
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default useGetAnnualIncomeById
