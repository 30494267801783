import {Flex, Spin, Typography} from 'antd'
import {Form, Formik} from 'formik'
import {useEffect} from 'react'
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters} from 'react-query'
import {Toggle} from 'rsuite'
import Modal from 'rsuite/Modal'
import usePostAnnualIncome from 'src/app/queries/getMasterData/annualIncomes/post/usePostNewAnnualIncome'
import usePutAnnualIncome from 'src/app/queries/getMasterData/annualIncomes/put/usePutAnnualIncome'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
const {Title} = Typography
interface IAnnualIncome {
  id?: string
  title: string
  cbs_code: string
  status: boolean
  on_acc_type?: any
  order?: number
  is_annual_income: any
  is_annual_turnover: any
}
type Props = {
  isOpen: boolean
  handleClose: () => void
  modalStatus: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  initialData: IAnnualIncome | undefined
  isLoadingData: boolean
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>
}

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  cbs_code: Yup.string()
    .matches(
      /^[,A-Za-z0-9./-]+$/,
      'Code can only contain letters, numbers, hyphens, and forward slashes'
    )
    .required('CBS Code is required'),
  order: Yup.number().typeError('Order must be a number').required('Order is required'),
  status: Yup.string().required('Status is required'),
  on_acc_type: Yup.array().required('Account Type is required'),
  is_annual_income: Yup.boolean().required('Annual Income is required'),
  is_annual_turnover: Yup.boolean().required('Annual Turnover is required'),
})

// "is_annual_income": true,
//   "is_annual_turnover": true,
const AnnualIncomeModal = ({
  isOpen,
  handleClose,
  modalStatus,
  setOpen,
  initialData,
  isLoadingData,
  refetch,
}: Props) => {
  const {mutate: mutateAdd, isLoading: isLoadingAdd} = usePostAnnualIncome(setOpen, refetch)
  const {mutate: mutateEdit, isLoading: isLodingEdit} = usePutAnnualIncome(setOpen, refetch)

  const handleSubmitData = (values: any) => {
    let payloadData = {...values, on_acc_type: values.on_acc_type?.toString()}
    if (modalStatus === 'Edit') {
      mutateEdit({payload: payloadData, id: initialData?.id as string})
    } else {
      mutateAdd(payloadData)
    }
  }

  const {allMasterDataOptions} = usePlaceMasterData({
    allMasterData: true,
  })

  return (
    <div className='modal-container'>
      <Modal
        open={isOpen}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>
            {modalStatus === 'Add'
              ? 'Add Annual Income'
              : modalStatus === 'Edit'
              ? 'Edit Annual Income'
              : 'View Annual Income'}{' '}
          </Modal.Title>
        </Modal.Header>
        <Spin spinning={isLoadingData}>
          <Modal.Body>
            <div>
              <Formik
                initialValues={{
                  title: '',
                  cbs_code: '',
                  status: true,
                  order: 0,
                  on_acc_type: [],
                  is_annual_income: false,
                  is_annual_turnover: false,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={handleSubmitData}
              >
                {({touched, handleChange, errors, setFieldValue, values, setValues}: any) => {
                  useEffect(() => {
                    if (modalStatus === 'Edit' && initialData) {
                      setValues({
                        title: initialData?.title,
                        cbs_code: initialData?.cbs_code,
                        status: initialData?.status,
                        order: initialData?.order ?? 0,
                        on_acc_type: Array.isArray(initialData?.on_acc_type)
                          ? initialData.on_acc_type.map((item: any) => item.id)
                          : [],
                        is_annual_income: initialData?.is_annual_income ?? false,
                        is_annual_turnover: initialData?.is_annual_turnover ?? false,
                      })
                    } else {
                      setValues({
                        title: '',
                        cbs_code: '',
                        status: true,
                        order: 0,
                        on_acc_type: [],
                        is_annual_income: false,
                        is_annual_turnover: false,
                      })
                    }
                  }, [initialData, modalStatus, setValues])
                  return (
                    <Form>
                      {console.log(errors)}
                      <div className='card-body border-top'>
                        <div className='row'>
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormTextBox
                              containerClassName='col-lg-12'
                              labelClassName='col-lg-12'
                              type='text'
                              placeholder='Enter Title '
                              label='Title'
                              name='title'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                            />
                          </div>
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormTextBox
                              containerClassName='col-lg-12'
                              labelClassName='col-lg-12'
                              type='text'
                              placeholder='Enter CBS Code'
                              label='Code'
                              name='cbs_code'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                            />
                          </div>

                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              placeholder='Select Account Type'
                              label='Account Type'
                              name='on_acc_type'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allMasterDataOptions?.account_types?.map(
                                (categories: any) => ({
                                  value: categories.id || categories.value,
                                  label: categories.title || categories.label,
                                })
                              )}
                              values={values}
                              setFieldValue={setFieldValue}
                              required
                              multiple
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-xs-12 '>
                          <div className='d-flex align-items-center pt-5'>
                            <FormRadio
                              containerClassName=''
                              label='Is Annual Income ?'
                              name='is_annual_income'
                              type='checkbox'
                              onChange={(e: any) => {
                                setFieldValue('is_annual_income', e.target.checked)
                              }}
                              errors={errors}
                              touched={touched}
                              checked={values?.is_annual_income}
                              checkBoxText='Is Annual Income ?'
                            />
                          </div>
                          <div className='d-flex align-items-center pt-5'>
                            <FormRadio
                              containerClassName=''
                              label='is Annual Turnover ?'
                              name='is_annual_turnover'
                              type='checkbox'
                              onChange={(e: any) => {
                                setFieldValue('is_annual_turnover', e.target.checked)
                              }}
                              errors={errors}
                              touched={touched}
                              checked={values?.is_annual_turnover}
                              checkBoxText='is Annual Turnover? '
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <Flex vertical>
                            <Title level={5}>Status</Title>
                            <Toggle
                              size='md'
                              checked={values.status}
                              data-cy='toggle-button'
                              onChange={(value) => setFieldValue('status', value)}
                            />
                          </Flex>
                        </div>
                      </div>

                      <div className='d-flex justify-content-end px-9 '>
                        <button
                          type='submit'
                          // disabled={isLoading}
                          className='btn btn-primary btn-sm ms-3'
                          data-cy='modal-submit'
                        >
                          {isLoadingAdd || isLodingEdit ? (
                            <>
                              <span
                                className='spinner-border spinner-border-sm me-2'
                                role='status'
                                aria-hidden='true'
                              ></span>
                              {modalStatus === 'Add'
                                ? 'Saving...'
                                : modalStatus === 'Edit'
                                ? 'Updating...'
                                : ''}
                            </>
                          ) : (
                            'Save'
                          )}
                        </button>

                        <button
                          type='button'
                          onClick={handleClose}
                          className='btn btn-secondary btn-sm ms-3'
                          data-cy='modal-cancel'
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </Modal.Body>
        </Spin>
      </Modal>
    </div>
  )
}
export default AnnualIncomeModal
