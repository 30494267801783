import React, {useEffect, useState} from 'react'
import {FieldArray, useFormikContext} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import {FaPlus} from 'react-icons/fa'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'

const yesNoOptions = [
  {value: 'Yes', label: 'Yes'},
  {value: 'No', label: 'No'},
]

const FATCADeclarationsForm = ({values, handleChange, errors, touched, setFieldValue}: any) => {
  const {allMasterDataOptions, isLoading} = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  const {setFieldValue: setFieldData} = useFormikContext()

  useEffect(() => {
    if (values?.facta_declarations?.length === 0) {
      setFieldData('facta_declarations', [
        {
          us_person: 'No',
          us_indicia: '',
          us_telephone_number: '',
          us_citizenship: '',
          us_birth_country: '',
          us_residence: '',
          tin_number: '',
          w9_form_submitted: '',
          joint_us_person: 'No',
          joint_us_indicia: '',
          joint_us_telephone_number: '',
          joint_us_citizenship: '',
          joint_us_birth_country: '',
          joint_us_residence: '',
          joint_tin_number: '',
          joint_w9_form_submitted: '',
        },
      ])
    }
  }, [setFieldData, values.facta_declarations])

  return (
    <div className=''>
      <div>
        <FieldArray name='facta_declarations'>
          {({push}) => (
            <div>
              {values?.facta_declarations?.length > 0 &&
                values?.facta_declarations?.map((declaration: any, index: any) => {
                  const isUSPerson = declaration.us_person === 'Yes'
                  const isJointPerson = declaration.us_person === 'Yes'

                  return (
                    <div className='row mb-3' key={index}>
                      <div className='col-md-6'>
                        <FormSelect
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          placeholder='US Person'
                          name={`facta_declarations.${index}.us_person`}
                          label='US Person'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          arrValue={values?.facta_declarations[index]?.us_person}
                          options={yesNoOptions}
                          setFieldValue={setFieldValue}
                          required
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormTextBox
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          type='text'
                          placeholder='US Indicia'
                          name={`facta_declarations.${index}.us_indicia`}
                          label='US Indicia'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          value={values?.facta_declarations[index]?.us_indicia}
                          required={isUSPerson}
                          maxLength={50}
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormTextBox
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          type='text'
                          placeholder='US Telephone Number'
                          name={`facta_declarations.${index}.us_telephone_number`}
                          label='US Tel Number'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          value={values?.facta_declarations[index]?.us_telephone_number}
                          required={isUSPerson}
                          maxLength={10}
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormSelect
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          placeholder='US Citizenship'
                          name={`facta_declarations.${index}.us_citizenship`}
                          label='US Citizenship'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          arrValue={values?.facta_declarations[index]?.us_citizenship}
                          options={yesNoOptions}
                          setFieldValue={setFieldValue}
                          required={isUSPerson}
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormSelect
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          placeholder='US Birth Country'
                          name={`facta_declarations.${index}.us_birth_country`}
                          label='US Birth Country'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          arrValue={values?.facta_declarations[index]?.us_birth_country}
                          options={yesNoOptions}
                          setFieldValue={setFieldValue}
                          required={isUSPerson}
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormSelect
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          placeholder='US Residence'
                          name={`facta_declarations.${index}.us_residence`}
                          label='US Residence'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          arrValue={values?.facta_declarations[index]?.us_residence}
                          options={yesNoOptions}
                          setFieldValue={setFieldValue}
                          required={isUSPerson}
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormTextBox
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          type='text'
                          placeholder='TIN Number'
                          name={`facta_declarations.${index}.tin_number`}
                          label='TIN Number'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          value={values?.facta_declarations[index]?.tin_number}
                          required={isUSPerson}
                          maxLength={10}
                        />
                      </div>
                      <div className='col-md-6'>
                        <FormSelect
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          placeholder='W9 Form Submitted'
                          name={`facta_declarations.${index}.w9_form_submitted`}
                          label='W9 Form Submitted'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          arrValue={values?.facta_declarations[index]?.w9_form_submitted}
                          options={yesNoOptions}
                          setFieldValue={setFieldValue}
                          required={isUSPerson}
                        />
                      </div>

                      {/* Joint fields */}
                      {allMasterDataOptions?.account_types
                        ?.filter((type: {title: string}) =>
                          ['Individual', 'Joint', 'Minor']?.includes(type.title)
                        )
                        ?.map(({id, title}: any) => (
                          <>
                            {values?.account_type_id === id && title === 'Joint' && (
                              <>
                                <div className='col-md-12'>
                                  <h2>Required For Joint Account</h2>
                                </div>
                                <div className='col-md-6'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Joint US Person'
                                    name={`facta_declarations.${index}.joint_us_person`}
                                    label='Joint US Person'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    arrValue={values?.facta_declarations[index]?.joint_us_person}
                                    options={yesNoOptions}
                                    setFieldValue={setFieldValue}
                                    required
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Joint US Indicia'
                                    name={`facta_declarations.${index}.joint_us_indicia`}
                                    label='Joint US Indicia'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    value={values?.facta_declarations[index]?.joint_us_indicia}
                                    required={isJointPerson}
                                    maxLength={50}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Joint US Tel Number'
                                    name={`facta_declarations.${index}.joint_us_telephone_number`}
                                    label='Joint US Tel Number'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    value={
                                      values?.facta_declarations[index]?.joint_us_telephone_number
                                    }
                                    required={isJointPerson}
                                    maxLength={10}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Joint US Citizenship'
                                    name={`facta_declarations.${index}.joint_us_citizenship`}
                                    label='Joint US Citizenship'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    arrValue={
                                      values?.facta_declarations[index]?.joint_us_citizenship
                                    }
                                    options={yesNoOptions}
                                    setFieldValue={setFieldValue}
                                    required={isJointPerson}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Joint US Birth Country'
                                    name={`facta_declarations.${index}.joint_us_birth_country`}
                                    label='US Birth Country'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    arrValue={
                                      values?.facta_declarations[index]?.joint_us_birth_country
                                    }
                                    options={yesNoOptions}
                                    setFieldValue={setFieldValue}
                                    required={isJointPerson}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Joint US Residence'
                                    name={`facta_declarations.${index}.joint_us_residence`}
                                    label='Joint US Residence'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    arrValue={values?.facta_declarations[index]?.joint_us_residence}
                                    options={yesNoOptions}
                                    setFieldValue={setFieldValue}
                                    required={isJointPerson}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Joint TIN Number'
                                    name={`facta_declarations.${index}.joint_tin_number`}
                                    label='Joint TIN Number'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    value={values?.facta_declarations[index]?.joint_tin_number}
                                    required={isJointPerson}
                                    maxLength={15}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='W9 Form Submitted'
                                    name={`facta_declarations.${index}.joint_w9_form_submitted`}
                                    label='W9 Form Submitted'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    arrValue={
                                      values?.facta_declarations[index]?.joint_w9_form_submitted
                                    }
                                    options={yesNoOptions}
                                    setFieldValue={setFieldValue}
                                    required={isJointPerson}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        ))}
                    </div>
                  )
                })}
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  )
}

export default FATCADeclarationsForm
