// import { FC, useEffect, useState } from 'react'
// import { useIntl } from 'react-intl'

// import { PageTitle } from 'src/cms/layout/core'
// import { ISettingTypeState } from 'src/app/modules/siteSettings/components/settings'
// import { useDispatch, useSelector } from 'react-redux'
// import { isEmpty } from 'lodash'
// import * as key from 'src/app/modules/siteSettings/components/keySetting'

// interface Props {
//   applicationName: string | null
//   gTrackCode: any
// }

// const DashboardPage = ({ applicationName, gTrackCode }: Props) => {
//   const dispatch = useDispatch()
//   const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
//   return (
//     <>
//       <div className='card mb-5 mb-xl-10'>
//         <div className='card-body pt-9 pb-9'>
//           <span className='d-flex text-gray-800 text-hover-primary justify-content-center fs-2 fw-bolder me-1'>
//             <h1> Welcome to {applicationName}</h1>
//           </span>
//         </div>
//       </div>
//     </>
//   )
// }

// const DashboardWrapper: FC = () => {
//   const intl = useIntl()
//   const dispatch = useDispatch()
//   const [color, setColor] = useState({ r: 255, g: 255, b: 255, a: 1 })
//   const [applicationName, setAplicationName] = useState('')
//   const keyData: any = useSelector((state: any) => state.key)

//   const gaTrackId = keyData?.data?.keySetting.filter(
//     (item: any) => item?.name === 'GA-Tracking-ID'
//   )[0]?.key

//   const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
//   const onChange = (color: any) => {
//     setColor(color.rgb)
//   }
//   useEffect(() => {
//     dispatch(key.actions.getKey())
//   }, [])

//   useEffect(() => {
//     if (!isEmpty(settingTypeData?.backendData)) {
//       settingTypeData?.backendData &&
//         settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
//           if (item?.name === 'applicationName') {
//             setAplicationName(item?.value)
//           }
//         })
//     }
//   }, [settingTypeData])

//   return (
//     <>
//       <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
//       <DashboardPage applicationName={applicationName} gTrackCode={gaTrackId} />
//     </>
//   )
// }

// export { DashboardWrapper }
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import {isEmpty} from 'lodash'
import {key} from 'src/app/modules/siteSettings'
import CIFCheck from './component.tsx/CIFCheck'
import useGetMasterData from 'src/hooks/PostMasterData/UsePostGetMasterData'
import {Flex, Select} from 'antd'
import {useGetCount} from 'src/hooks/getDashBoardCount/useGetDashBoardCount'
import Individual from './Individual'
import OtherFilter from './OtherFilter'
import {Spinner} from 'react-bootstrap-v5'
const DashboardWrapper = () => {
  const dispatch = useDispatch()
  let timeoutId: NodeJS.Timeout | null = null

  const intl = useIntl()
  const [applicationName, setAplicationName] = useState('')
  const [color, setColor] = useState({r: 255, g: 255, b: 255, a: 1})
  const keyData: any = useSelector((state: any) => state.key)
  // let hasDispatched = false
  // const {allMasterDataOptions, isLoading} = usePlaceMasterData({
  //   allMasterData: true,
  // })
  const gaTrackId = keyData?.data?.keySetting.filter(
    (item: any) => item?.name === 'GA-Tracking-ID'
  )[0]?.key

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
  const onChange = (color: any) => {
    setColor(color.rgb)
  }
  useEffect(() => {
    dispatch(key.actions.getKey())
  }, [])

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
          if (item?.name === 'applicationName') {
            setAplicationName(item?.value)
          }
        })
    }
  }, [settingTypeData])
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp

  const option = [
    'NewCIFNewAccount',
    'NewCIF',
    'ExistingCIFNewAccount',
    'LinkedEntity',
    'UpdateKYC',
    'AccountClosure',
    'AccountMaintenance',
    'OnlineAccountOpening',
  ]
  const [individualCount, SetIndividualCount] = useState({model_name: 'NewCIFNewAccount'})
  const {data: counIndividualData, isLoading: isCountLoading} = useGetCount(individualCount)
  const [show, setShow] = useState<boolean>(true)
  const [linked, setLinked] = useState<boolean>(true)

  const {mutate} = useGetMasterData()

  useEffect(() => {
    let masterData = localStorage.getItem('allMasterDataOptions')
    if (masterData) {
      return
    } else {
      mutate()
    }
  }, [])

  const handleChange = (value: string) => {
    SetIndividualCount({model_name: value})
    if (value === 'NewCIF' || value === 'UpdateKYC') {
      setShow(false)
    } else {
      setShow(true)
      value === 'LinkedEntity' ? setLinked(false) : setLinked(true)
    }
  }
  const formattedOptions = option.map((item) => ({
    value: item,
    label: item,
  }))

  return (
    <div>
      <div className='heading'>
        <h1>Welcome to {applicationName} </h1>
        <CIFCheck />
      </div>
      <h3 className='g-0 border-bottom border-danger heading__component__title fw-bolder mb-5 pb-3'>Choose To View Count</h3>
      <Select
        defaultValue='NewCIFNewAccount'
        style={{width: 400, height: '45px'}}
        onChange={handleChange}
        options={formattedOptions}
      />{' '}
      <hr />
      {isCountLoading ? (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body text-center '>
            <h3>
              <Spinner animation='border' variant='danger' className='mb-3' />
              <br />
              Please Wait...
            </h3>
          </div>
        </div>
      ) : counIndividualData?.data?.corporate ? (
        <Individual counIndividualData={counIndividualData} show={show} linked={linked} />
      ) : (
        <OtherFilter counIndividualData={counIndividualData} individualCount={individualCount} />
      )}
    </div>
  )
}

export {DashboardWrapper}
