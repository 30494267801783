import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import * as Yup from 'yup'
import moment from 'moment'

export const CORPORATE_FORM_VALIDATION = Yup.object().shape({
  institution_name: Yup.string().required('Institution Name is required'),
  constitution: Yup.string().required('Constitution is required'),
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  registration: Yup.string().required('Registration No. is required'),
  registered_with: Yup.string().required('Registered With is required'),
  regis_date: Yup.date().required('Date of Registration is required'),
  Regis_Date_in_BS: Yup.date().required('Date of Registration (BS) is required'),
  Date_in_BS: Yup.date().required('Registration Valid Upto (BS) is required'),
  house_no: Yup.string().required('House No. is required'),
  tole: Yup.string().required('Tole is required'),
  district: Yup.string().required('District is required'),
  municipality: Yup.string().required('Municipality is required'),
  ward_no: Yup.string()
    .required('The ward number number is required.')
    .min(0, 'The ward number must be a positive number.'),
  phone_no: Yup.string().required('Phone No. is required'),
  fax_number: Yup.string().required('Fax Number is required'),
  mail_id: Yup.string().required('Email is required'),
  website: Yup.string().url('Invalid URL').required('Website is required'),
  area: Yup.string().required('Working Area is required'),
  nature_of_business: Yup.string().required('Nature of Business is required'),
  list_of_offices: Yup.string().required('List of Offices is required'),
  branches: Yup.string().required('Number of Branches is required'),
  pan_number: Yup.string().when('status', {
    is: 'yes',
    then: Yup.string()
      .required('The PAN number is required.')
      .matches(/^\d{9}$/, 'PAN number must be exactly 9 digits and should not have characters.')
      .max(9, 'PAN number must be 9 characters long.'),
  }),

  vat_number: Yup.string().when('status', {
    is: 'yes',
    then: Yup.string()
      .required('The VAT number is required.')
      .matches(/^\d{9}$/, 'VAT number must be exactly 9 digits and should not have characters.')
      .max(9, 'VAT number must be 9 characters long.'),
  }),
  screen_id: Yup.string().required('Screening ID is required'),
  screening_verify: Yup.boolean().oneOf([true], 'Acknowledgement is required'),
})

export const OPENING_ACCOUNT_TYPE = Yup.object().shape({
  accountType: Yup.string().required('Account Type is required'),
  customerType: Yup.string().required('Customer Type is required'),
})
export const OPENING_ACCOUNT_TYPE_UPDATE = Yup.object().shape({
  customerType: Yup.string().required('Customer Type is required'),
})

const nepaliMobileRegex = /^(98|\+?(977)?)\d{8}$/
const isCountryNepal = (value: string) => value

const isNotNepal = (value: string) => value !== '48691d0c-2489-4718-8596-10833123fce9'

const married = (label: string) => label === 'a705c6c7-8da8-4c65-b52d-887b019c7a4a'
const account_type_id = (label: string) => label === 'ad785597-b100-4e3d-b86c-d0f9e161ff60'
const panCardID: any = (label: string) => label === '9610704f-a3d8-46b2-8414-07afe6dafce3'
const citizenshipID = (label: string) => label === '8b2c2aec-9843-4646-8cb9-8f507637a2ae'
const birthID = (label: string) => label === 'd2f43beb-2e8c-4836-bd70-ac414a09f2fb'
const nationality_id = '4785497c-56b4-4745-a15b-6bbd20ada494'

// const isCountryNepal = (country:any) => country && country.toUpperCase() === 'NEPAL';
const numericRegex = /^[,A-Za-z0-9./-\s]+$/
// const uuidRegex: RegExp =
//   /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
//FOR INDIVIDUAL
export const SaveAndContinueValidation = (allMasterDataOptions: any, notNeeded = false) =>
  Yup.object().shape({
    // const isNepalYes = allMasterDataOptions?.countries?.find(
    //   (country: any) => country?.title === "NEPAL")?.id
    first_name: Yup.string()
      .required('The first name is required.')
      .matches(
        /^[,A-Za-z0-9./-\s]+$/,
        'The first name should not contain any numbers or special characters.'
      ),
    middle_name: Yup.string().matches(
      /^[,A-Za-z0-9./-\s]+$/,
      'The middle name should not contain any numbers or special characters.'
    ),
    last_name: Yup.string().matches(
      /^[A-Za-z.\s]+$/,
      'The last name should only contain alphabets and dots.'
    ),
    // account_scheme_id: Yup.string().required('Account Scheme is required'),

    form_addresses: Yup.array().of(
      Yup.object().shape({
        permanent_email: Yup.string().required('Email is required.'),
        permanent_mobile_number: Yup.string()
          .required('The mobile number is required.')
          .test(
            'dots-or-mobile',
            'Mobile number must start with 98 or 97 and be 10 digits, or it must consist of more than 0 dot (.)',
            (value) => {
              if (!value) return false

              if (/^\.+$/.test(value) && value.length > 0) {
                return true
              }

              const mobileNumberRegex = /^(98|97)\d{8}$/
              return mobileNumberRegex.test(value)
            }
          ),
      })
    ),
    branch_code: Yup.string()
      .max(6, 'Branch code must be 6 digits long.')
      .min(3, 'Branch code must be atleast 3 digits'),
    id_type_id: Yup.string().required('The ID type  is required.'),
    identification_number: Yup.string().required('The identification number is required.'),

    account_category_id: Yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: Yup.string().required('Account Category is required'),
      otherwise: Yup.string().nullable(), // Skip validation when notNeeded is true
    }),
    account_scheme_id: Yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: Yup.string().required('Account Scheme is required'),
      otherwise: Yup.string().nullable(), // Skip validation when notNeeded is true
    }),

    // account_type_id: Yup.string().required('The account type is required.'),

    account_type_id: Yup.string().required('The account type is required.'),
  })

export const saveValidations = Yup.object().shape({
  account_type_id: Yup.string().required('The account type is required.'),
  aml_risk_rating: Yup.string().required('Risk Rating is required'),
  salutation: Yup.string().required('The salutation is required.'),
  first_name: Yup.string()
    .required('The first name is required.')
    .matches(
      /^[,A-Za-z0-9./-\s]+$/,
      'The first name should not contain any numbers or special characters.'
    ),
  middle_name: Yup.string().matches(
    /^[,A-Za-z0-9./-\s]+$/,
    'The middle name should not contain any numbers or special characters.'
  ),
  last_name: Yup.string()
    .required('The last name is required.')
    .matches(
      /^[,A-Za-z0-9./-\s]+$/,
      'The first name should not contain any numbers or special characters.'
    ),
  spouse_name: Yup.string().when('marital_status', {
    is: married,
    then: Yup.string()
      .required('Spouse name is required when married.')
      .matches(/^[,A-Za-z0-9./-\s]+$/, 'Should not contain any numbers or special characters.'),
    otherwise: Yup.string().nullable(),
  }),
  father_in_law: Yup.string().when('marital_status', {
    is: married,
    then: Yup.string()
      .required('Father in law name is required when married.')
      .matches(/^[,A-Za-z0-9./-\s]+$/, 'Should not contain any numbers or special characters.'),
    otherwise: Yup.string().nullable(),
  }),
  date_of_birth_ad: Yup.date().when('nationality_id', {
    is: nationality_id,
    then: Yup.date().required('Date of Birth (AD) is required when nationality is Nepal'),
    otherwise: Yup.date().nullable(),
  }),
  date_of_birth_bs: Yup.date().when('nationality_id', {
    is: nationality_id,
    then: Yup.date().required('Date of Birth (BS) is required when nationality is Nepal'),
    otherwise: Yup.date().nullable(),
  }),

  gender: Yup.string().required('The gender is required.'),
  id_type_id: Yup.string().required('The ID type is required.'),

  form_addresses: Yup.array().of(
    Yup.object().shape({
      permanent_country: Yup.string().required('The country is required.'),
      permanent_zone: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('The zone is required when the country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_city: Yup.string().matches(
        /^[,a-zA-Z /_,-]+$/,
        'City Name should not contain numbers'
      ),
      permanent_district: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('The district is required when the country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_ward_number: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('Ward Number is required when the country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_house_number: Yup.string().when('permanent_country', {
        is: isCountryNepal, // Ensure isCountryNepal is a valid function or label
        then: Yup.string().matches(numericRegex, 'House Number Format is invalid'),
        otherwise: Yup.string().nullable().matches(numericRegex, 'House Number Format is invalid'), // Optional but could be useful for consistency
      }),
      permanent_municipality: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('The municipality is required when the country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
})
//FOR MINOR
export const SaveAndContinueValidationMinor = (allMasterDataOptions: any, notNeeded = false) =>
  Yup.object().shape({
    aml_risk_rating: Yup.string().required('Risk Rating is required'),
    sub_risk_category_id: Yup.string().required('Risk Sub Rating is required'),
    // total_annual_income: Yup.number()
    //   .required('Total annual income is required')
    //   .min(0, 'Total annual income cannot be negative')
    //   .typeError('Must be a numeric label.'),
    // expected_monthly_income: Yup.number()
    //   .required('Expected Annual Turnover is required')
    //   .min(0, 'Expected Annual Turnover cannot be negative')
    //   .typeError('Must be a numeric label.'),
    // expected_annual_income: Yup.number()
    //   .required('Expected Annual Turnover is required')
    //   .min(0, 'Expected Annual Turnover cannot be negative')
    //   .typeError('Must be a numeric label.'),
    other_occupation: Yup.string().when('occupation_id', {
      is: (occupation_id: string) => {
        const otherOccupation = allMasterDataOptions?.occupations?.find(
          (income: any) => income?.code === '5'
        )
        return occupation_id === otherOccupation?.id
      },
      then: Yup.string().required('Other occupation details is required.'),
      otherwise: Yup.string().nullable(),
    }),
    account_scheme_id: Yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: Yup.string().required('Account Scheme is required'),
      otherwise: Yup.string().nullable(), // Skip validation when notNeeded is true
    }),

    account_category_id: Yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: Yup.string().required('Account Category is required'),
      otherwise: Yup.string().nullable(), // Skip validation when notNeeded is true
    }),
    other_income_source: Yup.string().when('income_source_id', {
      is: (income_source_id: string) => {
        const incomeSource = allMasterDataOptions?.income_sources?.find(
          (income: any) => income?.code === 'O'
        )
        return income_source_id === incomeSource?.id
      },
      then: Yup.string().required('Other income source details is required.'),
      otherwise: Yup.string().nullable(),
    }),
    form_addresses: Yup.array().of(
      Yup.object().shape({
        permanent_country: Yup.string().required('The country is required.'),
        permanent_province: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Province is required.'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_zone: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Zone is required.'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_district: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('District is required.'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_municipality: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Municipality is required.'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_ward_number: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string()
            .required('Ward Number is required.')
            .matches(/^[0-9]+$/, 'Ward Number must contain only numbers'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_house_number: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().matches(numericRegex, 'House Number must contain only numbers'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_street_name: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Street Name is required.'),
          // .matches(/^[.,a-zA-Z /\\_-]+$/, 'Street Name should not contain numbers'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_city: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('City is required.'),
          otherwise: Yup.string().nullable(),
        }),

        permanent_outside_country_post_box: Yup.string().when('permanent_country', {
          is: (value: string) => {
            let selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title !== 'NEPAL'
          },
          then: Yup.string()
            .required('Post Box No. is required')
            .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_outside_country_full_address: Yup.string().when('permanent_country', {
          is: (value: string) => {
            let selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title !== 'NEPAL'
          },
          then: Yup.string()
            .required('Foreign Address is required')
            .matches(
              /^[.,a-zA-Z /\\_-]+$/,
              'Foreign Address name should contain only letters and spaces'
            ),
          otherwise: Yup.string().nullable(),
        }),
        permanent_email: Yup.string().required('Email is required.'),

        permanent_mobile_number: Yup.string()
          .required('The mobile number is required.')
          .test(
            'dots-or-mobile',
            'Mobile number must start with 98 or 97 and be 10 digits, or it must consist of more than 0 dot (.)',
            (value) => {
              if (!value) return false

              if (/^\.+$/.test(value) && value.length > 0) {
                return true
              }

              const mobileNumberRegex = /^(98|97)\d{8}$/
              return mobileNumberRegex.test(value)
            }
          ),
      })
    ),
    id_issued_date: Yup.string().required('The ID Issue date (BS) is required.'),
    id_issued_date_ad: Yup.string()
      .required('The ID Issue date (AD) is required.')
      .test('is-past-date', 'The ID Issue date must be a past date.', (value) => {
        return value ? moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day') : true
      }),

    account_type_id: Yup.string().required('The account type is required.'),
    guardian_id_number: Yup.string(),
    guardian_first_name: Yup.string().matches(
      /^[,A-Za-z0-9./-\s]+$/,
      'Only characters are allowed.'
    ),
    guardian_middle_name: Yup.string().matches(
      /^[,A-Za-z0-9./-\s]+$/,
      'Only characters are allowed.'
    ),
    guardian_last_name: Yup.string().matches(
      /^[,A-Za-z0-9./-\s]+$/,
      'Please enter only alphabetic characters.'
    ),

    guardian_cif_number: Yup.string()
      .matches(/^\d{9}$/, 'Guardian CIF number must be exactly 9 digits.')
      .typeError('Must be a numeric label.'),

    guardian_relationship_id: Yup.string(),
    guardian_relationship_other: Yup.string(),
    guardian_address: Yup.string(),
    // minor_certificate_number: Yup.string()
    //   .required('Minor certificate is required.')
    //   .matches(/^[A-Za-z0-9\s,-/]+$/, 'Please enter only alphabetic characters.'),
    // certificate_issued_by: Yup.string()
    // .required('Minor Certificate issue by is required.')
    // .matches(/^[,A-Za-z0-9./-\s]+$/, 'Only characters are allowed.'),
    // minor_certificate_issue_date_ad: Yup.string().required(
    //   'Minor Certificate Issued(AD) is required.'
    // ),
    // minor_certificate_issue_date_bs: Yup.string().required(
    //   'Minor Certificate Issued(BS) is required.'
    // ),
    certificate_issued_by: Yup.string(),
    salutation: Yup.string().required('The salutation is required.'),
    first_name: Yup.string()
      .required('The first name is required.')
      .matches(/^[,A-Za-z0-9./-\s]+$/, 'Please enter only alphabetic characters.'),
    last_name: Yup.string().matches(
      /^[A-Za-z.\s]+$/,
      'The last name should only contain alphabets and dots.'
    ),
    gender: Yup.string().required('The gender is required.'),
    marital_status: Yup.string().required('The marital status is required.'),
    date_of_birth_ad: Yup.date()
      .when('nationality_id', {
        is: (nationality_id: string) => {
          const nepaliNationality = allMasterDataOptions?.nationalities?.find(
            (national: any) => national?.code === 'NP'
          )
          return nationality_id === nepaliNationality?.id
        },
        then: Yup.date().required('Date of Birth (AD) is required when nationality is Nepal'),
        otherwise: Yup.date().nullable(),
      })
      .test('is-below-18', 'You must be below 18 years old', function (value) {
        if (!value) return true // Skip validation if no date is entered
        const age = moment().diff(moment(value), 'years')
        return age < 18
      }),

    date_of_birth_bs: Yup.date()
      .when('nationality_id', {
        is: (nationality_id: string) => {
          const nepaliNationality = allMasterDataOptions?.nationalities?.find(
            (national: any) => national?.code === 'NP'
          )
          return nationality_id === nepaliNationality?.id
        },
        then: Yup.date().required('Date of Birth (BS) is required when nationality is Nepal'),
        otherwise: Yup.date().nullable(),
      })
      .test('is-below-18', 'You must be below 18 years old', function (value) {
        if (!value) return true // Skip validation if no date is entered
        const age = moment().diff(moment(value), 'years')
        return age < 18
      }),
    id_type_id: Yup.string().required('The ID type ID is required.'),
    id_expiry_date: Yup.string(),

    id_expiry_date_ad: Yup.string(),
    identification_number: Yup.string()
      .required('The identification number is required.')
      .matches(
        /^[a-zA-Z0-9\-\/\\_]+$/,
        'The identification number can only include letters, numbers, -, /, \\ or _.'
      ),
    national_id_number: Yup.string().matches(
      /^[a-zA-Z0-9\-\/\\_]+$/,
      'The National ID number can only include letters, numbers, -, /, \\ or _.'
    ),
    father_name: Yup.string()
      .required('The father name is required.')
      .matches(/^[,A-Za-z0-9./-\s]+$/, 'Please enter only alphabetic characters.'),
    grandfather_name: Yup.string()
      .required('The grandfather name is required.')
      .matches(/^[,A-Za-z0-9./-\s]+$/, 'Please enter only alphabetic characters.'),
    occupation_id: Yup.string().required('The occupation is required.'),
    income_source_id: Yup.string().required('The income source is required.'),
    total_annual_income: Yup.string().required('Total annual income is required'),

    expected_annual_turnover: Yup.number()
      .required('Expected Annual Transaction In Amount is required')
      .min(0, 'Expected Annual Transaction In Amount cannot be negative')
      .typeError('Must be a numeric label.'),
    spouse_name: Yup.string().when('marital_status', {
      is: married,
      then: Yup.string()
        .required('Spouse name is required when married.')
        .matches(/^[,A-Za-z0-9./-\s]+$/, 'Should not contain any numbers or special characters.'),
      otherwise: Yup.string().nullable(),
    }),
    father_in_law: Yup.string().when('marital_status', {
      is: married,
      then: Yup.string()
        .required('Father in law name is required when married.')
        .matches(/^[,A-Za-z0-9./-\s]+$/, 'Should not contain any numbers or special characters.'),
      otherwise: Yup.string().nullable(),
    }),
  })

export const saveValidationsMinor = Yup.object().shape({
  account_type_id: Yup.string().required('The account type is required.'),
  salutation: Yup.string().required('The salutation is required.'),
  first_name: Yup.string().required('The first name is required.'),
  last_name: Yup.string().required('The last name is required.'),
  gender: Yup.string().required('The gender is required.'),
  id_type_id: Yup.string().required('The ID type is required.'),
  guardian_id_number: Yup.number()
    .required('The account type is required.')
    .typeError('Must be a numeric label.'),
  aml_risk_rating: Yup.string().required('Risk Rating is required'),
  guardian_first_name: Yup.string().required('Guardian First Name is required.'),
  guardian_middle_name: Yup.string(),
  guardian_last_name: Yup.string().required('Guardian Last Name is required.'),
  guardian_relationship_id: Yup.string().required('Required.'),
  guardian_relationship_other: Yup.string(),
  guardian_address: Yup.string().required('Required.'),
  minor_certificate_number: Yup.string().required('Required.'),
  certificate_issued_by: Yup.string().required('Required.'),
  minor_certificate_issue_date_ad: Yup.string().required('Required.'),
  minor_certificate_issue_date_bs: Yup.string().required('Required.'),
  form_addresses: Yup.array().of(
    Yup.object().shape({
      permanent_country: Yup.string().required('The country is required.'),
      permanent_zone: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('The zone is required when the country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_ward_number: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('Ward Number is required when the country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_house_number: Yup.string().when('permanent_country', {
        is: isCountryNepal, // Ensure isCountryNepal is a valid function or label
        then: Yup.string().matches(numericRegex, 'House Number Format is invalid'),
        otherwise: Yup.string().nullable().matches(numericRegex, 'House Number Format is invalid'), // Optional but could be useful for consistency
      }),
      permanent_district: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('The district is required when the country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_municipality: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('The municipality is required when the country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
  expected_annual_turnover: Yup.number()
    .required('Expected Annual Transaction In Amount is required')
    .min(0, 'Expected Annual Transaction In Amount cannot be negative')
    .typeError('Must be a numeric label.'),
  total_annual_income: Yup.string().required('Total annual income is required'),
})
