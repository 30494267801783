import {Flex, Spin, Typography} from 'antd'
import {Form, Formik} from 'formik'
import {useEffect} from 'react'
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters} from 'react-query'
import {Toggle} from 'rsuite'
import Modal from 'rsuite/Modal'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import usePutMaritalStatuses from 'src/app/queries/getMasterData/maritialStatus/put/usePutMaritalStatus'
import usePostScheduleCode, {
  IScheduleCode,
} from 'src/app/queries/getMasterData/scheduleCode/post/usePostNewScheduleCode'
import usePutScheduleCode from 'src/app/queries/getMasterData/scheduleCode/put/usePutScheduleCode'

import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
const {Title} = Typography
type Props = {
  isOpen: boolean
  handleClose: () => void
  modalStatus: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  initialData: IScheduleCode | undefined
  isLoadingData: boolean
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>
}

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string()
    // .matches(/^[-. a-zA-Z0-9\s]*$/, 'Title can only contain letters, numbers, and spaces')
    .required('Title is required'),
  cbs_code: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'CBS-Code cannot contain special characters')
    .required('CBS Code is required'),
  order: Yup.number().typeError('Order must be a number').required('Order is required'),
  status: Yup.string().required('Status is required'),
})

const ScheduleCodeModal = ({
  isOpen,
  handleClose,
  modalStatus,
  setOpen,
  initialData,
  isLoadingData,
  refetch,
}: Props) => {
  const {mutate: mutateAdd, isLoading: isLoadingAdd} = usePostScheduleCode(setOpen, refetch)
  const {mutate: mutateEdit, isLoading: isLodingEdit} = usePutScheduleCode(setOpen, refetch)

  const handleSubmitData = (values: IScheduleCode) => {
    let payloadData = {...values}
    if (modalStatus === 'Edit') {
      mutateEdit({payload: payloadData, id: initialData?.id as string})
    } else {
      mutateAdd(payloadData)
    }
  }

  const {allMasterDataOptions} = usePlaceMasterData({
    allMasterData: true,
  })

  return (
    <div className='modal-container'>
      <Modal
        open={isOpen}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>
            {modalStatus === 'Add'
              ? 'Add Free Code 9'
              : modalStatus === 'Edit'
              ? 'Edit Free Code 9'
              : 'View Free Code 9'}{' '}
          </Modal.Title>
        </Modal.Header>
        <Spin spinning={isLoadingData}>
          <Modal.Body>
            <div>
              <Formik
                initialValues={{
                  title: '',
                  cbs_code: '',
                  status: true,
                  order: 0,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={handleSubmitData}
              >
                {({touched, handleChange, errors, setFieldValue, values, setValues}) => {
                  useEffect(() => {
                    if (modalStatus === 'Edit' && initialData) {
                      setValues({
                        title: initialData?.title,
                        cbs_code: initialData?.cbs_code,
                        // fin_code: initialData?.cbs_code,
                        status: initialData?.status,
                        order: initialData?.order ?? 0,
                      })
                    } else {
                      setValues({
                        title: '',
                        cbs_code: '',
                        status: true,
                        order: 0,
                      })
                    }
                  }, [initialData, modalStatus, setValues])
                  return (
                    <Form>
                      <div className='card-body border-top pt-5'>
                        <div className='row'>
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormTextBox
                              containerClassName='col-lg-12'
                              labelClassName='col-lg-12'
                              type='text'
                              placeholder='Enter Title '
                              label='Title'
                              name='title'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                            />
                          </div>
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormTextBox
                              containerClassName='col-lg-12'
                              labelClassName='col-lg-12'
                              type='text'
                              placeholder='Enter Code'
                              label='CBS Code'
                              name='cbs_code'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                            />
                          </div>
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormTextBox
                              containerClassName='col-lg-12'
                              labelClassName='col-lg-12'
                              type='text'
                              placeholder='Enter Order'
                              label='order'
                              name='order'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <Flex vertical>
                            <Title level={5}>Status</Title>
                            <Toggle
                              size='md'
                              checked={values.status}
                              data-cy='toggle-button'
                              onChange={(value) => setFieldValue('status', value)}
                            />
                          </Flex>
                        </div>
                      </div>

                      <div className='d-flex justify-content-end px-9 '>
                        <button
                          type='submit'
                          // disabled={isLoading}
                          className='btn btn-primary btn-sm ms-3'
                          data-cy='modal-submit'
                        >
                          {isLoadingAdd || isLodingEdit ? (
                            <>
                              <span
                                className='spinner-border spinner-border-sm me-2'
                                role='status'
                                aria-hidden='true'
                              ></span>
                              {modalStatus === 'Add'
                                ? 'Saving...'
                                : modalStatus === 'Edit'
                                ? 'Updating...'
                                : ''}
                            </>
                          ) : (
                            'Save'
                          )}
                        </button>

                        <button
                          type='button'
                          onClick={handleClose}
                          className='btn btn-secondary btn-sm ms-3'
                          data-cy='modal-cancel'
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </Modal.Body>
        </Spin>
      </Modal>
    </div>
  )
}
export default ScheduleCodeModal
