import * as yup from 'yup'

const numericRegex = /^[,A-Za-z0-9./-]+$/
const today: any = new Date()

export const CORPORATE_NORMAL_FORM_VALIDATION = yup.object().shape({
  business_nature_id: yup.string().required('The business nature id field is required.'),
  constitution_id: yup.string().required('The constitution field is required.'),
  aml_risk_rating: yup.string().required('AML Risk Rating is required'),
  expected_annual_transaction_number: yup
    .string()
    .required('The expected annual transaction number field is required.'),
  expected_annual_turnover: yup.string(),
  total_annual_income: yup.string().required('The total_annual_income field is required.'),
  institution_name: yup
    .string()
    .required('The institution name field is required.')
    .matches(/^[A-Za-z\s-&/]+$/, 'The institution name should not contain any numbers.'),

  pep_status: yup.string().required('The pep status field is required.'),
  pep_information: yup.string().when('pep_status', {
    is: 'Yes',
    then: yup
      .string()
      .required('information is required')
      .matches(
        /^[.,a-zA-Z /\\_-]+$/,
        'Information should not contain any numbers or special characters'
      ),
    otherwise: yup.string().notRequired(),
  }),
  tax_type: yup.string().required('Tax type is required'),
  pan_number: yup.string().when('tax_type', {
    is: 'pan',
    then: yup
      .string()
      .required('PAN Number is required')
      .matches(/^[0-9]{9,}$/, 'PAN Number must be at least 9 digits and contain only numbers'),
    otherwise: yup.string().notRequired(),
  }),
  vat_number: yup.string().when('tax_type', {
    is: 'vat',
    then: yup
      .string()
      .required('VAT Number is required')
      .matches(/^[0-9]{9,}$/, 'VAT Number must be at least 9 digits and contain only numbers'),
    otherwise: yup.string().notRequired(),
  }),

  registered_office_country: yup.string().required('Country is required'),
  registered_office_province: yup.string().when('registered_office_country', {
    is: '48691d0c-2489-4718-8596-10833123fce9', // Replace with Nepal's country ID
    then: yup.string().required('Province is required'),
    otherwise: yup.string().notRequired(),
  }),
  registered_office_zone: yup.string().when('registered_office_country', {
    is: '48691d0c-2489-4718-8596-10833123fce9',
    then: yup.string().required('Zone is required'),
    otherwise: yup.string().notRequired(),
  }),
  registered_office_district: yup.string().when('registered_office_country', {
    is: '48691d0c-2489-4718-8596-10833123fce9',
    then: yup.string().required('District is required'),
    otherwise: yup.string().notRequired(),
  }),
  registered_office_municipality: yup.string().when('registered_office_country', {
    is: '48691d0c-2489-4718-8596-10833123fce9',
    then: yup.string().required('Municipality is required'),
    otherwise: yup.string().notRequired(),
  }),
  registered_office_ward_number: yup.string().when('registered_office_country', {
    is: '48691d0c-2489-4718-8596-10833123fce9',
    then: yup
      .string()
      .required('Ward Number is required')
      .matches(/^[0-9]+$/, 'Ward Number must be a number'),
    otherwise: yup
      .string()
      .notRequired()
      .matches(/^[0-9]+$/, 'Ward Number must be a number'),
  }),

  registered_office_house_number: yup
    .string()
    .matches(/^[0-9a-zA-Z/-]+$/, 'House Number must be a alphanumeric and may include slashes.'),

  registered_office_street_name: yup.string().when('registered_office_country', {
    is: '48691d0c-2489-4718-8596-10833123fce9',
    then: yup
      .string()
      .required('Tole is required')
      .matches(/^[.,a-zA-Z /\\_-]+$/, 'Tole name should contain only letters and spaces'),
    otherwise: yup
      .string()
      .notRequired()
      .matches(/^[.,a-zA-Z /\\_-]+$/, 'Tole name should contain only letters and spaces'),
  }),
  registered_office_outside_country_post_box: yup.string().when('registered_office_country', {
    is: (value: any) => value !== '48691d0c-2489-4718-8596-10833123fce9',
    then: yup
      .string()
      .required('Post Box No. is required')
      .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
    otherwise: yup
      .string()
      .notRequired()
      .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
  }),
  registered_office_outside_country_full_address: yup.string().when('registered_office_country', {
    is: (value: any) => value !== '48691d0c-2489-4718-8596-10833123fce9',
    then: yup
      .string()
      .required('Foreign Address is required')
      .matches(
        /^[.,a-zA-Z /\\_-]+$/,
        'Foreign Address name should contain only letters and spaces'
      ),
    otherwise: yup
      .string()
      .notRequired()
      .matches(
        /^[.,a-zA-Z /\\_-]+$/,
        'Foreign Address name should contain only letters and spaces'
      ),
  }),
  // registered_office_mobile_number: yup
  //   .string()
  //   .required('Mobile Number is required')
  //   .matches(/^(98|97)\d{8}$/, 'Mobile Number must start with 98 or 97 and contain 10 digits'),
  registered_office_mobile_number: yup
    .string()
    .required('The mobile number is required.')
    .test(
      'dots-or-mobile',
      'Mobile number must start with 98 or 97 and be 10 digits, or it must consist of more than 0 dot (.)',
      (value) => {
        if (!value) return false

        if (/^\.+$/.test(value) && value.length > 0) {
          return true
        }

        const mobileNumberRegex = /^(98|97)\d{8}$/
        return mobileNumberRegex.test(value)
      }
    ),

  registered_office_email: yup.string().required('Email is required'),
  registration_date_ad: yup
    .date()
    .required('The registration date AD field is required.')
    .test(
      'is-before-expiry-date-ad',
      'Registration date AD should be before expiry date AD',
      function (value) {
        const {registration_expiry_date_ad} = this.parent
        return (
          !value || !registration_expiry_date_ad || value < new Date(registration_expiry_date_ad)
        )
      }
    ),
  registration_date_bs: yup
    .string()
    .required('The registration date BS field is required.')
    .test(
      'is-before-expiry-date-bs',
      'Registration date BS should be before expiry date BS.',
      function (value) {
        const {registration_expiry_date_bs}: any = this.parent
        return !value || !registration_expiry_date_bs || value < registration_expiry_date_bs
      }
    ),
  registration_expiry_date_bs: yup
    .string()
    .required('The registration expiry date BS field is required.'),
  registration_expiry_date_ad: yup
    .date()
    .required('The registration expiry date AD field is required.'),
  registration_number: yup
    .string()
    .nullable()
    .required('The registration Number field is required.')
    .max(50, 'Registration Number cannot exceed 50 characters')
    .matches(/^[,0-9 _/.-]+$/, 'Invalid format for Registration Number should be number '),
  registration_with_id: yup.string().required('The registration with ID is required.'),

  blacklist_screening: yup
    .boolean()
    .oneOf([true], 'The blacklist screening must be checked before you can submit.')
    .required('The blacklist screening field is required.'),
  sanction_screening: yup
    .boolean()
    .oneOf([true], 'The sanction screening must be checked before you can submit.')
    .required('The sanction screening field is required.'),
})

export const CORPORATE_CPU_FORM_VALIDATION = (allMasterDataOptions: any, notNeeded = false) =>
  yup.object().shape({
    business_nature_id: yup.string().required('The business nature id field is required.'),
    constitution_id: yup.string().required('The constitution field is required.'),
    sub_risk_category_id: yup.string().required('AML Risk Subcategory Rating is required'),
    expected_annual_transaction_number: yup
      .string()
      .required('The expected annual transaction number field is required.'),
    // expected_annual_turnover: yup
    //   .string()
    //   .required('The expected annual turnover field is required.')
    //   .matches(/^(\d+\.*)+$/, 'The expected annual turnover can only contain numbers and dots.')
    //   .nullable(),
    institution_name: yup
      .string()
      .required('The institution name field is required.')
      .matches(/^[A-Za-z\s-&./]+$/, 'The institution name should not contain any numbers.'),

    account_scheme_id: yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: yup.string().required('Account Scheme is required'),
      otherwise: yup.string().nullable(), // Skip validation when notNeeded is true
    }),

    account_category_id: yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: yup.string().required('Account Category is required'),
      otherwise: yup.string().nullable(), // Skip validation when notNeeded is true
    }),
    pep_status: yup.string().required('The pep status field is required.'),
    pep_information: yup.string().when('pep_status', {
      is: 'Yes',
      then: yup
        .string()
        .required('information is required')
        .matches(
          /^[.,a-zA-Z /\\_-]+$/,
          'Information should not contain any numbers or special characters'
        ),
      otherwise: yup.string().nullable(),
    }),
    // tax_type: yup.string().required('Tax type is required'),
    pan_number: yup.string().when('tax_type', {
      is: 'pan',
      then: yup
        .string()
        .required('PAN Number is required')
        .matches(/^[0-9]{9,}$/, 'PAN Number must be at least 9 digits and contain only numbers'),
      otherwise: yup.string().nullable(),
    }),
    vat_number: yup.string().when('tax_type', {
      is: 'vat',
      then: yup
        .string()
        .required('VAT Number is required')
        .matches(/^[0-9]{9,}$/, 'VAT Number must be at least 9 digits and contain only numbers'),
      otherwise: yup.string().nullable(),
    }),
    pan_issue_date_ad: yup.date().required('Issue Date (AD) is required'),
    pan_issue_date_bs: yup.date().required('Issue Date (BS) is required'),
    pan_issuing_district: yup.string().required('Issuing District is required'),

    registered_office_country: yup.string().required('Country is required'),
    registered_office_province: yup.string().when('registered_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup.string().required('Province is required.'),
      otherwise: yup.string().nullable(),
    }),
    registered_office_zone: yup.string().when('registered_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup.string().required('Zone is required.'),
      otherwise: yup.string().nullable(),
    }),

    registered_office_district: yup.string().when('registered_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup.string().required('District is required.'),
      otherwise: yup.string().nullable(),
    }),
    registered_office_municipality: yup.string().when('registered_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup.string().required('Municipality is required.'),
      otherwise: yup.string().nullable(),
    }),
    registered_office_city: yup.string().when('registered_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup.string().required('Registered Office City is required.'),
      otherwise: yup.string().nullable(),
    }),
    registered_office_ward_number: yup.string().when('registered_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup
        .string()
        .required('Ward Number is required.')
        .matches(/^[0-9]+$/, 'Ward Number must contain only numbers'),
      otherwise: yup.string().nullable(),
    }),
    registered_office_street_name: yup.string().when('registered_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup.string().required('Tole is required.'),
      // .matches(/^[.,a-zA-Z /\\_-]+$/, 'Tole should contain only letters and spaces'),
      otherwise: yup.string().nullable(),
    }),

    registered_office_house_number: yup.string().when('registered_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup.string().matches(numericRegex, 'House Number must contain only numbers'),
      otherwise: yup.string().nullable(),
    }),
    registered_office_outside_country_post_box: yup.string().when('registered_office_country', {
      is: (value: string) => {
        let selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title !== 'NEPAL'
      },
      then: yup
        .string()
        .required('Post Box No. is required')
        .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers')
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
    registered_office_outside_country_full_address: yup.string().when('registered_office_country', {
      is: (value: string) => {
        let selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title !== 'NEPAL'
      },
      then: yup
        .string()
        .required('Foreign Address is required')
        .matches(
          /^[.,a-zA-Z /\\_-]+$/,
          'Foreign Address name should contain only letters and spaces'
        ),
      otherwise: yup.string().nullable(),
    }),
    registered_office_mobile_number: yup.string().when('registered_office_country', {
      is: (values: string) => {
        let selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === values
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup
        .string()
        .required('The mobile number is required.')
        .test(
          'dots-or-mobile',
          'Mobile number must start with 98 or 97 and be 10 digits, or it must consist dot (.)',
          (value: any) => {
            if (!value) return false

            if (/^\.+$/.test(value) && value.length > 0) {
              return true
            }

            const mobileNumberRegex = /^(98|97)\d{8}$/
            return mobileNumberRegex.test(value)
          }
        ),
      otherwise: yup.string().required('The mobile number is required'),
    }),
    // registered_office_mobile_number: yup.string().when('registered_office_country', {
    //   is: (value: string) => {
    //     let selectedCountry = allMasterDataOptions?.countries?.find(
    //       (country: any) => country?.id === value
    //     )
    //     return selectedCountry?.title === 'NEPAL'
    //   },
    //   then: yup
    //     .string()
    //     .required('Mobile number is required.')
    //     .typeError('Must be a numeric value.')
    //     .matches(/^(98|97)\d{8}$/, 'Mobile number must start with 98 or 97 and must be 10 digits'),
    //   otherwise: yup
    //     .string()
    //     .required('Mobile number is required.')
    //     .typeError('Must be a numeric value.'),
    // }),
    // registered_office_mobile_number: yup
    //   .string()
    //   .required('The mobile number is required.')
    //   .test(
    //     'dots-or-mobile',
    //     'Mobile number must start with 98 or 97 and be 10 digits, or it must consist of more than 0 dot (.)',
    //     (value) => {
    //       if (!value) return false

    //       if (/^\.+$/.test(value) && value.length > 0) {
    //         return true
    //       }

    //       const mobileNumberRegex = /^(98|97)\d{8}$/
    //       return mobileNumberRegex.test(value)
    //     }
    //   ),
    registered_office_email: yup
      .string()
      .required('The registered office email field is required.'),

    registration_date_ad: yup
      .date()
      .required('The registration date AD field is required.')
      .test(
        'is-before-expiry-date-ad',
        'Registration date AD should be before expiry date AD',
        function (value) {
          const {registration_expiry_date_ad} = this.parent
          return (
            !value || !registration_expiry_date_ad || value < new Date(registration_expiry_date_ad)
          )
        }
      ),
    registration_date_bs: yup
      .string()
      .required('The registration date BS field is required.')
      .test(
        'is-before-expiry-date-bs',
        'Registration date BS should be before expiry date BS.',
        function (value) {
          const {registration_expiry_date_bs}: any = this.parent
          return !value || !registration_expiry_date_bs || value < registration_expiry_date_bs
        }
      ),
    registration_expiry_date_bs: yup.string(),

    registration_expiry_date_ad: yup
      .date()
      .min(today, 'Expiry date AD must be today or in the future.'),
    registration_number: yup
      .string()
      .required('The registration Number field is required.')
      .max(30, 'Registration Number cannot exceed 20 characters')
      .matches(/^[.,0-9 /\\_-]+$/, 'Invalid format for Registration Number'),
    registration_with_id: yup.string().required('The registration with ID is required.'),
    aml_risk_rating: yup.string().required('The AML risk rating  is required.'),
    remarks: yup.string().required('The Remark is required.') .max(255, 'Remarks cannot exceed 255 characters'),
    bank_code_id: yup.string().required('Bank Code is required'),
    address_type: yup.string().required('Address Type is required'),
    customer_status_id: yup.string().required('Customer Status is required'),
    // customer_category_id: yup.string().required('Customer Category is required'),
    customer_group_id: yup.string().required('Customer Category is required'),
    customer_free_text: yup.string().required('Customer Status Details is required'),
    cdd_edd_date: yup
      .date()
      .required('CDD & EDD date is required')
      .typeError('Invalid date format'),
    annual_turn_over_as_on: yup
      .date()
      .required('Annual Turnover As On date is required')
      .typeError('Invalid date format'),

    basic_shareholders: yup.array().of(
      yup.object().shape({
        shareholder_name: yup.string().required('Shareholder name is required'),
        ownership_percentage: yup
          .number()
          .nullable()
          .required('Ownership percentage is required')
          .max(100, 'Ownership percentage cannot exceed 100'),
        shareholder_address: yup.string().nullable(),
      })
    ),
    full_shareholders: yup.array().of(
      yup.object().shape({
        shareholder_name: yup.string().required('Shareholder name is required'),
        ownership_percentage: yup
          .number()
          .nullable()
          .required('Ownership percentage is required')
          .max(100, 'Ownership percentage cannot exceed 100'),
        shareholder_address: yup.string().nullable(),
        entity_name: yup.string().nullable().required('Entity Name is required'),
        entity_ownership_percentage: yup.number().nullable(),
        entity_address: yup.string().nullable(),
        // Optional fields remain as they are with the `test` function if desired
        entity_registration_number: yup.string().nullable(),
        entity_registration_office: yup.string().nullable(),
        entity_incorporation_date: yup.date().nullable(),
        entity_pan_number: yup.string().nullable(),
        entity_vat_number: yup.string().nullable(),
        entity_country: yup.string().nullable(),
        entity_registration_address: yup.string().nullable(),
        entity_business_address: yup.string().nullable(),
        entity_phone_number: yup.string().nullable(),
        entity_website: yup.string().nullable(),
      })
    ),

    other_banks: yup.array().of(
      yup.object().shape({
        bank_name: yup
          .string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Bank name should contain only letters, numbers, and spaces'
          )
          .max(50, 'Bank Name cannot exceed 50 characters')
          .required('Bank Name is Required')
          .nullable(),
        saving_ac: yup
          .string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Saving Account should contain only letters, numbers, and spaces'
          )
          .max(50, 'Saving Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {current_ac, od_ac, term_loan, others} = this.parent
            return value || current_ac || od_ac || term_loan || others
          }),
        current_ac: yup
          .string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Current Account should contain only letters, numbers, and spaces'
          )
          .max(50, 'Current Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, od_ac, term_loan, others} = this.parent
            return value || saving_ac || od_ac || term_loan || others
          }),
        od_ac: yup
          .string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Overdraft Account should contain only letters, numbers, and spaces'
          )
          .max(50, 'Overdraft Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, term_loan, others} = this.parent
            return value || saving_ac || current_ac || term_loan || others
          }),
        term_loan: yup
          .string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Term Loan should contain only letters, numbers, and spaces'
          )
          .max(50, 'Term Loan cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, od_ac, others} = this.parent
            return value || saving_ac || current_ac || od_ac || others
          }),
        others: yup
          .string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Other should contain only letters, numbers, and spaces')
          .max(50, 'Other cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, od_ac, term_loan} = this.parent
            return value || saving_ac || current_ac || od_ac || term_loan
          }),
      })
    ),
    directors: yup.array().of(
      yup.object().shape({
        full_name: yup
          .string()
          .matches(/^[.,a-zA-Z /\\_-]+$/, 'Full Name should contain only letters')
          .required('Full Name is required'),
        designation: yup
          .string()
          .matches(/^[.,a-zA-Z /\\_-]+$/, 'Designation should contain only letters')
          .required('Designation is required'),

        // .when('cif_number', {
        //   is: (cif_number: any) => !!cif_number, // If cif_number has data
        //   then: yup.string().required('Designation is required'),
        //   otherwise: yup.string().nullable(),
        // }),
        cif_number: yup
          .string()
          .matches(/^\d{9}$/, 'CIF number must be exactly 9 digits and contain only numbers.')
          .required('Cif Number is required')
          .nullable(),
      })
    ),
    facta_declarations: yup.array().of(
      yup.object().shape({
        us_person: yup.boolean().required('US Person is required'),
        us_person_remarks: yup.string(),
        us_indicia_remarks: yup.string().when('us_person', {
          is: true,
          then: yup.string().required('US Indicia is required'),
        }),
        us_telephone_number: yup
          .string()
          .when('us_person', {
            is: true,
            then: yup.string().required('US Telephone Number is required'),
          })
          .matches(/^\d{10}$/, 'US Telephone Number must be 10 digits'),
        tin_number: yup.string().when('us_person', {
          is: true,
          then: yup.string().required('TIN Number is required'),
        }),
        giin_number_remarks: yup.string().when('giin_number', {
          is: true,
          then: yup.string().required('GIIN Number Remarks are required if GIIN Number is Yes'),
          otherwise: yup.string().notRequired(),
        }),
        w9_form_submitted: yup.string().when('us_person', {
          is: true,
          then: yup.string().required('W9 Form Submitted is required'),
        }),
      })
    ),

    business_office_country: yup.string().required('Country is required'),
    business_office_province: yup.string().when('business_office_country', {
      is: '48691d0c-2489-4718-8596-10833123fce9', // Replace with Nepal's country ID
      then: yup.string().required('Province is required'),
      otherwise: yup.string().notRequired(),
    }),
    business_office_zone: yup.string().when('business_office_country', {
      is: '48691d0c-2489-4718-8596-10833123fce9',
      then: yup.string().required('Zone is required'),
      otherwise: yup.string().notRequired(),
    }),
    business_office_district: yup.string().when('business_office_country', {
      is: '48691d0c-2489-4718-8596-10833123fce9',
      then: yup.string().required('District is required'),
      otherwise: yup.string().notRequired(),
    }),
    business_office_municipality: yup.string().when('business_office_country', {
      is: '48691d0c-2489-4718-8596-10833123fce9',
      then: yup.string().required('Municipality is required'),
      otherwise: yup.string().notRequired(),
    }),
    business_office_city: yup.string().when('business_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup.string().required('Business Office City is required.'),
      otherwise: yup.string().nullable(),
    }),
    business_office_ward_number: yup.string().when('business_office_country', {
      is: '48691d0c-2489-4718-8596-10833123fce9',
      then: yup
        .string()
        .required('Ward Number is required')
        .matches(/^[0-9]+$/, 'Ward Number must be a number'),
      otherwise: yup
        .string()
        .notRequired()
        .matches(/^[0-9]+$/, 'Ward Number must be a number'),
    }),

    business_office_house_number: yup
      .string()
      .matches(/^[0-9a-zA-Z/-]+$/, 'House Number must be a alphanumeric and may include slashes.'),

    business_office_street_name: yup.string().when('business_office_country', {
      is: '48691d0c-2489-4718-8596-10833123fce9',
      then: yup.string().required('Tole is required'),
      // .matches(/^[.,a-zA-Z /\\_-]+$/, 'Tole name should contain only letters and spaces'),
      otherwise: yup.string().notRequired(),
      // .matches(/^[.,a-zA-Z /\\_-]+$/, 'Tole name should contain only letters and spaces'),
    }),
    business_office_outside_country_post_box: yup.string().when('business_office_country', {
      is: (value: any) => value !== '48691d0c-2489-4718-8596-10833123fce9',
      then: yup
        .string()
        .required('Post Box No. is required')
        .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
      otherwise: yup.string().nullable(),
    }),
    business_office_outside_country_full_address: yup.string().when('business_office_country', {
      is: (value: any) => value !== '48691d0c-2489-4718-8596-10833123fce9',
      then: yup
        .string()
        .required('Foreign Address is required')
        .matches(
          /^[.,a-zA-Z /\\_-]+$/,
          'Foreign Address name should contain only letters and spaces'
        ),
      otherwise: yup.string().nullable(),
    }),
    // business_office_mobile_number: yup
    //   .string()
    //   .required('Mobile Number is required')
    //   .matches(/^(98|97)\d{8}$/, 'Mobile Number must start with 98 or 97 and contain 10 digits'),
    business_office_mobile_number: yup.string().when('business_office_country', {
      is: (values: string) => {
        let selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === values
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: yup
        .string()
        .required('The mobile number is required.')
        .test(
          'dots-or-mobile',
          'Mobile number must start with 98 or 97 and be 10 digits, or it must consist dot (.)',
          (value: any) => {
            if (!value) return false

            if (/^\.+$/.test(value) && value.length > 0) {
              return true
            }

            const mobileNumberRegex = /^(98|97)\d{8}$/
            return mobileNumberRegex.test(value)
          }
        ),
      otherwise: yup.string().required('The mobile number is required'),
    }),
    business_office_email: yup.string().required('Email is required'),

    blacklisting_inclusion: yup.boolean(),
    blacklist_release_date: yup.string().when('blacklisting_inclusion', {
      is: true,
      then: yup
        .string()
        .required('Release date is required')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in the format YYYY-MM-DD'),
      otherwise: yup.string().notRequired(), // Optional if blacklisting_inclusion is false
    }),
  })

export const ACCOUNT_OPENING_VALIDATION = yup.object().shape({
  account_category_id: yup
    .string()
    .required('The account category id field is required.')
    .uuid('Invalid UUID format for Account Category ID'),

  account_scheme_id: yup
    .string()
    .required('The Account Scheme id field is required.')
    .uuid('Invalid UUID format for Account Scheme ID'),

  initial_deposit: yup
    .string()
    .required('The Initial Deposit field is required.')
    .matches(/^[0-9,.]+$/, 'Initial Deposit should contain only numbers'),

  account_currency_id: yup
    .string()
    .required('The account currency id field is required.')
    .uuid('Invalid UUID format for Account Currency ID'),
  account_holders: yup.array().of(
    yup.object().shape({
      first_name: yup
        .string()
        .required('First Name is required')
        .max(50, 'First Name cannot exceed 50 characters')
        .nullable(),
      middle_name: yup.string().max(50, 'Middle Name cannot exceed 50 characters').nullable(),
      last_name: yup
        .string()
        // .required('Last Name is required')
        .max(50, 'Last Name cannot exceed 50 characters')
        .nullable(),
      // cif_number: yup.string()
      //   .required('CIF Number is required')
      //   .matches(/^\d+$/, 'CIF Number must be numeric'),
      // id_number: yup.string().required('ID Number is required'),
      // id_type_id: yup.string().required('ID Type is required'),
      // account_relation_id: yup.string().required('Account Holder Identification is required'),
    })
  ),
  // account_title: yup
  //   .string()
  //   .required('The account title field is required.')
  //   .matches(/^[.,a-zA-Z /\\_-]+$/, 'Title should contain only letters')
  //   .max(100, 'Account title cannot exceed 100 characters'), // Adjust max length as needed

  cheque_book_name: yup
    .string()
    .required('The cheque book name field is required.')
    .matches(/^[,a-zA-Z _\.-\/]+$/, 'cheque book name should contain only letters, dot, or slash')
    .max(100, 'Cheque book name cannot exceed 100 characters'),

  nomination: yup
    .boolean()
    .required('The Nomination field is required.')
    .oneOf([true, false], 'Invalid value for Nomination'),

  nominee_first_name: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The First Name field is required.')
      .max(50, 'First Name cannot exceed 50 characters')
      .matches(/^[.,a-zA-Z /\\_-]+$/, 'First name should contain only letters'),
    otherwise: yup.string().nullable(),
  }),

  nominee_middle_name: yup
    .string()
    .nullable()
    .max(50, 'Middle Name cannot exceed 50 characters')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'Middle name should contain only letters'),
  nominee_last_name: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The Last Name field is required.')
      .max(50, 'Last Name cannot exceed 50 characters')
      .matches(/^[.,a-zA-Z /\\_-]+$/, 'Last name should contain only letters'),
    otherwise: yup.string().nullable(),
  }),

  nominee_citizenship_number: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The Citizenship Number field is required.')
      .max(20, 'Citizenship Number cannot exceed 20 characters')
      .matches(
        /^[0-9\/-]+$/,
        'Citizenship Number can only contain numbers, hyphens (-), and slashes (/)'
      ),

    otherwise: yup.string().nullable(),
  }),

  nominee_address: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The Address field is required.')
      .max(255, 'Address cannot exceed 255 characters')
      .matches(/^[.,a-zA-Z /\\_-]+$/, 'Entity name should contain only letters and spaces'),
    otherwise: yup.string().nullable(),
  }),

  nominee_place_of_issue: yup.string().when('nomination', {
    is: true,
    then: yup.string().nullable().max(255, 'Place of issue cannot exceed 255 characters'),
    otherwise: yup.string().nullable(),
  }),

  nominee_relationship_id: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The Relation To Me field is required.')
      .max(255, 'Relation To Me cannot exceed 255 characters'),
    otherwise: yup.string().nullable(),
  }),

  introducer_first_name: yup
    .string()
    // .required('The First Name field is required.')
    .max(50, 'First Name cannot exceed 50 characters')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'First Name should contain only letters and spaces'),

  introducer_middle_name: yup
    .string()
    .nullable()
    .max(50, 'Middle Name cannot exceed 50 characters')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'Middle Name should contain only letters and spaces'),

  introducer_last_name: yup
    .string()
    // .required('The Last Name field is required.')
    .max(50, 'Last Name cannot exceed 50 characters')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'Last Name should contain only letters and spaces'),

  introducer_customer_id: yup
    .string()
    // .required('The Customer Id field is required.')
    .matches(/^[0-9\/-]+$/, 'Customer Id can only contain numbers, hyphens (-), and slashes (/)'),

  introducer_address: yup
    .string()
    // .required('The Address field is required.')
    .max(255, 'Address cannot exceed 255 characters')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'Address field should contain only letters and spaces'),

  introducer_confirmation_period: yup.string().nullable(),
  // .matches(/^\d{0,2}$/, 'Confirmation Period can only contain up to 2 digits')
  // .test('maxDigits', 'Confirmation Period can only be up to 2 digits long', (value: any) => {
  //   return value === null || value === '' || (value && value.length <= 2)
  // }),

  mode_of_operation: yup.string().required('The Operation field is required.'),
  loan_detail_id: yup.string().required('The Free Code 1 is required.'),
  deposit_detail_id: yup.string().required('The Free Code 8 is required.'),
  schedule_code_id: yup.string().required('The Free Code 9 is required.'),
  // blacklist_screening: yup
  //   .boolean()
  //   .oneOf([true], 'You must verify the blacklist screening result'),
  sanction_screening: yup.boolean().oneOf([true], 'You must verify the sanction screening result'),
  duplication_screening: yup
    .boolean()
    .oneOf([true], 'You must verify the duplicate screening result'),
})

export const CORPORATE_SAVE_DRAFT_NORMAL_FORM_VALIDATION = yup.object().shape({
  registered_office_city: yup.string().when('registered_office_country', {
    is: 'Nepal',
    then: yup
      .string()
      .required(
        'The registered office city is required when the registered office country is Nepal.'
      ),
  }),
  registered_office_district: yup
    .string()
    .required('The registered office district must be a string.'),
  registered_office_email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Email should be in "Username@email.com" format'
    ),
  registered_office_municipality: yup
    .string()
    .required('The registered office municipality must be a string.'),
  registered_office_province: yup
    .string()
    .required('The registered office province must be a string.'),
  registration_with_id: yup.string().required('The registration with ID is required.'),
  aml_risk_rating: yup.string().required('The AML risk rating  is required.'),
})
export const OPENING_ACCOUNT_TYPE = yup.object().shape({
  accountType: yup.string().required('Account Type is required'),
  customerType: yup.string().required('Customer Type is required'),
})
