import {FieldArray} from 'formik'
import {FaPlus} from 'react-icons/fa'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {useEffect} from 'react'

const FamilyDetailsForm = ({
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
  maritalStatus,
  gender,
  allmasterData,
}: any) => {
  useEffect(() => {
    if (!allmasterData) return // Wait until master data is loaded

    const fatherId = allmasterData?.relationships?.find(
      (relation: any) => relation.title === 'FATHER'
    )?.id

    const grandfatherId = allmasterData?.relationships?.find(
      (relation: any) => relation.title === 'GRAND FATHER'
    )?.id

    const spouseRelationshipId = allmasterData?.relationships?.find((relation: any) =>
      gender === 'Male' ? relation.title === 'WIFE' : relation.title === 'HUSBAND'
    )?.id

    const oppositeSpouseRelationshipId = allmasterData?.relationships?.find((relation: any) =>
      gender === 'Male' ? relation.title === 'HUSBAND' : relation.title === 'WIFE'
    )?.id

    let updatedFamilyDetails = [...values.family_details]

    // Ensure "Father" is present
    const fatherIndex = updatedFamilyDetails.findIndex(
      (detail: any) => detail.relationship_id === fatherId
    )
    if (fatherIndex === -1) {
      updatedFamilyDetails = [
        {
          name: '',
          profession: '',
          relationship_id: fatherId,
        },
        ...updatedFamilyDetails,
      ]
    }

    // Ensure "Grandfather" is present
    const grandfatherIndex = updatedFamilyDetails.findIndex(
      (detail: any) => detail.relationship_id === grandfatherId
    )
    if (grandfatherIndex === -1) {
      updatedFamilyDetails = [
        ...updatedFamilyDetails,
        {
          name: '',
          profession: '',
          relationship_id: grandfatherId,
        },
      ]
    }

    // Remove the opposite spouse field if present
    updatedFamilyDetails = updatedFamilyDetails.filter(
      (detail: any) => detail.relationship_id !== oppositeSpouseRelationshipId
    )

    // Handle spouse field based on marital status and gender
    const existingSpouse = updatedFamilyDetails.find(
      (detail: any) => detail.relationship_id === spouseRelationshipId
    )

    const isMarried =
      allmasterData?.marital_statuses?.find(
        (maritalStatusData: any) => maritalStatusData.id === maritalStatus
      )?.title === 'MARRIED'

    if (isMarried) {
      if (!existingSpouse || !existingSpouse.name) {
        // Add spouse if not already present or if the name is empty
        updatedFamilyDetails.push({
          name: '',
          profession: '',
          relationship_id: spouseRelationshipId,
        })
      }
    } else {
      // Remove spouse if marital status is not "Married"
      updatedFamilyDetails = updatedFamilyDetails.filter(
        (detail: any) => detail.relationship_id !== spouseRelationshipId
      )
    }

    // Update the family details field
    setFieldValue('family_details', updatedFamilyDetails)
  }, [maritalStatus, gender])

  return (
    <div className=''>
      <FieldArray name='family_details'>
        {({remove, push}) => (
          <div>
            {values?.family_details?.length > 0 ? (
              values?.family_details?.map((details: any, index: any) => {
                // Determine if the remove button should be shown
                const fatherId = allmasterData?.relationships?.find(
                  (relation: any) => relation.title === 'FATHER'
                )?.id

                const grandfatherId = allmasterData?.relationships?.find(
                  (relation: any) => relation.title === 'GRAND FATHER'
                )?.id

                const spouseRelationshipId = allmasterData?.relationships?.find((relation: any) =>
                  gender === 'Male' ? relation.title === 'WIFE' : relation.title === 'HUSBAND'
                )?.id

                const isDefaultField =
                  details.relationship_id === fatherId ||
                  details.relationship_id === grandfatherId ||
                  details.relationship_id === spouseRelationshipId

                return (
                  <div className='row mb-3' key={index}>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Name'
                        uppercase={true}
                        name={`family_details.${index}.name`}
                        label='Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        value={details?.name}
                        className='w-100 p-3 rounded-2'
                      />
                    </div>

                    <div className='col-md-4'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Relationship'
                        name={`family_details.${index}.relationship_id`}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        arrValue={details?.relationship_id}
                        options={allmasterData?.relationships?.map((relations: any) => ({
                          value: relations.id,
                          label: relations.title || relations.label,
                        }))}
                        setFieldValue={setFieldValue}
                        required
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Profession'
                        name={`family_details.${index}.profession`}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        values={values}
                        arrValue={details?.profession}
                        options={allmasterData?.occupations?.map((occupation: any) => ({
                          value: occupation.id || occupation.value,
                          label: occupation.title || occupation.label,
                        }))}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {!isDefaultField && ( // Show remove button only if it's not a default field
                      <div className='col-md-2 d-flex align-items-center'>
                        <button
                          type='button'
                          className='dt-btn dt-btn-primary'
                          data-cy='remove-button'
                          onClick={() => remove(index)}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                )
              })
            ) : (
              <p>No family members added. Click 'Add Member' to start.</p>
            )}
            <button
              type='button'
              className='dt-btn dt-btn-primary'
              data-cy='add-button'
              onClick={() =>
                push({
                  name: '',
                  profession: '',
                  relationship_id: '',
                })
              }
            >
              <FaPlus className='me-2' />
              Add Member
            </button>
          </div>
        )}
      </FieldArray>
    </div>
  )
}

export default FamilyDetailsForm
