import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {CORPORATE_NEW_CIF_NEW_ACCOUNT_ACCOUNT_OPENING} from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${CORPORATE_NEW_CIF_NEW_ACCOUNT_ACCOUNT_OPENING}/`

const postCorporateAccountOpening = async (id: string, data: any) => {
  try {
    const response = await axios.post(`${API_URL}${id}/account-opening`, data)
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message

  }
}

const usePostAccountOpening = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: {id: string; data: any}) => postCorporateAccountOpening(payload.id, payload.data),
    {
      onSuccess: (response) => {
        if (response?.resCod === 200) {
          toast.success(
            response?.resDesc ?? 'Corporate account Opening form submitted successfully!'
          )
          queryClient.invalidateQueries('corporateAccountList')
          queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
        }
      },
      onError: (error: any) => {
        if (error.errors) {
          for (const key in error.errors) {
            if (error.errors[key]) {
              error.errors[key].forEach((message: string) => {
                toast.error(message)
              })
            }
          }
        } else if (error.message) {
          toast.error(error.message)
        } else {
          toast.error('An unknown error occurred')
        }
      },
    }
  )
}

export default usePostAccountOpening
