import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {API_URL} from 'src/app/queries/api/apiEndPoints/EndPoints'

interface SortItem {
  id: string
}

interface SortPayload {
  modules: SortItem[]
}

const sortDepositDetails = async (payload: SortPayload) => {
  try {
    const response = await axios.patch(`${API_URL}/deposit-details/sort-module`, payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || 'Failed to sort deposit details'}`)
  }
}

export const useSortDepositDetails = (refetch: () => void) => {
  const queryClient = useQueryClient()

  return useMutation(sortDepositDetails, {
    onSuccess: (response) => {
      if (response?.resCod === 200) {
        toast.success(response.resDesc || 'Successfully sorted deposit details')
        queryClient.invalidateQueries('getDepositDetailsList')
        refetch()
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}
