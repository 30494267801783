import {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {useHistory, useParams} from 'react-router-dom'
import {UserActivityLogAll} from 'src/app/modules/common/components/AllActivityLog'
import {useGetPreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import usePatchKycDetailsIndividual from 'src/app/queries/new-cif-new-account-queries/cpuMaker/kycIndividual/patch/usePatchKycDetailsIndividual'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import {AccountStatusLog} from 'src/app/modules/common/components/AccountStatusLog'
import KycFormNormalIndividualJointMinorCIF from './kycFormIndividualJointMinor'

const KycUpdateFormNormalIndividualJointMinorCIF = () => {
  interface IIDPROPS {
    id: string
  }
  const {id} = useParams<any>()
  const history = useHistory()
  const [btnFlag, setBtnFlag] = useState('')
  const {data: preliminaryDetailsByID, isLoading: detailsLoading} =
    useGetPreliminariesDetailsByIdCIF(id)
  const dataStatus = preliminaryDetailsByID?.data
  const {mutate: updateKycDetails, isLoading: updateLoading} = usePatchKycDetailsIndividual()
  const [kycFormSubmitted, setKycFormSubmitted] = useState(false)

  const [activeKey, setActiveKey] = useState('updateKyc')

  const data = preliminaryDetailsByID?.data
  return (
    <>
      <div className='row'>
        <div className='col-md-6 col-12 pe-0'>
          <div className='card mb-5 mb-xl-10' style={{position: 'sticky', top: '80px'}}>
            <div className='card-body p-0'>
              <DocumentsPreview data={dataStatus} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='card mb-5 mb-xl-10' style={{position: 'sticky', top: '80px'}}>
            <div className='card-body border-top p-0'>
              <div style={{position: 'sticky', top: '0', zIndex: '10'}}>
                <Tabs
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k ?? 'updateKyc')}
                  id='cpuMaker-new-cif-tabs'
                  className='mb-5'
                >
                  <Tab eventKey='updateKyc' title='CIF Form'>
                    <div
                      style={{
                        maxHeight: '700px',
                        overflowY: 'auto',
                      }}
                    >
                      <KycFormNormalIndividualJointMinorCIF
                        id={id}
                        data={dataStatus}
                        setActiveKey={setActiveKey}
                        setKycFormSubmitted={setKycFormSubmitted}
                      />
                    </div>
                  </Tab>
                  {/* <Tab
                  eventKey='accountOpening'
                  title='Account Opening'
                  data-cy='tab-account-opening'
                >
                  <div data-cy='tab-content-account-opening'>
                    <CpuMakerAccountOpeningForm />
                  </div>
                </Tab> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <AccountStatusLog data={dataStatus} />
        <UserActivityLogAll caseId={id} modalName={dataStatus?.model_name} modalData={dataStatus} />
      </div>
    </>
  )
}

export default KycUpdateFormNormalIndividualJointMinorCIF
