import {Form, Formik} from 'formik'
import React from 'react'
import {Modal} from 'rsuite'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {IPayloadChecker} from 'src/hooks/useGetCheckerTableList/usePostMakerCheckListReview/useReviewPost'
import {usePostUploadSubmitExistingCifCPUChecker} from 'src/hooks/UsePostKYCUpdateCPUChecker/usePostCPUchecker'
import * as Yup from 'yup'

interface SubmitModalProps {
  isOpen: boolean
  handleClose: () => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  id: string
  screeningPerformed: boolean
  accumulatedFilePaths: any
  status?: string
}

const CPUCheckerMOdal = ({
  isOpen,
  handleClose,
  setIsOpen,
  id,
  screeningPerformed,
  accumulatedFilePaths,
  status,
}: SubmitModalProps) => {
  const validationSchema = Yup.object().shape({
    remark: Yup.string()
      .required('Remark Field is required')
      // .matches(/^[a-zA-Z0-9\s]+$/, 'Remark Field cannot contain special characters'),
  })

  const {mutate, isLoading: isLoadingSubmit} = usePostUploadSubmitExistingCifCPUChecker(setIsOpen)

  const handleSubmitData = (values: {remark: string}) => {
    const payload: IPayloadChecker = {
      status: status,
      remarks: values.remark.trim(),
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    mutate({payload, id})
  }
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        className='w-10'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Remarks</Modal.Title>
        </Modal.Header>
        {/* <Spin spinning={isLoadingData}> */}
        <Modal.Body>
          <Formik
            initialValues={{
              remark: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmitData}
          >
            {({touched, handleChange, errors, values}) => {
              return (
                <Form>
                  <div className='card-body border-top pt-5'>
                    {/* <div className='row'> */}
                    {/* <div className='col-md-6 col-sm-6 col-xs-12'> */}
                    <FormTextBox
                      containerClassName='col-lg-12'
                      labelClassName='col-lg-12'
                      type='text'
                      placeholder='Enter Remarks '
                      label='Remarks'
                      name='remark'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                      as='textarea'

                    />
                    {/* </div> */}
                    {/* </div> */}

                    <div className='d-flex justify-content-end '>
                      <button
                        type='submit'
                        disabled={isLoadingSubmit}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        {isLoadingSubmit ? 'Saving...' : 'Save'}
                      </button>

                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CPUCheckerMOdal
