import {Form, Formik} from 'formik'
import {useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {AccountStatusLog} from 'src/app/modules/common/components/AccountStatusLog'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import usePostIndividualQAReview from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostIndividualQAReview'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {UserActivityLogAll} from '../../../../common/components/AllActivityLog'
import {REVIEW_VALIDATION_SCHEMA} from '../../utils/ValidationSchemas'

interface RouteParams {
  id: string
}

const QANormalReviewAndUpdate = () => {
  const dispatch = useDispatch()
  const {id} = useParams<RouteParams>()
  const history = useHistory()
  const {data: preliminariesDatas, isLoading} = useGetPreliminariesDetailsById(id)
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<
    {file_name: string; file_path: string}[]
  >([])
  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')

  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])
  const {
    mutate: QAUnitPostUpdate,
    data: respData,
    isLoading: postRespLoading,
  } = usePostIndividualQAReview()

  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      id,
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    QAUnitPostUpdate(payload, {
      onSuccess: (data) => {
        history.go(-1)
      },
      onError: (error) => {
        console.error('Error:', error)
      },
    })
  }

  const handleBack = () => {
    history.go(-1)
  }

  const getFieldValue = (field: any, key: string) => {
    return field && field[key] ? field[key].toString() : ''
  }

  const allStatusType = [
    {label: 'FORWARD TO CPU MAKER', value: 'approve'},
    {label: 'REJECT', value: 'reject'},
    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
  ]
  const data = preliminariesDatas?.data

  const handleScreening = () => {
    setScreeningLoading(true)

    const checkAllPayload: any = {
      customer_name: data?.full_name,
      citizenship: data?.identification_number,
      model_name: 'NewCIFNewAccount',
    }
    mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
      },
    })
  }
  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      {
        if (checkAllDataJoint && checkAllDataJoint[index]) {
          let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
          if (parts && parts.length > 1) {
            const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

            // Add new paths to the opened file paths state
            setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

            // Open all accumulated file paths
            newPaths.forEach((path: any) => {
              window.open(path, '_blank')
            })
          }
        }
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }
  const additionalScreening = async (cif_number: any, index?: number) => {
    let checkAllPayload: any = {}

    if (update.current === 'minor') {
      const guardian_full_name: string = [
        data?.guardian_first_name,
        data?.guardian_middle_name,
        data?.guardian_last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: guardian_full_name,
        citizenship: data?.identification_number,
        model_name: 'NewCIFNewAccount',
      }
    } else if (update.current === 'joint') {
      const partner = data?.account_holders?.account_holders.find(
        (holder: any) => holder.cif_number === cif_number
      )

      const partners_full_name: string = [
        partner?.first_name,
        partner?.middle_name,
        partner?.last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: partners_full_name,
        date_of_birth_bs: partner?.date_of_birth_bs || '',
        unique_id_no: partner?.unique_id_no || '',
        citizenship: partner?.citizenship || '',
        gender: partner?.gender || '',
        model_name: 'NewCIFNewAccount',
      }
    }

    setEnquiryLoading(true)
    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setEnquiryLoading(false) // Reset loading state on success
        setLoadingIndex(null)
      },
      onError: (error) => {
        console.error('Error:', error)
        setEnquiryLoading(false) // Reset loading state on error
        setLoadingIndex(null)
        clickedIndex.shift()
      },
    })
  }
  const handleGuardianScreening = (cif_number: any) => {
    update.current = 'minor'
    // setFlag(() => 'minor')
    setEnquiryLoading(true) // Set enquiry loading to true
    additionalScreening(cif_number)
  }

  const handlePartnersScreening = (cif_number: any, index: any) => {
    setEnquiryLoading(true) // Set enquiry loading to true
    update.current = 'joint'
    additionalScreening(cif_number, index)
  }

  const handleButtonClick = async (cif_number: string, index: number) => {
    setLoadingIndex(index) // Set the loading index to the clicked button's index
    setTimeout(() => {
      // Update the clicked indices to include the newly clicked index
      setClickedIndex((prevIndices) => {
        // If the index is not already in the array, add it
        if (!prevIndices.includes(index)) {
          return [...prevIndices, index]
        }
        return prevIndices
      })
    }, 1000)
    try {
      await handlePartnersScreening(cif_number, index) // Trigger the enquiry action
    } catch (error) {
      setLoadingIndex(null)
    }
  }
  const getStringValue = (value: any) => {
    if (typeof value === 'string') return value
    if (typeof value === 'number') return value.toString()
    if (value && typeof value === 'object' && 'title' in value) return value.title
    return ''
  }
  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className='row'>
        <div className='col-md-6 col-12 pe-0'>
          <div className='card mb-5'>
            <div className='card-body p-0'>
              <DocumentsPreview data={data} />
            </div>
          </div>
        </div>
        <div
          className='col-md-6 col-12 ps-0'
          style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}
        >
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body'>
              <section className=''>
                <div className='row'>
                  <h3 className='fw-bolder mb-4 text-capitalize' style={{color: 'brown'}}>
                    Account Details
                  </h3>

                  {/* <h2 style={{color: 'brown'}}>Account Details</h2> */}
                  <FormDetails
                    key='screening_id'
                    label='Screening Id'
                    value={getStringValue(data?.screening_id)}
                  />
                  <FormDetails
                    key='account_type_id'
                    label='Account Type'
                    value={getStringValue(data?.account_type_id?.cbs_code)}
                  />
                  <FormDetails
                    key='branch_code'
                    label='Branch Code'
                    value={getStringValue(data?.branch_code)}
                  />
                  <FormDetails
                    key='customer_code'
                    label='Customer Code'
                    value={getStringValue(data?.customer_code)}
                  />
                </div>
              </section>
              <hr />
              <section className=''>
                <h3 className='fw-bolder mb-4 text-capitalize' style={{color: 'brown'}}>
                  Personal Details
                </h3>
                <div className='row'>
                  <FormDetails
                    key='first_name'
                    label='First Name'
                    value={getStringValue(data?.first_name)}
                  />
                  <FormDetails
                    key='middle_name'
                    label='Middle Name'
                    value={getStringValue(data?.middle_name)}
                  />
                  <FormDetails
                    key='last_name'
                    label='Last Name'
                    value={getStringValue(data?.last_name)}
                  />

                  <FormDetails
                    key='id_type_id'
                    label='Identification Type'
                    value={getStringValue(data?.id_type_id)}
                  />
                  <FormDetails
                    key='identification_number'
                    label='Identification Number'
                    value={getStringValue(data?.identification_number)}
                  />
                  <FormDetails
                    key='account_category_id'
                    label='Account Category'
                    value={getStringValue(data?.account_opening?.account_category_id)}
                  />
                  <FormDetails
                    key='account_Scheme_id'
                    label='Account Scheme'
                    value={getStringValue(data?.account_opening?.account_scheme_id)}
                  />
                  <FormDetails
                    key='permanent_mobile_number'
                    label='Mobile Number'
                    value={getStringValue(data?.permanent_mobile_number)}
                  />
                  <FormDetails
                    key=''
                    label='Email Address'
                    value={getStringValue(data?.permanent_email)}
                  />
                </div>
              </section>
              {data?.account_type_id?.title === 'Joint' && (
                <div>
                  <section className=''>
                    <h3 className='fw-bolder mb-4 text-capitalize' style={{color: 'brown'}}>
                      Partner's Details
                    </h3>
                    <div className='row'>
                      {data?.account_holders?.account_holders.map((holder: any, index: any) => (
                        <div key={index} className='row'>
                          <FormDetails
                            key={`cif_number_${index}`}
                            label='CIF Number'
                            value={holder.cif_number}
                          />
                          <FormDetails
                            key={`first_name_${index}`}
                            label='First Name'
                            value={holder.first_name}
                          />
                          <FormDetails
                            key={`middle_name_${index}`}
                            label='Middle Name'
                            value={holder.middle_name}
                          />
                          <FormDetails
                            key={`last_name_${index}`}
                            label='Last Name'
                            value={holder.last_name}
                          />
                          <FormDetails
                            key={`id_type_id_${index}`}
                            label='Identification Type'
                            value={holder.id_type_id?.title}
                          />
                          <FormDetails
                            key={`id_number_${index}`}
                            label='Identification Number'
                            value={holder.id_number}
                          />
                          <hr />
                        </div>
                      ))}
                    </div>
                    {data?.forced_continue && (
                      <div
                        style={{
                          padding: '10px',
                          backgroundColor: '#d4edda',
                          border: '1px solid #c3e6cb',
                          borderRadius: '5px',
                          marginBottom: '20px',
                        }}
                      >
                        <strong>This account was found in the deduplication Screening</strong>
                      </div>
                    )}
                    <div
                      style={{
                        padding: '10px',
                        backgroundColor: '#d4edda',
                        border: '1px solid #c3e6cb',
                        borderRadius: '5px',
                        marginBottom: '20px',
                      }}
                    >
                      <strong>All documents are verified.</strong>
                    </div>
                  </section>
                </div>
              )}
              <div className='row'>
                {screeningPerformed ? (
                  checkAllData && (
                    <Button
                      type='button'
                      onClick={handleOpenPdf}
                      className='col-md-3 col-sm-6 col-xs-12'
                    >
                      View Details
                    </Button>
                  )
                ) : screeningLoading ? (
                  <Spinner animation='border' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    type='button'
                    onClick={handleScreening}
                    className='col-md-6 col-sm-6 col-xs-12'
                  >
                    Perform Screening
                  </Button>
                )}
                <div className='my-7'>
                  <h3>Screening Files</h3>
                  {data?.screening_files?.screening_files?.length > 0 ? (
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>File Name</th>
                          <th>Performed By</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.screening_files?.screening_files?.map((file: any, index: any) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{file.file_name}</td>
                            <td>{file.created_by}</td>
                            <td>
                              <button
                                type='button'
                                onClick={() => handlePdfFileClick(file.file_path)}
                                className='btn btn-primary'
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No files available</p>
                  )}
                </div>
                <div className='modal-container pt-10'>
                  <div className='card'>
                    <div className='card-body'>
                      <Formik
                        initialValues={{status: '', remarks: ''}}
                        onSubmit={(values) => {
                          handleSubmit(values)
                        }}
                        validationSchema={REVIEW_VALIDATION_SCHEMA}
                      >
                        {({handleChange, errors, touched, values, setFieldValue}) => (
                          <Form>
                            <div className='row '>
                              <div className='col-md-8'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  label='Status Type'
                                  name='status'
                                  // isClearable={true}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={allStatusType?.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                  }))}
                                  required={true}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                              <FormTextBox
                                containerClassName=''
                                labelClassName='col-md-12'
                                type='text'
                                placeholder='Remarks'
                                label='Remarks'
                                name='remarks'
                                disabled={false}
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                data-cy='add-id-number-text-field'
                                as='textarea'
                              />
                            </div>
                            <div className='d-flex justify-content-end mt-3'>
                              <button
                                type='button'
                                onClick={handleBack}
                                className='btn btn-secondary mx-4'
                              >
                                Back
                              </button>
                              <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={postRespLoading}
                              >
                                {postRespLoading ? 'Submitting...' : 'Submit'}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-10'>
        <AccountStatusLog data={data} />
        <UserActivityLogAll
          caseId={id}
          modalName={preliminariesDatas?.data?.model_name}
          modalData={preliminariesDatas?.data}
        />
      </div>
    </>
  )
}

export default QANormalReviewAndUpdate
