import axios from 'axios'
import {useQuery} from 'react-query'
import {AccountSchemaType} from 'src/app/modules/common/Model'
import {GET_DEPOSITDETAILS} from 'src/app/queries/api/apiEndPoints/EndPoints'

const QUERY_KEY = 'getDepositDetailsList'

export const useGetDepositDetailsList = (params: AccountSchemaType) => {
  return useQuery(
    [QUERY_KEY, params],
    async () => {
      const {data} = await axios.get(GET_DEPOSITDETAILS, {params})
      return data
    },
    {
      onError: (error) => {
        console.error('Error fetching account schema:', error)
      },
    }
  )
}
