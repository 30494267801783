import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

const API_ENDPOINT = `${BASE_API_URL}/corporate-new-cif-new-account` // Replace this with your actual API endpoint
const API_ENDPOINTCIF = `${BASE_API_URL}/corporate-new-cif`

// type Director = {
//   full_name: string
//   designation: string
// }

// type ShareHolder = {
//   shareholder_name: string
//   ownership_percentage: number
//   shareholder_address: string
//   entity_name: string
//   entity_ownership_percentage: number
//   entity_address: string
// }

// type FatcaDeclaration = {
//   us_person: boolean
//   us_person_remarks: string
//   us_indicia_remarks: string
//   us_telephone_number: string
//   tin_number: string
//   giin_number: boolean
//   giin_number_remarks: string
//   w9_form_submitted: string
// }

// type OtherBank = {
//   bank_name: string
//   saving_ac: string
//   current_ac: string
//   od_ac: string
//   term_loan: string
//   others: string
// }

// type Payload = {
//   directors: Director[]
//   share_holders: ShareHolder[]
//   fatca_declarations: FatcaDeclaration[]
//   other_banks: OtherBank[]
// }

const postCorporateAccount = async (id: string, additionalFormPayload: any) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${id}/kyc-additional-form`,
      additionalFormPayload
    )
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message

  }
}

const usePostAdditionalForms = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: {id: string; additionalFormPayload: any}) =>
      postCorporateAccount(payload.id, payload.additionalFormPayload),
    {
      onSuccess: (response) => {
        if (response?.resCod === 200) {
          toast.success(response?.resDesc ?? 'Corporate account data submitted successfully!')
          queryClient.invalidateQueries('corporateAccountList')
          queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
        }
      },
      onError: (error: any) => {
        if (error.errors) {
          for (const key in error.errors) {
            if (error.errors[key]) {
              error.errors[key].forEach((message: string) => {
                toast.error(message)
              })
            }
          }
        } else if (error.message) {
          toast.error(error.message)
        } else {
          toast.error('An unknown error occurred')
        }
      },
    }
  )
}

export default usePostAdditionalForms

const postCorporateAccountCIF = async (id: string, additionalFormPayload: any) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINTCIF}/${id}/kyc-additional-form`,
      additionalFormPayload
    )
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message

  }
}

export const usePostAdditionalFormsCIF = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: {id: string; additionalFormPayload: any}) =>
      postCorporateAccountCIF(payload.id, payload.additionalFormPayload),
    {
      onSuccess: (response) => {
        toast.success(response?.resDesc ?? 'Corporate account data submitted successfully!')
        // queryClient.invalidateQueries('corporateAccountList')
        // queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
      },
      onError: (error: any) => {
        if (error.errors) {
          for (const key in error.errors) {
            if (error.errors[key]) {
              error.errors[key].forEach((message: string) => {
                toast.error(message)
              })
            }
          }
        } else if (error.message) {
          toast.error(error.message)
        } else {
          toast.error('An unknown error occurred')
        }
      },
    }
  )
}
