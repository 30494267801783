import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {
  GET_NEW_CIF_Corporate,
  UPDATE_NEW_CIF_BM,
  UPDATEKYCDETAILS,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

// type typePayload = {
//   id: string
//   data: {
//     action?: string
//     remarks?: string
//   }
// }

const patchKYCUpdateIndividual = async (payload: any) => {
  const {id, ...data} = payload
  try {
    const response = await axios.patch(`${UPDATEKYCDETAILS}/${id}/update-kyc-details`, data)
    return response
  } catch (error: any) {
    throw error.response?.data || error.message

  }
}

const usePatchKycDetailsIndividual = () => {
  const queryClient = useQueryClient()
  return useMutation(patchKYCUpdateIndividual, {
    onSuccess: (response) => {
      if (response?.data?.resCod == 200) {
        // toast.success(response?.data?.resDesc ?? 'success!')

        queryClient.invalidateQueries('getIndividualAccountLists')
        queryClient.invalidateQueries('ListingNewCIFNewAccount')
      }
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}

export default usePatchKycDetailsIndividual

const patchKYCUpdateIndividualCIF = async (payload: any) => {
  const {id, ...data} = payload
  try {
    const response = await axios.patch(`${UPDATE_NEW_CIF_BM}/${id}/update-kyc-details`, data)
    return response
  } catch (error: any) {
    throw error.response?.data || error.message

  }
}

export const usePatchKycDetailsIndividualCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(patchKYCUpdateIndividualCIF, {
    onSuccess: (response) => {
      // toast.success(response?.data?.resDesc ?? 'success!')
      // queryClient.invalidateQueries('getIndividualAccountLists')
      queryClient.invalidateQueries('preliminariesDetailsByIdCIF')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}
