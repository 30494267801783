import {FieldArray, Form, Formik} from 'formik'
import React, {useEffect, useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {RadioGroup} from 'rsuite'
import {getOptionIdValueByKeyCBS} from 'src/app/modules/common/SplitFunction'
import {IParams} from 'src/app/modules/linkedEntities/common/interface/Interfaces'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {useGetLinkedEntitesData} from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesData'
import usePatchLinkedEntitiesDetails from 'src/app/queries/linkedEntitiesQuery/patch/usePatchLinkedEntitiesDetails'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {BASE_APP_URL} from '../../../../../../../../cms/helpers/constants/common'
import usePostCustomerEnquiryInfo from '../../../../../../../queries/apiMS/usePostCUstomerEnquiryInfo'
import usePostCheckAll from '../../../../../../../queries/apiMS/usePostCheckAllAndGeneratePdf'
import DetailView from '../../../../../common/components/detailView/detailView'
import {validationSchemaIndividual} from '../../../utils/validationSchemas/ValidationSchemas'
import usePostCorporateEnquiryInfo from 'src/app/queries/apiMS/usePostCorporateEnquiryInfo'

interface ToBeLinkedEntitiesRevertCaseProps {
  otherData: any
  onSubmitValues: any
  screeningPerformed: any
  accumulatedFilePaths: any
  setAccumulatedFilePaths: any
}

const ToBeLinkedEntitiesRevertCase: React.FC<ToBeLinkedEntitiesRevertCaseProps> = ({
  otherData,
  onSubmitValues,
  screeningPerformed,
  accumulatedFilePaths,
  setAccumulatedFilePaths,
}) => {
  const {id} = useParams<IParams>()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const history = useHistory()
  const {mutate: patchCPUMakerLinkedEntities} = usePatchLinkedEntitiesDetails()
  const userBranchCode = user?.data?.branch?.code
  const {data: onSubmitCIFDatas} = useGetLinkedEntitesData(onSubmitValues, {
    enabled: Boolean(onSubmitValues?.cif_number),
  })
  const {allMasterDataOptions} = usePlaceMasterData({
    allMasterData: true,
  })
  const [searchParams, setSearchParams] = useState<{
    relation_cif_number: string
    isCorporate: boolean
    index: number
  } | null>(null)
  const [searchResults, setSearchResults] = useState<{[key: number]: any}>({})
  const [loadingStates, setLoadingStates] = useState<{[key: number]: boolean}>({})

  const [checkAllData, setCheckAllData] = useState<any>()
  const [currentIndex, setCurrentIndex] = useState<any>()

  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])

  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null)
  const [linkedFiles, setlinkedFiles] = useState<number | null>(null)
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [enquiryData, setEnquiryData] = useState<any>()
  const [linkedScreening, setLinkedScreening] = useState<any>(false)
  const refinedData = onSubmitCIFDatas?.data
  const primaryCifNumber = refinedData?.AccountDetail[0]?.CUST_ID
  const customerName = refinedData?.AccountDetail[0]?.ACCT_NAME
  const is_CorporateFlag = onSubmitValues?.is_corporate
  const is_CorporateFlagString =
    is_CorporateFlag === true || is_CorporateFlag === false ? is_CorporateFlag.toString() : ''

  const initialValues = {
    action: 'save_and_continue',
    is_corporate: is_CorporateFlagString,
    primary_customer_name: customerName,
    primary_cif_number: primaryCifNumber,
    branch_code: userBranchCode,
    primary_account_number: otherData?.data?.primary_account_number,
    primary_customer_email: '',
    unlink_flag: false,
    linked_relations: otherData?.data?.relationship_data?.linked_relationships?.map(
      (relation: any) => ({
        relation_cif_number: relation?.relation_cif_number || '',
        relationship_id: relation?.relationship_id?.id || '',
        relation_name: relation?.relation_name || '',
        account_type: relation?.account_type,
      })
    ) || [
      {
        relation_cif_number: '',
        relationship_id: '',
        relation_name: '',
        account_type: '',
      },
    ],
  }
  const update = useRef<any>('joint')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )

  const {mutate: enquiry} = usePostCustomerEnquiryInfo(setEnquiryData)
  const {mutate: enquiryCorporate} = usePostCorporateEnquiryInfo(setEnquiryData)

  const handleEnquiry = async (index: number, values: any) => {
    setScreeningLoading(true)

    setCurrentIndex(index)
    const cifNumber = values?.linked_relations[index]?.relation_cif_number
    const isCorporate = values?.linked_relations[index]?.account_type
    if (cifNumber) {
      if (isCorporate === false) {
        enquiry(
          {cifNumber, index},
          {
            onSuccess: (data: any) => {},
            onError: (error: any) => {
              setLoadingIndex(null)
              console.error('Error:', error)
            },
          }
        )
      } else {
        enquiryCorporate(
          {cifNumber, index},
          {
            onSuccess: (data: any) => {},
            onError: (error: any) => {
              setLoadingIndex(null)
              console.error('Error:', error)
            },
          }
        )
      }
    }
  }

  useEffect(() => {
    if (enquiryData) {
      const data: any = enquiryData

      const checkAllPayload: any = {
        customer_name: data?.data?.generalDetails[0]?.CUST_NAME,
        unique_id_no: data?.data?.generalDetails[0]?.CUST_ID,
        model_name: 'LinkedEntities',
      }

      mutateCheckAll(checkAllPayload, {
        onSuccess: (response: any) => {},
        onError: (error: any) => {},
      }) // <-- You were missing this closing parenthesis
    }
  }, [enquiryData]) // <-- Correctly closing useEffect here

  const {
    data: searchData,
    isSuccess,
    isLoading,
  } = useGetLinkedEntitesData(
    {
      cif_number: searchParams?.relation_cif_number || '',
      is_corporate: searchParams?.isCorporate || false,
    },
    {enabled: !!searchParams}
  )

  useEffect(() => {
    if (isSuccess && searchData && searchParams !== null) {
      setSearchResults((prevState) => ({
        ...prevState,
        [searchParams.index]: searchData,
      }))
      setLoadingStates((prevState) => ({
        ...prevState,
        [searchParams.index]: false,
      }))
    }
  }, [searchData, isSuccess, searchParams])

  const handleSubmit = (values: any, {setSubmitting}: any) => {
    const payload = {
      ...values,
      id,
      action: values.action,
      is_corporate: onSubmitValues?.is_corporate?.toString(),
      is_file_path_exists: screeningPerformed ? screeningPerformed : linkedScreening,
      files: accumulatedFilePaths,
    }
    patchCPUMakerLinkedEntities(payload, {
      onSuccess: () => {
        setSubmitting(false)
        history.go(-1)
      },
      onError: (error: any) => {
        setSubmitting(false)
      },
    })
  }

  const handleOpenPdf = () => {
    if (checkAllDataJoint) {
      let parts = checkAllDataJoint?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleSearchDetails = (
    relation_cif_number: string,
    isCorporate: boolean,
    index: number
  ) => {
    setLoadingStates((prevState) => ({...prevState, [index]: true}))
    setSearchParams({relation_cif_number, isCorporate, index})
  }

  const handlePdf = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      {
        if (checkAllDataJoint && checkAllDataJoint[index]) {
          let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
          if (parts && parts.length > 1) {
            const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

            // Add new paths to the opened file paths state
            setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

            // Open all accumulated file paths
            newPaths.forEach((path: any) => {
              window.open(path, '_blank')
            })
          }
        }
        // let parts = checkAllDataJoint[index as number]?.data?.file_path?.split('/public/')
        // const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
        // window.open(modifiedFilePath, '_blank')
      }
    } else if (checkAllDataJoint) {
      let parts = checkAllDataJoint?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }

  const handleButtonClick = async (index: number, values: any) => {
    setLoadingIndex(index)
    setCurrentIndex(index)
    setLinkedScreening(true)

    // try {
    await handleEnquiry(index, values) // Trigger the enquiry action
    // } catch (error) {
    //   setLoadingIndex(null)
    // }
  }

  return (
    <div className='mt-5'>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={validationSchemaIndividual}
      >
        {({
          errors,
          handleChange,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
          setSubmitting,
          isSubmitting,
        }: any) => (
          <Form>
            <FieldArray
              name='linked_relations'
              render={(arrayHelpers) => (
                <>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='heading__component__title fw-bolder text-uppercase'>
                      Entities To Be Linked
                    </h3>
                  </div>
                  {values.linked_relations &&
                    values.linked_relations.length > 0 &&
                    values.linked_relations.map((relation: any, index: any) => (
                      <div key={index} className='row mb-3 align-items-center'>
                        <div className='row mt-8'>
                          <p style={{opacity: '50%'}}>Please Select account type</p>
                        </div>

                        {/* Radio Group for Account Type */}
                        <div className='row mb-4'>
                          <RadioGroup
                            name={`linked_relations.${index}.account_type`} // Bind to the specific field
                            value={values.linked_relations[index]?.account_type || ''} // Correctly bind the value
                            onChange={(value) => {
                              setFieldValue(`linked_relations.${index}.account_type`, value) // Dynamically update Formik state
                            }}
                          >
                            <div className='radio-buttons-container d-flex'>
                              <FormRadio
                                name={`linked_relations.${index}.account_type`}
                                value='individual'
                                checkBoxText='Individual'
                                checked={values.linked_relations[index]?.account_type === "false"} // Dynamically set checked
                                onChange={() =>
                                  setFieldValue(`linked_relations.${index}.account_type`, "false")
                                }
                              />
                              <FormRadio
                                name={`linked_relations.${index}.account_type`}
                                value='corporate'
                                checkBoxText='Corporate'
                                checked={values.linked_relations[index]?.account_type === "true"} // Dynamically set checked
                                onChange={() =>
                                  setFieldValue(`linked_relations.${index}.account_type`, "true")
                                }
                              />
                            </div>
                          </RadioGroup>
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12'>
                          <FormTextBox
                            uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Enter Relation CIF Number'
                            name={`linked_relations.${index}.relation_cif_number`}
                            label='Relation CIF Number'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12'>
                          <FormTextBox
                            uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Enter Relation Name'
                            name={`linked_relations.${index}.relation_name`}
                            label='Relation Name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            value={
                              searchResults[index]
                                ? searchResults[index]?.AccountDetail?.[0]?.ACCT_NAME ?? ''
                                : values?.linked_relations[index]?.relation_name
                            }
                          />
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-lg-12'
                            placeholder='Select'
                            label='Relationship Declaration'
                            name={`linked_relations.${index}.relationship_id`}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            arrValue={values?.linked_relations[index]?.relationship_id}
                            options={allMasterDataOptions?.account_relations?.map(
                              (relationShip: any) => ({
                                value: relationShip.id || relationShip.value,
                                label: relationShip.title || relationShip.label,
                              })
                            )}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12 d-flex justify-content-between'>
                          {checkAllDataJoint &&
                          checkAllDataJoint?.some(
                            (item: any) =>
                              item.cif_number ===
                              values?.linked_relations[index]?.relation_cif_number
                          ) ? (
                            <Button
                              className='mb-5 ms-3'
                              onClick={() => {
                                handlePdf(index)
                              }}
                            >
                              View PDF
                            </Button>
                          ) : loadingIndex === index ? (
                            <Spinner animation='border' role='status' size='sm'>
                              <span className='sr-only'>Loading...</span>
                            </Spinner>
                          ) : (
                            <Button
                              name={`enquiry-${index}`}
                              key={index}
                              className='mb-5'
                              onClick={async () => {
                                // Trigger validation for the `relation_cif_number` field
                                const fieldName = `linked_relations.${index}.relation_cif_number`
                                setFieldTouched(fieldName, true) // Mark the field as touched
                                await setFieldValue(
                                  fieldName,
                                  values.linked_relations[index].relation_cif_number
                                ) // Update field value in case it's stale

                                // Check for errors
                                if (
                                  !values.linked_relations[index].relation_cif_number ||
                                  errors[fieldName]
                                ) {
                                  return // Prevent further execution if validation fails
                                }

                                // Proceed with Search & Enquiry
                                handleSearchDetails(
                                  values.linked_relations[index]?.relation_cif_number,
                                  values.linked_relations[index]?.account_type,
                                  index
                                )
                                handleButtonClick(index, values)
                              }}
                            >
                              Search & Enquire
                            </Button>
                          )}
                          {searchResults[index] && (
                            <Button
                              type='button'
                              className='btn btn-warning ms-2'
                              onClick={() => {
                                // Reset the specific linked relation fields
                                setFieldValue(`linked_relations.${index}`, {
                                  relation_cif_number: '',
                                  relationship_id: '',
                                  relation_name: '',
                                })
                                // Remove the corresponding search result
                                setSearchResults((prevState) => {
                                  const newState = {...prevState}
                                  delete newState[index]
                                  return newState
                                })
                                setLoadingIndex(null)
                              }}
                            >
                              Reset
                            </Button>
                          )}
                          {index > 0 && (
                            <Button
                              type='button'
                              className='btn btn-danger'
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Delete
                            </Button>
                          )}
                        </div>

                        {searchResults[index] &&
                          searchResults[index]?.data?.GeneralDetails?.length > 0 && (
                            <>
                              <div className='row mb-4'>
                                <div className='col-md-3'>
                                  <DetailView
                                    label={'CIF Number'}
                                    value={searchResults[index]?.data?.GeneralDetails[0]?.CUST_ID}
                                  />
                                </div>
                                <div className='col-md-3'>
                                  <DetailView
                                    label={'Full Name'}
                                    value={searchResults[index]?.data?.GeneralDetails[0]?.CUST_NAME}
                                  />
                                </div>
                                <div className='col-md-3'>
                                  <DetailView
                                    label={'Contact Number'}
                                    value={searchResults[index]?.data?.GeneralDetails[0]?.MOBILE_NO}
                                  />
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <div className='col-md-3'>
                                  <DetailView
                                    label={'ID Number'}
                                    value={searchResults[index]?.data?.GeneralDetails[0]?.IDNO}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    ))}
                  {/* Add Relation button moved outside of the loop */}
                  <div className='text-center mt-4'>
                    <Button
                      type='button'
                      className='btn'
                      onClick={() =>
                        arrayHelpers.push({
                          relation_cif_number: '',
                          relationship_id: '',
                          relation_name: '',
                        })
                      }
                    >
                      Add Relation
                    </Button>
                  </div>
                </>
              )}
            />
            <div className='text-end'>
              <Button
                type='submit'
                style={{margin: '15px 10px 15px 6px'}}
                onClick={() => {
                  // Set the action value to 'save' for "Save as Draft"
                  setFieldValue('action', 'save', false) // Pass `false` to prevent Formik from immediately validating
                  // setTimeout(() => {
                  //   // Call handleSubmit when the action field is updated
                  //   handleSubmit(
                  //     values,
                  //     {
                  //       setSubmitting: () => {},
                  //     },
                  //     isSubmitting
                  //   )
                  // }, 100) // Ensure the state update is completed before submission
                }}
              >
                Save as Draft
              </Button>
              <Button
                type='submit'
                style={{margin: '15px 10px 15px 6px'}}
                onClick={() => {
                  if (isSubmitting) return // Don't call submit if it's already being submitted

                  setFieldValue('action', 'save_and_continue', false)
                  // // setTimeout(() => {
                  // handleSubmit(values, {setSubmitting: () => {}}, isSubmitting)
                  // // }, 0)
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ToBeLinkedEntitiesRevertCase
