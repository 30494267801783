import { useState } from 'react'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as placeReducer from 'src/app/modules/common'
import { useGetAllMasterData } from 'src/app/queries/getMasterData/getAllMasterData'
import { useGetCorporatePreliminariesDetailsByIdCIF } from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import { useUpdateCorporateAccountPreliminaryCIF } from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/update/useUpdateCorporatePreliminaries'
import { RootState } from 'src/setup'

import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'

interface RouteParams {
  id: string
}
const DocumentUploadForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams<RouteParams>()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const userBranchCode = user?.data?.branch?.code

  const {data: allMasterData} = useGetAllMasterData()
  const {mutate: updateExpressCorporateAccountPreliminary} =
    useUpdateCorporateAccountPreliminaryCIF()
  const {data: corporateDataById, isLoading} = useGetCorporatePreliminariesDetailsByIdCIF(id)

  const [masterData, setMasterData] = useState<any>()
  const [isSave, setIsSave] = useState(false)

  useEffect(() => {
    if (allMasterData) {
      setMasterData(allMasterData)
    }
  }, [allMasterData])

  const {district, province, zone, municipality} = useSelector((state: RootState) => state.place)
  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getMunicipality())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getZone())
  }, [])

  const handleSubmit = (values: any, actions: any) => {
    const payload = {...values, id}
    payload.action = isSave ? 'save_and_continue' : 'save'
    updateExpressCorporateAccountPreliminary(payload, {
      onSuccess: () => {
        actions.setSubmitting(false)
        history.go(-1)
      },
      onError: (error) => {
        actions.setSubmitting(false)
      },
    })
    // history.push('new-cif-new-account-lists')
  }

  const {
    allMasterDataOptions,
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading: masterDataLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  const getFieldValue = (field: any, key: string) => {
    return field && field[key] ? field[key].toString() : ''
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <section>
            <div className='row'>
              {' '}
              <FormDetails
                key='branch_code'
                label='Branch Code'
                value={corporateDataById?.data?.branch_code}
              />
              <FormDetails
                key='screening_id'
                label='Screening Id'
                value={corporateDataById?.data?.screening_id}
              />
              <FormDetails
                key='customer_code'
                label='Customer Code'
                value={corporateDataById?.data?.customer_code}
              />
              <h3 className='fw-bolder mb-4 text-capitalize'>Company Information</h3>
              <FormDetails
                key='institution_name'
                label='Name of Institution'
                value={corporateDataById?.data?.institution_name}
              />
              <FormDetails
                key='constitution_id'
                label='Constitution'
                value={corporateDataById?.data?.constitution_id?.title}
              />
              <FormDetails
                key='registration_number'
                label='Registration Number'
                value={corporateDataById?.data?.registration_number}
              />
              {/* <FormDetails
                key='account_category_id'
                label='Account Category'
                value={corporateDataById?.data?.account_opening?.account_category_id?.title}
              />
              <FormDetails
                key='account_Scheme_id'
                label='Account Scheme'
                value={corporateDataById?.data?.account_opening?.account_scheme_id?.title}
              /> */}
              {corporateDataById?.data?.pan_number && (
                <>
                  <FormDetails
                    key='pan_number'
                    label='Pan Number'
                    value={corporateDataById?.data?.pan_number}
                  />
                </>
              )}
              {corporateDataById?.data?.vat_number && (
                <>
                  <FormDetails
                    key='vat_number'
                    label='Vat Number'
                    value={corporateDataById?.data?.vat_number}
                  />
                </>
              )}
            </div>
          </section>
          <hr />
        </div>
      </div>
    </>
  )
}

export default DocumentUploadForm
