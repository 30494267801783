import {bsToAd} from '@sbmdkl/nepali-date-converter'
import {ErrorMessage, FormikErrors, FormikTouched} from 'formik'
import moment from 'moment'
import {NepaliDatePicker} from 'datepicker-reactjs-nepali'
import 'datepicker-reactjs-nepali/dist/index.css'
import {NepaliDatePickerProps} from 'nepali-datepicker-reactjs/dist/NepaliDatePicker/Types'
import '../../../../app/modules/new-cif-new-account/bankMaker/createNewAccountCorporate/styles/DatePickerStyle.css'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {relative} from 'path'

interface FormNepaliCalendarDatePickerProps extends NepaliDatePickerProps {
  className?: string
  nepaliFormName: string
  englishFormName: string
  label?: string
  required?: boolean
  touched: FormikTouched<any>
  values: any
  disabled?: boolean // Add this line
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<unknown>>
  valueObject?: string
}

const FormNepaliCalendarDatePicker = ({
  values,
  setFieldValue,
  className,
  nepaliFormName,
  englishFormName,
  required,
  disabled, // Destructure this prop
  touched,
  label,
  valueObject,
  ...props
}: FormNepaliCalendarDatePickerProps) => {
  const [displayValue, setDisplayValue] = useState<boolean>(true)

  useEffect(() => {
    // If English date is empty, reset the Nepali date
    if (!values[nepaliFormName]) setDisplayValue(true)
    else setDisplayValue(false)
  }, [values[nepaliFormName]])
  return (
    
    <>
      <div style={{position: 'relative'}}>
        {label ? (
          <label className={`mb-1 fw-bolder fs-6 col-12  `}>
            <span className={required ? 'required' : ''}>{label}</span>
          </label>
        ) : null}
        <div className={`nepali-date-wrapper ${disabled ? 'disabled ' : ''}`}>
          <div style={{position: 'relative'}}>
            <NepaliDatePicker
              value={valueObject ? valueObject : values[nepaliFormName]}
              inputClassName={`form-control border nepali-date-input ${
                className ? className : ''
              } ${disabled ? 'bg-warning w-100 p-3 rounded-2' : ''}`}
              onChange={(value: string) => {
                if (disabled) return // Prevent change if disabled
                touched[nepaliFormName] = true
                setFieldValue(nepaliFormName, value)
                setFieldValue(englishFormName, moment(bsToAd(value)).toDate())
              }}
              // onSelect={handleChange}
              options={{calenderLocale: 'ne', valueLocale: 'en'}}
              {...props}
            />
            {displayValue && (
              <span
                className='placeholder-text'
                style={{
                  position: 'absolute',
                  left: '12px',
                  top: '25%',
                  color: 'rgb(153, 153, 153)',
                  background: 'white',
                  fontSize: '15px',
                }}
              >
                YYYY-MM-DD
              </span>
            )}
          </div>
        </div>

        <div className='fv-plugins-message-container text-danger fw-bolder small text-start mt-1'>
          <ErrorMessage name={nepaliFormName} component='div' className='field-error-message' />
        </div>
      </div>
    </>
  )
}

export default FormNepaliCalendarDatePicker
