import {Form, Formik} from 'formik'
import {useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {AccountStatusLog} from 'src/app/modules/common/components/AccountStatusLog'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import useGetCorporatePreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import usePostCorporateBranchCheckeReview from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/post/usePostCorporateBranchCheckerReview'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import * as Yup from 'yup'
import {UserActivityLogAll} from '../../../../common/components/AllActivityLog'

interface RouteParams {
  id: string
}

interface LocationState {
  rowData?: any
  flag?: any
}

const NormalCorporateReview = () => {
  const location = useLocation<LocationState>()
  const {rowData, flag} = location.state || {}
  console.log(flag)
  const dispatch = useDispatch()
  const {id} = useParams<RouteParams>()
  const history = useHistory()
  const {data: allMasterData} = useGetAllMasterData()
  const {data: corporateDataById, isLoading} = useGetCorporatePreliminariesDetailsById(id)
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])

  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])

  const {
    mutate: branchCheckerCorporateReview,
    data: respData,
    isLoading: postRespLoading,
  } = usePostCorporateBranchCheckeReview()

  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      id,
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    branchCheckerCorporateReview(payload, {
      onSuccess: (data) => {
        history.go(-1)
      },
      onError: (error) => {
        console.error('Error:', error)
      },
    })
  }

  const handleBack = () => {
    history.go(-1)
  }

  const getFieldValue = (field: any, key: string) => {
    return field && field[key] ? field[key].toString() : ''
  }

  const allStatusType = [
    {label: 'FORWARD TO BRANCH MAKER', value: 'approve'},
    {label: 'REJECT', value: 'reject'},
    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
  ]
  const handleScreening = async () => {
    setScreeningLoading(true) // Set screening loading to true

    update.current = 'default'
    const checkAllPayload: any = {
      customer_name: corporateDataById?.data?.institution_name,
      pan_number: corporateDataById?.data?.pan_number,
      model_name: 'NewCIFNewAccount',
    }
    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }
  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <div className=''>
            <section>
              <div className='row'>
                {' '}
                <FormDetails
                  key='branch_code'
                  label='Branch Code'
                  value={corporateDataById?.data?.branch_code}
                />
                <FormDetails
                  key='screening_id'
                  label='Screening Id'
                  value={corporateDataById?.data?.screening_id}
                />
                <FormDetails
                  key='customer_code'
                  label='Customer Code'
                  value={corporateDataById?.data?.customer_code}
                />
                <h3 className='fw-bolder mb-4 text-capitalize' style={{color: 'brown'}}>
                  Company Information
                </h3>
                <FormDetails
                  key='institution_name'
                  label='Name of Institution'
                  value={corporateDataById?.data?.institution_name}
                />
                <FormDetails
                  key='constitution_id'
                  label='Constitution'
                  value={corporateDataById?.data?.constitution_id?.title}
                />
                <FormDetails
                  key='registration_number'
                  label='Registration Number'
                  value={corporateDataById?.data?.registration_number}
                />
                <FormDetails
                  key='account_category_id'
                  label='Account Category'
                  value={corporateDataById?.data?.account_opening?.account_category_id?.title}
                />
                <FormDetails
                  key='account_Scheme_id'
                  label='Account Scheme'
                  value={corporateDataById?.data?.account_opening?.account_scheme_id?.title}
                />
                {corporateDataById?.data?.pan_number && (
                  <>
                    <FormDetails
                      key='pan_number'
                      label='Pan Number'
                      value={corporateDataById?.data?.pan_number}
                    />
                  </>
                )}
                {corporateDataById?.data?.vat_number && (
                  <>
                    <FormDetails
                      key='vat_number'
                      label='Vat Number'
                      value={corporateDataById?.data?.vat_number}
                    />
                  </>
                )}
              </div>
            </section>
            {corporateDataById?.data?.forced_continue && 
            <div
              style={{
                padding: '10px',
                backgroundColor: '#d4edda',
                border: '1px solid #c3e6cb',
                borderRadius: '5px',
                marginBottom: '20px',
              }}
              >
              <strong>This account was found in the deduplication Screening</strong>
            </div>
            }
            {flag === true ? null : (
              <div className='row'>
                {screeningPerformed ? (
                  checkAllData && (
                    <Button
                      type='button'
                      onClick={handleOpenPdf}
                      className='col-md-2 col-sm-6 col-xs-12'
                    >
                      View Details
                    </Button>
                  )
                ) : screeningLoading ? (
                  <Spinner animation='border' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    type='button'
                    onClick={handleScreening}
                    className='col-md-2 col-sm-6 col-xs-12'
                  >
                    Perform Screening
                  </Button>
                )}
              </div>
            )}
            <div className='my-7 col-12 col-md-6'>
              <h3>Screening Files</h3>

              {corporateDataById?.data?.screening_files?.screening_files?.length > 0 ? (
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>File Name</th>
                      <th>Performed By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {corporateDataById?.data?.screening_files?.screening_files?.map(
                      (file: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{file.file_name}</td>
                          <td>{file.created_by}</td>
                          <td>
                            <button
                              type='button'
                              onClick={() => handlePdfFileClick(file.file_path)}
                              className='btn btn-primary'
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <p>No files available</p>
              )}
            </div>
            {flag === true ? null : (
              <Formik
                initialValues={{status: '', remarks: '', accountOpeningConfirmation: false}}
                validationSchema={Yup.object().shape({
                  status: Yup.string().required('Status is required'),
                  remarks: Yup.string()
                    .required('Remarks is required')
                    .max(255, 'Remarks cannot exceed 255 characters'),
                  accountOpeningConfirmation: Yup.boolean().when('status', {
                    is: 'approve', // Require checkbox only when "Approve" is selected
                    then: Yup.boolean().oneOf(
                      [true],
                      'You must confirm that all required documents for the Account opening form have been physically checked and verified'
                    ),
                    otherwise: Yup.boolean().notRequired(), // Checkbox is not required for other statuses
                  }),
                })}
                onSubmit={handleSubmit}
              >
                {({handleChange, errors, touched, values, setFieldValue}) => (
                  <Form>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='col-md-12 p-5'>
                          <FormCheckbox
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            name='accountOpeningConfirmation'
                            touched={touched}
                            errors={errors}
                            onChange={handleChange}
                            checkBoxText='I hereby confirm that all required documents for Account opening form have been physically checked, verified with original and found correct.'
                            required={values.status === 'approve'} // Show as required only if status is "approve"
                            checked={values?.accountOpeningConfirmation}
                          />
                        </div>
                        {/* Status Dropdown */}
                        <FormSelect
                          labelClassName='col-12 col-md-6'
                          containerClassName='col-md-12'
                          label='Status Type'
                          name='status'
                          isClearable={true}
                          onChange={(selected: any) => {
                            console.log(selected)
                            handleChange(selected) // Update the status value
                            if (selected === 'reject' || selected === 'revert') {
                              // Reset the checkbox only if the status is "reject" or "revert"
                              setFieldValue('accountOpeningConfirmation', false)
                            }
                          }}
                          errors={errors}
                          touched={touched}
                          options={allStatusType.map((item) => ({
                            label: item.label,
                            value: item.value,
                          }))}
                          required={true}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      {/* Remarks Field */}
                      <FormTextBox
                        containerClassName='col-12 col-md-6'
                        labelClassName='col-md-12'
                        type='text'
                        placeholder='Remarks'
                        label='Remarks'
                        name='remarks'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required={true}
                        as='textarea'
                      />
                    </div>
                    <div className='d-flex justify-content-end mt-3'>
                      <button type='button' onClick={handleBack} className='btn btn-secondary mx-4'>
                        Back
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        disabled={values.status === 'approve' && !values.accountOpeningConfirmation} // Disable if status is "approve" and checkbox is unchecked
                      >
                        {postRespLoading ? 'Submitting...' : 'Submit'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>

      <AccountStatusLog data={corporateDataById?.data} />
      <UserActivityLogAll
        caseId={id}
        modalName={corporateDataById?.data?.model_name}
        modalData={corporateDataById?.data}
      />
    </>
  )
}

export default NormalCorporateReview
