import {useState, useEffect} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {FaEye, FaPlus} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {useGetIndividualJointMinorNewCIFandAccountList} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetBranchCheckerLists'
import {useGetCorporateNewCIFandAccountList} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporatePreliminariesAccountList'
import CheckPermissions from '../common/checkPermission'
import IndividualJointMinorTable from './bankMaker/createNewCIFAndAccount/components/tables/IndividualJointMinorTable'
import CorporateBankTable from './bankMaker/createNewCIFAndAccount/components/tables/corporateBankTable'
import CorporateCompleteTable from './bankMaker/createNewCIFAndAccount/components/tables/corporateCompleteTable'
import CorporateBankCompleteTable from './bankMaker/createNewCIFAndAccount/components/tables/corporateCompleteTable'
import IndividualJointMinorCompleteTable from './bankMaker/createNewCIFAndAccount/components/tables/individualCompleteTable'
import {useGetOnlineAccountOpening} from 'src/app/queries/onlineAccountOpening'
import IndividualJointOnlineTable from './bankMaker/createNewCIFAndAccount/components/tables/individualMinorOnlineTable'
import RpaCorporateBankTable from './bankMaker/createNewCIFAndAccount/components/tables/rpaCorporateBankTable'
import RpaIndividualJointMinorTable from './bankMaker/createNewCIFAndAccount/components/tables/rpaIndividualJointMinorTable'

const NewCIFAccount = () => {
  const history = useHistory()
  const roleName = useSelector((state: any) => state.auth?.user?.data?.roles)

  // Lowercase role names and convert spaces to underscores
  const formattedRoleNames =
    roleName?.map((role: any) => role.name.toLowerCase().replace(/\s+/g, '_')) || []

  // Define available roles and their display names
  const roleConfig = {
    branch_maker: 'Branch Maker',
    branch_checker: 'Branch Checker',
    qa: 'QA',
    cpu_maker: 'CPU Maker',
    cpu_checker: 'CPU Checker',
    superadmin: 'Super Admin',
  }

  // Determine if the current user is a superadmin.
  const hasSuperAdmin = formattedRoleNames.includes('superadmin')

  // If the user is superadmin, show all individual roles in a pre-determined order.
  const superadminAccessibleRoles = [
    'branch_maker',
    'cpu_maker',
    'branch_checker',
    'cpu_checker',
    'qa',
  ]
  const accessibleRoles = hasSuperAdmin
    ? superadminAccessibleRoles
    : formattedRoleNames.filter((role: any) => Object.keys(roleConfig).includes(role))

  // Set the default parent tab.
  // If the user is superadmin, we default to branch_maker
  const defaultRole = hasSuperAdmin ? 'branch_maker' : accessibleRoles[0] || 'branch_maker'
  const [roleTab, setRoleTab] = useState(defaultRole)
  const [activeKey, setActiveKey] = useState('individualJointMinor')

  const [params, setParams] = useState({
    user_type: defaultRole,
    cif_number: '',
    opening_mode: 'normal',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    search: '',
    orderBy: 'updated_at',
    order: 'desc',
  })

  const [completeParams, setCompleteParams] = useState({
    user_type: defaultRole,
    account_status: 'completed',
    cif_number: '',
    opening_mode: 'normal',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    search: '',
    orderBy: 'updated_at',
    order: 'desc',
  })

  // New rpa params for the RPA tabs.
  const [rpaParams, setRpaParams] = useState({
    // user_type: defaultRole,
    account_status: 'rpa',
    cif_number: '',
    opening_mode: 'normal',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    search: '',
    orderBy: 'updated_at',
    order: 'desc',
  })

  const [onlineParams, setOnlineParams] = useState({
    user_type: defaultRole,
    cif_number: '',
    opening_mode: 'normal',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    search: '',
    orderBy: 'updated_at',
    order: 'desc',
  })

  // Update API query parameters when roleTab changes.
  useEffect(() => {
    setParams((prev) => ({...prev, user_type: roleTab}))
    setCompleteParams((prev) => ({...prev, user_type: roleTab}))
    setOnlineParams((prev) => ({...prev, user_type: roleTab}))
    setRpaParams((prev) => ({...prev})) // update rpaParams accordingly if needed
  }, [roleTab])

  // Determine the effective params based on the activeKey.
  const effectiveParams = activeKey.includes('completed')
    ? completeParams
    : activeKey.includes('rpa')
    ? rpaParams
    : params

  const {data: newCorporateCIFandAccountListData, isLoading: corporateLoading} =
    useGetCorporateNewCIFandAccountList(effectiveParams)

  const {data: newCIFandAccountListData, isLoading: individualLoading} =
    useGetIndividualJointMinorNewCIFandAccountList(effectiveParams)

  const {data: onlineAccountOpeningList, isLoading: isOnlineAccountOpeningLoading} =
    useGetOnlineAccountOpening(
      activeKey.includes('completed')
        ? {...onlineParams, account_status: 'completed'}
        : activeKey.includes('verified')
        ? {...onlineParams, account_status: 'verified'}
        : activeKey.includes('unverified')
        ? {...onlineParams, account_status: 'unverified'}
        : onlineParams
    )

  console.log(formattedRoleNames)

  return (
    <div>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
            <div className='col-lg-6'>
              <div className='heading__component__title fw-bolder'>New Account Lists</div>
            </div>
            {/* Show create button for branch maker / superadmin (only on branch_maker tab) */}
            <div className='col-lg-6'>
              <div className='d-flex justify-content-end'>
                {(formattedRoleNames.includes('branch_maker') ||
                  formattedRoleNames.includes('superadmin')) &&
                  roleTab === 'branch_maker' && (
                    <div
                      className='sub_button'
                      onClick={() => history.push('/new-cif-new-accounts/account-opening-requests')}
                    >
                      <button
                        className='dt-btn dt-btn-primary d-flex align-items-center'
                        data-cy='add-button'
                      >
                        <FaPlus className='me-2' />
                        Create New CIF / Account
                      </button>
                    </div>
                  )}
                <div
                  className='sub_button'
                  onClick={() => history.push('/new-cif-new-accounts/rejected-account-lists')}
                >
                  <button
                    className='dt-btn dt-btn-primary d-flex align-items-center'
                    data-cy='add-button'
                  >
                    <FaEye className='me-2' />
                    View Rejected List
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            activeKey={roleTab}
            onSelect={(k) => setRoleTab(k ?? (accessibleRoles[0] || 'branch_maker'))}
            id='role-tabs'
            className='mb-3'
          >
            {accessibleRoles.map((role: any) => (
              <Tab key={role} eventKey={role} title={roleConfig[role as keyof typeof roleConfig]}>
                <Tabs
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k ?? 'individualJointMinor')}
                  id={`${role}-new-cif-account-tabs`}
                  className='mb-5'
                  data-cy='new-cif-account-list-tabs'
                >
                  <Tab eventKey='individualJointMinor' title='Pending Individual/Joint/Minor'>
                    <div className='card-body bg-white'>
                      <IndividualJointMinorTable
                        newCIFandAccountListData={newCIFandAccountListData}
                        setParams={setParams}
                        params={params}
                        loading={individualLoading}
                        roleName={roleTab}
                      />
                    </div>
                  </Tab>
                  <Tab eventKey='corporateBank' title='Pending Corporate'>
                    <div className='card-body bg-white'>
                      <CorporateBankTable
                        newCorporateCIFandAccountListData={newCorporateCIFandAccountListData}
                        setParams={setParams}
                        params={params}
                        loading={corporateLoading}
                        roleName={roleTab}
                      />
                    </div>
                  </Tab>

                  <Tab eventKey='completedIndividual' title='Completed Individual/joint/minor '>
                    <div className='card-body bg-white'>
                      <IndividualJointMinorCompleteTable
                        newCIFandAccountListData={newCIFandAccountListData}
                        setParams={setCompleteParams}
                        params={completeParams}
                        loading={individualLoading}
                        roleName={roleTab}
                      />
                    </div>
                  </Tab>
                  <Tab eventKey='completedCorporate' title='Completed Corporate '>
                    <div className='card-body bg-white'>
                      <CorporateBankCompleteTable
                        newCorporateCIFandAccountListData={newCorporateCIFandAccountListData}
                        setParams={setCompleteParams}
                        params={completeParams}
                        loading={corporateLoading}
                        roleName={roleTab}
                      />
                    </div>
                  </Tab>

                  {/* Render RPA tabs only for CPU Maker and CPU Checker roles */}

                  <Tab eventKey='rpaIndividual' title='Rpa Individual/joint/minor'>
                    {(roleTab === 'cpu_maker' ) && (
                      <div className='card-body bg-white'>
                        <RpaIndividualJointMinorTable
                          newCIFandAccountListData={newCIFandAccountListData}
                          setParams={setRpaParams}
                          params={rpaParams}
                          loading={individualLoading}
                          roleName={roleTab}
                        />
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey='rpaCorporate' title='Rpa Corporate'>
                    {(roleTab === 'cpu_maker' ) && (
                      <div className='card-body bg-white'>
                        <RpaCorporateBankTable
                          newCorporateCIFandAccountListData={newCorporateCIFandAccountListData}
                          setParams={setRpaParams}
                          params={rpaParams}
                          loading={corporateLoading}
                          roleName={roleTab}
                        />
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default NewCIFAccount
