import React, {useEffect, useState} from 'react'
import {FieldArray} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {Button, Spinner} from 'react-bootstrap-v5'

const PartnersDetailsFormJoint = ({
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
  customerEnquiry,
  enquiryData,
  mutateCheckAll,
  handlePdf,
  update,
  checkAllData,
  setcheckAllData,
  setScreeningPerformed,
  pdfLoading,
}: any) => {
  const [currentIndex, setCurrentIndex] = useState<any>()
  const {
    allMasterDataOptions,
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  useEffect(() => {
    if (!values?.account_holders || values?.account_holders?.length === 0) {
      setFieldValue('account_holders', [
        {
          first_name: '',
          middle_name: '',
          last_name: '',
          cif_number: '',
          id_number: '',
          id_type_id: '',
        },
      ])
    }
  }, [setFieldValue, values?.account_holders])

  const handleReset = (setFieldValue: (field: string, value: any) => void, index: number) => {
    setFieldValue(`account_holders.${index}.first_name`, '')
    setFieldValue(`account_holders.${index}.middle_name`, '')
    setFieldValue(`account_holders.${index}.last_name`, '')
    setFieldValue(`account_holders.${index}.cif_number`, '')
    setFieldValue(`account_holders.${index}.id_number`, '')
    setFieldValue(`account_holders.${index}.id_type_id`, '')
    setcheckAllData(undefined) // Reset the checkAllData state
  }

  useEffect(() => {
    if (enquiryData) {
      const data = enquiryData
      const checkAllPayload: any = {
        customer_name: data?.data?.generalDetails[0]?.CUST_NAME,
        date_of_birth_bs: data?.data?.generalDetails[0]?.DATE_OF_BIRTH,
        unique_id_no: data?.data?.generalDetails[0]?.CUST_ID,
        citizenship: data?.data?.generalDetails[0]?.IDNO,
        gender: data?.data?.generalDetails[0]?.CUST_SEX,
        model_name: 'NewCIFNewAccount',
      }
      if (update.current === 'joint') {
        mutateCheckAll(checkAllPayload, {
          onSuccess: () => ((update.current = 'default'), setLoadingIndex(null)),
          onError: () => ((update.current = 'default'), setLoadingIndex(null)),
        })
      }

      const fullName = data?.data?.generalDetails[0]?.CUST_NAME || ''
      const nameParts = fullName.split(' ')

      const first_name = nameParts[0] || ''
      const middle_name = nameParts?.length > 2 ? nameParts.slice(1, -1).join(' ') : ''
      const last_name = nameParts[nameParts?.length - 1] || ''

      setFieldValue(`account_holders.${currentIndex}.first_name`, first_name || '')
      setFieldValue(`account_holders.${currentIndex}.middle_name`, middle_name || '')
      setFieldValue(`account_holders.${currentIndex}.last_name`, last_name || '')

      const enteredCifNumber = values.account_holders[currentIndex]?.cif_number
      setFieldValue(`account_holders.${currentIndex}.cif_number`, enteredCifNumber)

      const idType = data?.data?.generalDetails[0]?.IDTYPE
      const matchedOption = allMasterDataOptions?.id_types?.find(
        (option: any) => option.code === idType
      )
      if (matchedOption) {
        setFieldValue(`account_holders.${currentIndex}.id_type_id`, matchedOption.id)
      }

      setFieldValue(
        `account_holders.${currentIndex}.id_number`,
        data?.data?.generalDetails[0]?.IDNO || ''
      )
    }
  }, [enquiryData, currentIndex, setFieldValue])

  const handleEnquiry = (index: number) => {
    setCurrentIndex(index)
    const cifNumber = values.account_holders[index]?.cif_number
    if (cifNumber) {
      customerEnquiry(
        {cifNumber, index},
        {
          onSuccess: (data: any) => {},
          onError: (error: any) => {
            setLoadingIndex(null)
            console.error('Error:', error)
          },
        }
      )
      update.current = 'joint'
      setScreeningPerformed(true)
    } else {
      // Show validation error if CIF number is missing
      setFieldValue(`account_holders.${index}.cif_number`, '')
      errors.account_holders = errors.account_holders || []
      errors.account_holders[index] = {
        ...errors.account_holders[index],
        cif_number: 'CIF Number is required',
      }
    }
  }

  const [loadingIndex, setLoadingIndex] = useState<number | null>(null)

  const handleButtonClick = async (index: number) => {
    if (values.account_holders[index]?.cif_number) {
      setLoadingIndex(index) // Set the loading index to the clicked button's index
      try {
        await handleEnquiry(index) // Trigger the enquiry action
      } catch (error) {
        setLoadingIndex(null)
      }
    }
  }

  return (
    <>
      <div>
        <div className='border-top pt-9 pb-9'>
          <FieldArray name='account_holders'>
            {({remove, push}) => (
              <div>
                {values?.account_holders?.length > 0 ? (
                  values?.account_holders?.map((partners: any, index: any) => (
                    <React.Fragment key={index}>
                      <div className='col-md-4'>
                        <FormTextBox
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          type='text'
                          placeholder='CIF Number'
                          name={`account_holders.${index}.cif_number`}
                          label='Partner CIF Number'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required
                          uppercase={true}
                          maxLength={9}
                        />
                        {checkAllData?.length > 0 &&
                        checkAllData?.some(
                          (item: any) =>
                            item.cif_number === values?.account_holders[index].cif_number
                        ) ? (
                          <Button
                            className='mb-5 ms-3'
                            onClick={() => {
                              handlePdf(index)
                            }}
                          >
                            View PDF
                          </Button>
                        ) : loadingIndex === index ? (
                          <Spinner animation='border' role='status' size='sm'>
                            <span className='sr-only'>Loading...</span>
                          </Spinner>
                        ) : (
                          <Button
                            name={`enquiry-${index}`}
                            key={index}
                            className='mb-5'
                            onClick={() => {
                              handleButtonClick(index)
                            }}
                            disabled={!values.account_holders[index]?.cif_number}
                          >
                            Enquiry
                          </Button>
                        )}
                        <div>
                          <Button
                            type='button'
                            onClick={() => handleReset(setFieldValue, index)}
                            className='btn btn-primary'
                            disabled={loadingIndex === index}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-4'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Partner First Name'
                            name={`account_holders.${index}.first_name`}
                            label='First Name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            uppercase={true}
                            maxLength={50}
                          />
                        </div>
                        <div className='col-md-4'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Partners Middle Name'
                            name={`account_holders.${index}.middle_name`}
                            label='Middle Name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            uppercase={true}
                            maxLength={50}
                          />
                        </div>
                        <div className='col-md-4'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Partner Last Name'
                            name={`account_holders.${index}.last_name`}
                            label='Last Name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            maxLength={50}
                            uppercase={true}
                          />
                        </div>
                        <div className='col-md-4'>
                          <FormSelect
                            labelClassName='col-lg-12 '
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Partner Identification'
                            name={`account_holders.${index}.id_type_id`}
                            label='Partner Identification'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            arrValue={values?.account_holders[index]?.id_type_id}
                            options={allMasterDataOptions?.id_types?.map((identityType: any) => ({
                              value: identityType.id || identityType.value,
                              label: identityType.title || identityType.label,
                            }))}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <div className='col-md-4'>
                          <FormTextBox
                            labelClassName='col-lg-12 '
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Partners Identification Number'
                            name={`account_holders.${index}.id_number`}
                            label='Identification Number'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            maxLength={10}
                            required
                            uppercase={true}
                          />
                        </div>
                        <div className='col-md-4 d-flex align-items-center'>
                          <button
                            type='button'
                            className='btn btn-danger me-2'
                            onClick={() => remove(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <p>No partners added. Click 'Add More' to start.</p>
                )}
                <button
                  type='button'
                  className='btn btn-primary me-2'
                  onClick={() =>
                    push({
                      first_name: '',
                      middle_name: '',
                      last_name: '',
                      cif_number: '',
                      id_number: '',
                      id_type_id: '',
                    })
                  }
                >
                  Add More
                </button>
              </div>
            )}
          </FieldArray>
        </div>
      </div>
    </>
  )
}

export default PartnersDetailsFormJoint
