import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {
  POST_ADDITIONAL_FORMS,
  POST_ADDITIONAL_FORMS_CIF,
  POST_ADDITIONAL_FORMS_EXISTING_CIF,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

const postAdditionalFormDetails = async (id: string, additionalFormPayload: any) => {
  try {
    const response = await axios.post(
      `${POST_ADDITIONAL_FORMS}/${id}/kyc-additional-form`,
      additionalFormPayload
    )
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message

  }
}

const usePostKYCAdditionalDetails = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: {id: string; additionalFormPayload: any}) =>
      postAdditionalFormDetails(payload.id, payload.additionalFormPayload),
    {
      onSuccess: (response) => {
        if (response?.resCod == 200) {
          queryClient.invalidateQueries('preliminaryAccountLists')
          queryClient.invalidateQueries('getBranchMakerAccountLists')
        }
      },
      onError: (error: any) => {
        if (error.errors) {
          for (const key in error.errors) {
            if (error.errors[key]) {
              error.errors[key].forEach((message: string) => {
                toast.error(message)
              })
            }
          }
        } else if (error.message) {
          toast.error(error.message)
        } else {
          toast.error('An unknown error occurred')
        }
      },
    }
  )
}

export default usePostKYCAdditionalDetails

const postAdditionalFormDetailsCIF = async (id: string, additionalFormPayload: any) => {
  try {
    const response = await axios.post(
      `${POST_ADDITIONAL_FORMS_CIF}/${id}/kyc-additional-form`,
      additionalFormPayload
    )
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message

  }
}

export const usePostKYCAdditionalDetailsCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: {id: string; additionalFormPayload: any}) =>
      postAdditionalFormDetailsCIF(payload.id, payload.additionalFormPayload),
    {
      onSuccess: (response) => {
        if (response?.resCod == 200) {
          queryClient.invalidateQueries('preliminaryAccountLists')
          queryClient.invalidateQueries('getBranchMakerAccountLists')
        }
      },
      onError: (error: any) => {
        if (error.errors) {
          for (const key in error.errors) {
            if (error.errors[key]) {
              error.errors[key].forEach((message: string) => {
                toast.error(message)
              })
            }
          }
        } else if (error.message) {
          toast.error(error.message)
        } else {
          toast.error('An unknown error occurred')
        }
      },
    }
  )
}

const postAdditionalFormDetailsexistingCif = async (id: string, additionalFormPayload: any) => {
  try {
    const response = await axios.post(
      `${POST_ADDITIONAL_FORMS_EXISTING_CIF}/${id}/kyc-additional-form`,
      additionalFormPayload
    )
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message

  }
}

export const usePostKYCAdditionalDetailsExistingCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: {id: string; additionalFormPayload: any}) =>
      postAdditionalFormDetailsexistingCif(payload.id, payload.additionalFormPayload),
    {
      onSuccess: (response) => {
        if (response?.resCod == 200) {
          queryClient.invalidateQueries('preliminaryAccountLists')
          queryClient.invalidateQueries('getBranchMakerAccountLists')
        }
      },
      onError: (error: any) => {
        if (error.errors) {
          for (const key in error.errors) {
            if (error.errors[key]) {
              error.errors[key].forEach((message: string) => {
                toast.error(message)
              })
            }
          }
        } else if (error.message) {
          toast.error(error.message)
        } else {
          toast.error('An unknown error occurred')
        }
      },
    }
  )
}
