import React, {Suspense, useEffect, useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../cms/partials'
import {RouteList} from './RoutesList'
import {useSelector} from 'react-redux'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {isEmpty} from 'lodash'
import {ProfileHeader} from '../modules/profileComponent/components/ProfileHeader'
import FileManager from '../modules/common/components/fileManager/components'
import {ErrorsPage} from '../modules/errors/ErrorsPage'

export function PrivateRoutes() {
  const module: any = useSelector((state: any) => state.auth.module)
  const userRoles = useSelector((state: any) => state.auth?.user?.data?.roles || []) // Fetch user roles
  const [authorizedRoute, setAuthorizedRoute] = useState<Array<string>>([])
  console.log(userRoles)
  useEffect(() => {
    if (isEmpty(authorizedRoute)) {
      let newAuthorizedRoute = [...authorizedRoute]
      module?.moduleData?.forEach((modules: any) => {
        if (modules.children) {
          modules.children.forEach((route: any) => {
            newAuthorizedRoute.push(route?.alias)
          })
        } else {
          newAuthorizedRoute.push(modules?.alias)
        }
      })
      setAuthorizedRoute(newAuthorizedRoute)
    }
  }, [module])

  console.log(authorizedRoute)

  // Filter routes based on allowed roles
  const filteredRoutes = RouteList.filter((route) => {
    // If no allowedRoles are specified, the route is considered public
    if (!route.allowedRoles || route.allowedRoles.length === 0) return true

    // Check if any allowed role for the route matches any of the current user's roles.
    return route.allowedRoles.some((allowedRole: string) => {
      return userRoles.some((userRole: any) => userRole.name === allowedRole)
    })
  })
  console.log(filteredRoutes)

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/media-manager' component={FileManager} />
        <Route path='/profile' component={ProfileHeader} />

        {/* Map over filtered routes */}
        {filteredRoutes.map((route, i) => (
          <Route path={route.path} component={route.component} key={i} exact={route.exact} />
        ))}

        <Route path='/error' component={ErrorsPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
      </Switch>
    </Suspense>
  )
}
