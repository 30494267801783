import {Row} from 'antd'
import {RcFile} from 'antd/lib/upload'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap-v5'
import {useQueryClient} from 'react-query'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useGetDOcumentMasterData} from 'src/app/queries/getMasterData/documentType/documentTypeMasterData'
import useGetCorporatePreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'
import Spinner from '../../createNewCIFAndAccount/common/spinner/Spinner'
import FileUploadComponent from '../common/form/fileUpload'
import DocumentUploadForm from './documentUploadForm'

interface FileUploadProps {
  documentType: string
  maxSize: number
  onChange: (file: RcFile, documentType: string) => void
}

interface RouteParams {
  id: string
}

const UploadFile: React.FC<FileUploadProps> = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const {id: uniqueId} = useParams<RouteParams>()
  const [resetUploads, setResetUploads] = useState(false)
  const [uploadDocumentsTypeId, setUploadDocumentsTypeId] = useState<string[]>([])
  const [missingDocs, setMissingDocs] = useState<string[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<{id: string}[]>([])
  const [existingFiles, setExistingFiles] = useState<{id: string}[]>([])
  const [existingFilesIds, setExistingFilesIds] = useState<{id: string}[]>([])
  const [hasOptionalDocument, setHasOptionalDocument] = useState(false)
  const [btnFlag, setBtnFlag] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  // Track multiple CIF Form file uploads
  const [cifFormFiles, setCifFormFiles] = useState<Array<{id: string; file: RcFile | null}>>([])
  const [cifFormFilesIds, setCifFormFilesIds] = useState<Array<{id: any; doc_id: any}>>([])
  const [otherFiles, setOtherFiles] = useState<Array<{id: string; file: RcFile | null}>>([])
  const [otherFilesIds, setOtherFilesIds] = useState<any>()

  const [otherDocumentsCount, setOtherDocumentsCount] = useState(1)

  const {
    data: corporateDataById,
    isLoading: documentLoading,
    refetch,
  } = useGetCorporatePreliminariesDetailsById(uniqueId)
  const defaultDocuments: string[] = [
    'AOF',
    'Firm / Company Registration',
    'PP Size Photo / Stamp',
    'Tax Registration Certificate (PAN / VAT)',
    'Signature',
  ]

  const unRequiredDocuments: string[] = [
    'POA Signature',
    'POA Documents',
    'Map Documents',
    // 'Other Documents',
    'Financial / Tax Clearance',
    'MOA',
    'AOA',
    'AOF',
    'Tax Registration Certificate (PAN / VAT)',
  ]

  const constitutionDocuments: Record<string, string[]> = {
    INGO: [...defaultDocuments, ...unRequiredDocuments],
    'SOLE PROPRIETORSHIP': [...defaultDocuments, ...unRequiredDocuments],
    'Other (specify)': [...defaultDocuments, ...unRequiredDocuments],
    'PARTNERSHIP COMPANY': [...defaultDocuments, ...unRequiredDocuments],
    'NON-GOVERNMENT ORGANIZATION': [...defaultDocuments, ...unRequiredDocuments],
    'LIMITED COMPANY': [...defaultDocuments, ...unRequiredDocuments],
    'JOINT VENTURE': [...defaultDocuments, ...unRequiredDocuments],
    INDIVIDUAL: [...defaultDocuments, ...unRequiredDocuments],
    'Diplomatic Mission/Embassy': [
      ...defaultDocuments,
      ...unRequiredDocuments,
      'Letter from Embassy',
    ],
    'FOREIGN ENTERPRISE': [
      ...defaultDocuments,
      ...unRequiredDocuments,
      'NRB/ Government Office Permission ',
    ],
    'GOVERNMENTAL ENTERPRISE': [...defaultDocuments, ...unRequiredDocuments],
    'PRIVATE LIMITED COMPANY': [...defaultDocuments, ...unRequiredDocuments],
    'School/College': [...defaultDocuments, ...unRequiredDocuments],
    'CO-OPERATIVE': [...defaultDocuments, ...unRequiredDocuments],
    'Club, NGO & Association': [...defaultDocuments, ...unRequiredDocuments],
  }
  useEffect(() => {
    if (!documentLoading && corporateDataById?.data?.account_documents?.documents) {
      const existingFilesData = corporateDataById.data.account_documents.documents.map(
        (doc: any) => ({id: doc.documentType.id})
      )
      setExistingFiles(existingFilesData)
      const existingFilesids = corporateDataById.data.account_documents.documents.map(
        (doc: any) => ({id: doc.id, doc_id: doc.documentType.id})
      )
      setExistingFilesIds(existingFilesids)
      const existingOptionalDocIds = corporateDataById.data.account_documents.documents
        .filter((doc: any) => unRequiredDocuments.includes(doc.documentType.cbs_code))
        .map((doc: any) => doc.documentType.id)
      if (existingOptionalDocIds.length > 0) {
        setHasOptionalDocument(true)
      }
    }
    if (corporateDataById?.data?.additional_documents?.documents) {
      const otherDocFiles = corporateDataById.data.additional_documents.documents
        ?.filter((item: any) => item?.documentType?.cbs_code === 'other_documents')
        .map((doc: any) => ({id: doc.documentType.id}))

      setOtherFiles(otherDocFiles.length > 0 ? otherDocFiles : [{id: '', file: null}])
    } else {
      setOtherFiles([{id: '', file: null}])
    }
    if (corporateDataById?.data?.additional_documents?.documents) {
      const otherDocFiles = corporateDataById.data.additional_documents.documents
        ?.filter((item: any) => item?.documentType?.cbs_code === 'other_documents')
        .map((doc: any) => ({id: doc?.id, doc_id: doc.documentType.id}))

      setOtherFilesIds(otherDocFiles.length > 0 ? otherDocFiles : [{id: ''}])
    }
    const cifFormFiles = corporateDataById?.data?.additional_documents?.documents
      ?.filter((item: any) => item?.documentType?.cbs_code === 'cif_form')
      .map((doc: any) => ({id: doc.documentType.id, file: null}))

    setCifFormFiles(cifFormFiles?.length > 0 ? cifFormFiles : [{id: '', file: null}])
    const cifFormFilesIds = corporateDataById?.data?.additional_documents?.documents
      ?.filter((item: any) => item?.documentType?.cbs_code === 'cif_form')
      .map((doc: any) => ({id: doc?.id, doc_id: doc.documentType.id}))

    setCifFormFilesIds(cifFormFilesIds?.length > 0 ? cifFormFilesIds : [{id: '', file: null}])
  }, [corporateDataById])

  const handleCifFormFileUpload = (index: number, file: RcFile, documentTypeId: string) => {
    setCifFormFiles((prev) => {
      const newCifFiles = [...prev]
      newCifFiles[index] = {id: documentTypeId, file}
      return newCifFiles
    })
  }

  const handleOtherFormFileUpload = (index: number, file: RcFile, documentTypeId: string) => {
    setOtherFiles((prev) => {
      const newOtherFiles = [...prev]
      newOtherFiles[index] = {id: documentTypeId, file}
      return newOtherFiles
    })
  }
  const handleFileUpload = (file: RcFile, documentTypeId: string) => {
    const documentType = documentTypeList?.data?.document_types.find(
      (doc: any) => doc.id === documentTypeId
    )

    if (documentType?.cbs_code === 'signature') {
      const fileExtension = file.name.split('.').pop()?.toLowerCase()
      if (fileExtension !== 'tif') {
        toast.error('Only .tif files are allowed for signature documents.')
        return
      }
    }
    setUploadedFiles((prevState) => {
      const existingFile = prevState.findIndex((file) => file.id === documentTypeId)
      if (existingFile !== -1) {
        const newState = [...prevState]
        newState[existingFile] = {id: documentTypeId}
        return newState
      } else {
        return [...prevState, {id: documentTypeId}]
      }
    })
  }

  const filteredDocumentTypes = corporateDataById?.data?.account_type_id?.documents.filter(
    (documentType: any) => {
      const constitutionDocs =
        constitutionDocuments[corporateDataById?.data?.constitution_id?.title]
      return constitutionDocs?.includes(documentType.title)
    }
  )
  const validateRequiredDocuments = () => {
    const defaultDocIds =
      corporateDataById?.data?.account_type_id?.documents
        .filter((doc: any) => defaultDocuments.includes(doc?.title))
        .map((doc: any) => doc.id) || []

    const uploadedDocIds = uploadedFiles.map((file) => file?.id)
    const existingDocIds = existingFiles.map((file) => file?.id)

    // Find missing document IDs
    const missingDocIds = defaultDocIds.filter(
      (docId: any) => !uploadedDocIds.includes(docId) && !existingDocIds.includes(docId)
    )

    // Update the missingDocs state with an array of missing IDs
    setMissingDocs(missingDocIds)

    // Return true if there are no missing documents
    return missingDocIds.length === 0
  }

  useEffect(() => {
    if (btnFlag === 'save_and_continue') {
      const isValid = validateRequiredDocuments()
      if (isValid) {
        setMissingDocs([])
      }
    }
  }, [uploadedFiles, existingFiles, otherFiles])

  const handleSubmit = async (isSubmit: boolean) => {
    !isSubmit && setBtnFlag('save')
    isSubmit && setBtnFlag('save_and_continue')

    const isAnyDocumentUploaded =
      uploadedFiles.length > 0 ||
      existingFiles.length > 0 ||
      cifFormFiles.some((file) => file.file !== null) ||
      otherFiles.some((file) => file.file !== null)

    if (!isSubmit && !isAnyDocumentUploaded) {
      toast.error('Please upload at least one document to save as draft.')
      return
    }

    const hasCifFormUploaded = cifFormFilesIds.some((file) => file.id !== '')
    if (!hasCifFormUploaded) {
      toast.error('Please upload at least one CIF Form as it is mandatory.')
      return
    }

    // Validate all required documents, including CIF Form
    const hasRequiredDocs = validateRequiredDocuments()

    if (!hasRequiredDocs && isSubmit) {
      toast.error('Please upload all required documents.')
      return
    }

    const documentIdsToSubmit = [
      ...new Set([
        ...uploadedFiles?.map((file) => file.id),
        ...existingFiles?.map((file) => file.id),
        ...cifFormFiles?.map((file) => file.id),
        ...otherFiles?.map((file) => file.id),
      ]),
    ]

    const payload = {
      action: isSubmit ? 'save_and_continue' : 'save',
      documentIds: documentIdsToSubmit,
    }

    try {
      setLoading(true)
      const response = await axios.post(
        `${BASE_API_URL}/corporate-new-cif-new-account/${uniqueId}/branch-maker-document-form`,
        payload
      )

      if (response) {
        refetch()
        const updatedFiles = corporateDataById?.data?.account_documents?.documents.map(
          (doc: any) => ({
            id: doc?.documentType?.id,
            fileName: doc?.file_name,
            fileUrl: doc?.file_url,
          })
        )
        setExistingFiles(updatedFiles)
        queryClient.invalidateQueries('corporatePreliminariesDetailsByIdCIF')
        setLoading(false)
        history.go(-1)
        toast.success(response?.data?.resDesc)
      }
    } catch (error: any) {
      setLoading(false)
      setBtnFlag('')
      toast.error(error.message)
    }
  }

  const {data: documentTypeList, isLoading} = useGetDOcumentMasterData()
  if (isLoading) {
    return (
      <div>
        Please wait... <Spinner />
      </div>
    )
  }

  const getExistingFile = (documentTypeId: string, index?: number) => {
    const additionalDoc = index
      ? corporateDataById?.data?.additional_documents?.documents?.filter(
          (doc: any) => doc.documentType.id === documentTypeId
        )[index]
      : corporateDataById?.data?.additional_documents?.documents?.find(
          (doc: any) => doc.documentType.id === documentTypeId
        )

    if (additionalDoc) {
      return [
        {
          name: additionalDoc?.file_name,
          url: additionalDoc?.file_url,
          id: additionalDoc?.id,
          docId: additionalDoc.documentType.id,
        },
      ]
    }
    const document = corporateDataById?.data?.account_documents?.documents.find(
      (doc: any) => doc.documentType.id === documentTypeId
    )

    return document
      ? [
          {
            name: document?.file_name,
            status: 'done',
            url: document?.file_url,
            id: document?.id,
            docId: document?.documentType?.id,
          },
        ]
      : null
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body border-top pt-9 pb-9'>
        <DocumentUploadForm />
        <div className='heading'>
          <h4>Document Requirements</h4>
          <ul>
            <li>
              AOF (10 MB)<span style={{color: 'red', fontSize: '20px'}}> *</span>
            </li>
            <li>
              CIF Form (10 MB)<span style={{color: 'red', fontSize: '20px'}}> *</span>
            </li>
            <li>
              Firm / Company Registration (5 MB){' '}
              <span style={{color: 'red', fontSize: '20px'}}> *</span>
            </li>
            <li>
              Tax Registration Certificate (PAN / VAT) (5 MB){' '}
              <span style={{color: 'red', fontSize: '20px'}}> *</span>
            </li>
            <li>Financial/Tax Clearance (5 MB)</li>
            <li>MOA (5 MB)</li>
            <li> AOA (5 MB)</li>
            <li>
              PP Size Photo (1 MB) <span style={{color: 'red', fontSize: '20px'}}> *</span>
            </li>
            <li>POA Signature (5 MB)</li>
            <li>POA Documents (5 MB)</li>
            <li>Map Documents (5 MB)</li>
            <li>Other Documents (5 MB)</li>
          </ul>
        </div>
        <div>
          <Row gutter={16}>
            {filteredDocumentTypes?.map((documentType: any) => (
              // <div key={documentType.id}>
              <FileUploadComponent
                documentType={documentType.title}
                maxSize={Number(documentType.max_size)}
                onChange={(file: RcFile) => handleFileUpload(file, documentType.id)}
                documentTypeId={documentType.id}
                uniqueID={uniqueId}
                resetUploads={resetUploads}
                setResetUploads={setResetUploads}
                setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                isMissing={
                  missingDocs?.includes(documentType.id) &&
                  defaultDocuments.includes(documentType.title)
                }
                initialFile={getExistingFile(documentType.id)}
                existingFilesid={existingFilesIds}
                validateRequiredDocuments={validateRequiredDocuments}
                setExistingFiles={setExistingFiles}
                setUploadedFiles={setUploadedFiles}
                otherFilesId={otherFilesIds}
                setCifFilesIds={setCifFormFilesIds}
              />
              // </div>
            ))}
          </Row>

          {cifFormFiles.map((_, index) => (
            <div key={`cif_form_${index}`} style={{marginBottom: '20px', width: '30%'}}>
              <FileUploadComponent
                documentType={'CIF Form'}
                maxSize={2000}
                onChange={(file: RcFile) =>
                  handleCifFormFileUpload(
                    index,
                    file,
                    documentTypeList?.data?.document_types.find(
                      (item: any) => item?.cbs_code === 'cif_form'
                    )?.id
                  )
                }
                documentTypeId={
                  documentTypeList?.data?.document_types?.find(
                    (item: any) => item?.cbs_code === 'cif_form'
                  )?.id
                }
                uniqueID={uniqueId}
                resetUploads={resetUploads}
                setResetUploads={setResetUploads}
                setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                isMissing={missingDocs.includes(
                  documentTypeList?.data?.document_types?.find(
                    (item: any) => item?.cbs_code === 'cif_form'
                  )?.id
                )}
                initialFile={getExistingFile(
                  documentTypeList?.data?.document_types?.find(
                    (item: any) => item?.cbs_code === 'cif_form'
                  )?.id,
                  index
                )}
                cifFilesIds={cifFormFilesIds}
                setCifFilesIds={setCifFormFilesIds}
                validateRequiredDocuments={validateRequiredDocuments}
                setExistingFiles={setExistingFiles}
                setUploadedFiles={setUploadedFiles}
                setCifFormFiles={setCifFormFiles}
              />
            </div>
          ))}

          {/* Button to add more CIF Forms */}
          <div className='col-12'>
            <Button
              onClick={() => setCifFormFiles((prev) => [...prev, {id: '', file: null}])}
              variant='outline-primary'
            >
              Add More CIF Form
            </Button>
          </div>

          {/* Render multiple Other Documents fields */}
          {otherFiles.map((_: any, index: number) => (
            <div key={`other_documents_${index}`} style={{marginBottom: '20px', width: '30%'}}>
              <FileUploadComponent
                documentType={'Other Documents'}
                maxSize={2000}
                onChange={(file: RcFile) =>
                  handleOtherFormFileUpload(
                    index,
                    file,
                    documentTypeList?.data?.document_types?.find(
                      (item: any) => item?.cbs_code === 'other_documents'
                    )?.id
                  )
                }
                documentTypeId={
                  documentTypeList?.data?.document_types?.find(
                    (item: any) => item?.cbs_code === 'other_documents'
                  )?.id
                }
                uniqueID={uniqueId}
                resetUploads={resetUploads}
                setResetUploads={setResetUploads}
                setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                isMissing={missingDocs?.includes(
                  documentTypeList?.data?.document_types?.find(
                    (item: any) => item?.cbs_code === 'other_documents'
                  )?.id
                )}
                initialFile={getExistingFile(
                  documentTypeList?.data?.document_types?.find(
                    (item: any) => item?.cbs_code === 'other_documents'
                  )?.id,
                  index
                )}
                otherFilesId={otherFilesIds}
                validateRequiredDocuments={validateRequiredDocuments}
                setExistingFiles={setExistingFiles}
                setUploadedFiles={setUploadedFiles}
                cifFilesIds={cifFormFilesIds}
                setCifFilesIds={setCifFormFilesIds}
              />
            </div>
          ))}

          {/* Button to add more Other Documents */}
          <div className='col-12'>
            <Button
              onClick={() => setOtherFiles((prev) => [...prev, {id: '', file: null}])}
              // onClick={() => setOtherDocumentsCount(otherDocumentsCount + 1)}
              variant='outline-primary'
            >
              Add More Other Documents
            </Button>
          </div>
        </div>
        <Button
          type='primary'
          onClick={() => handleSubmit(false)}
          style={{margin: '20px 10px'}}
          disabled={loading}
        >
          {btnFlag === 'save' && loading ? 'Saving as draft...' : 'Save as draft'}
        </Button>
        <Button
          type='secondary'
          onClick={() => handleSubmit(true)}
          style={{marginTop: '20px 10px'}}
          disabled={loading}
        >
          {btnFlag === 'save_and_continue' && loading ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </div>
  )
}

export default UploadFile
