import React, {useEffect, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {RcFile} from 'antd/lib/upload'
import {useGetDOcumentMasterData} from 'src/app/queries/getMasterData/documentType/documentTypeMasterData'
import {useGetPreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import FileUploadComponent from '../../../common/form/fileUpload'
import {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS} from '../../../constants/Contants'
import {Button, Spinner} from 'react-bootstrap-v5'
import axios from 'axios'
import {toast} from 'react-toastify'
import {QueryClient} from 'react-query'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'

const DocumentUploadJoint: React.FC = () => {
  interface RouteParams {
    id: string
  }

  const history = useHistory()
  const {id: uniqueId} = useParams<RouteParams>()
  const [resetUploads, setResetUploads] = useState(false)
  const [uploadDocumentsTypeId, setUploadDocumentsTypeId] = useState<string[]>([])
  const [missingDocs, setMissingDocs] = useState<string[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<{id: string}[]>([])
  const [existingFiles, setExistingFiles] = useState<{id: string}[]>([])
  const [existingFilesIds, setExistingFilesIds] = useState<{id: string}[]>([])
  const [hasOptionalDocument, setHasOptionalDocument] = useState(false)
  const [btnFlag, setBtnFlag] = useState<string>('')
  const [loading, setLoading] = useState(false)

  // Track multiple CIF Form file uploads
  const [cifFormFiles, setCifFormFiles] = useState<Array<{id: string; file: RcFile | null}>>([])
  const [cifFormFilesIds, setCifFormFilesIds] = useState<Array<{id: any; doc_id: any}>>([])
  const [otherFiles, setOtherFiles] = useState<Array<{id: string; file: RcFile | null}>>([])
  const [otherFilesIds, setOtherFilesIds] = useState<any>()

  const [otherDocumentsCount, setOtherDocumentsCount] = useState(1)

  const {
    data: preliminariesDetails,
    isLoading: loadingPreliminaryData,
    invalidateQuery,
    refetch,
  } = useGetPreliminariesDetailsByIdCIF(uniqueId)
  const {data: documentTypeList, isLoading: loadingDocumentTypes} = useGetDOcumentMasterData()

  const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

  useEffect(() => {
    if (preliminariesDetails?.data?.account_documents?.documents) {
      const existingFilesData = preliminariesDetails.data.account_documents.documents.map(
        (doc: any) => ({id: doc.documentType.id})
      )
      setExistingFiles(existingFilesData)
      const existingFilesids = preliminariesDetails.data.account_documents.documents.map(
        (doc: any) => ({id: doc.id, doc_id: doc.documentType.id})
      )
      setExistingFilesIds(existingFilesids)
      const existingOptionalDocIds = preliminariesDetails.data.account_documents.documents
        .filter((doc: any) => optionalDocuments.includes(doc.documentType.cbs_code))
        .map((doc: any) => doc.documentType.id)
      if (existingOptionalDocIds.length > 0) {
        setHasOptionalDocument(true)
      }
    }

    if (preliminariesDetails?.data?.additional_documents?.documents) {
      const otherDocFiles = preliminariesDetails.data.additional_documents.documents
        ?.filter((item: any) => item?.documentType?.cbs_code === 'other_documents')
        .map((doc: any) => ({id: doc.documentType.id, file: null}))

      setOtherFiles(otherDocFiles.length > 0 ? otherDocFiles : [{id: '', file: null}])
    } else {
      setOtherFiles([{id: '', file: null}])
    }
    if (preliminariesDetails?.data?.additional_documents?.documents) {
      const otherDocFiles = preliminariesDetails.data.additional_documents.documents
        ?.filter((item: any) => item?.documentType?.cbs_code === 'other_documents')
        .map((doc: any) => ({id: doc?.id, doc_id: doc.documentType.id}))

      setOtherFilesIds(otherDocFiles.length > 0 ? otherDocFiles : [{id: ''}])
    }

    // Check for CIF Form documents
    const cifFormFiles = preliminariesDetails?.data?.additional_documents?.documents
      ?.filter((item: any) => item?.documentType?.cbs_code === 'cif_form')
      .map((doc: any) => ({id: doc.documentType.id, file: null}))

    setCifFormFiles(cifFormFiles?.length > 0 ? cifFormFiles : [{id: '', file: null}])
    const cifFormFilesIds = preliminariesDetails?.data?.additional_documents?.documents
      ?.filter((item: any) => item?.documentType?.cbs_code === 'cif_form')
      .map((doc: any) => ({id: doc?.id, doc_id: doc.documentType.id}))

    setCifFormFilesIds(cifFormFilesIds?.length > 0 ? cifFormFilesIds : [{id: '', file: null}])
  }, [preliminariesDetails])

  const optionalDocuments = [
    'Citizenship',
    'Passport',
    'Driving License',
    'Voters ID',
    'Identification Certificate',
  ]

  const handleFileUpload = (file: RcFile, documentTypeId: string) => {
    const uploadedDoc = documentTypeList?.data?.document_types.find(
      (doc: any) => doc.id === documentTypeId
    )

    // Check if the uploaded document is one of the optional ones
    if (uploadedDoc && optionalDocuments.includes(uploadedDoc.title)) {
      setHasOptionalDocument(true) // Set to true if any optional document is uploaded
    }

    setUploadedFiles((prevState) => {
      const existingFile = prevState.find((file) => file.id === documentTypeId)
      if (existingFile) {
        return prevState.map((file) => (file.id === documentTypeId ? {id: documentTypeId} : file))
      } else {
        return [...prevState, {id: documentTypeId}]
      }
    })
  }

  const requiredDocuments: string[] = [
    'Identification Certificate',
    'Upload PP Size Photo',
    'CIF Form',
    // 'Signature',
    'Upload AOF',
    'Citizenship',
    // 'Passport',
    // 'Driving License',
    // 'Voters ID',
    'Registration ID',
    // 'CIF Form',
  ]

  const validateRequiredDocuments = () => {
    const requiredDocIds =
      documentTypeList?.data?.document_types
        .filter((doc: any) => {
          // Always include documents that are in the required documents list
          if (requiredDocuments.includes(doc.title)) return true

          // Include optional documents only if no optional document has been uploaded
          if (optionalDocuments.includes(doc.title)) {
            return !hasOptionalDocument
          }

          return false
        })
        .map((doc: any) => doc.id) || []

    const uploadedDocIds = uploadedFiles.map((file) => file.id)
    const existingDocIds = existingFiles.map((file) => file.id)

    const missingRequiredDocs = requiredDocIds.filter(
      (docId: any) => !uploadedDocIds.includes(docId) && !existingDocIds.includes(docId)
    )
    setMissingDocs(missingRequiredDocs)
    return missingRequiredDocs.length === 0
  }

  const handleSubmit = async (isSubmit: boolean) => {
    !isSubmit && setBtnFlag('save')
    isSubmit && setBtnFlag('save_and_continue')
    if (isSubmit && !validateRequiredDocuments()) {
      toast.error('Please upload all required documents.')
      return
    }

    const documentIdsToSubmit = [
      ...new Set([
        ...uploadedFiles.map((file) => file.id),
        ...existingFiles.map((file) => file.id),
      ]),
    ]

    const payload = {
      action: isSubmit ? 'save_and_continue' : 'save',
      documentIds: documentIdsToSubmit,
    }

    try {
      setLoading(true)
      const queryClient = new QueryClient()
      await axios
        .post(`${API_URL}/new-cif/${uniqueId}/branch-maker-document-form`, payload)
        .then((res: any) => {
          if (res) {
            toast.success(res?.data?.resDesc)

            invalidateQuery()
            refetch()
            setLoading(false)
            history.go(-1)
          }
        })
    } catch (error: any) {
      toast.error('Failed to upload documents.')
      console.error('Error uploading documents:', error)
    }
  }

  if (loadingDocumentTypes || loadingPreliminaryData) {
    return (
      <div>
        Loading... <Spinner animation={'border'} />
      </div>
    )
  }

  const getExistingFile = (documentTypeId: string) => {
    const document = preliminariesDetails?.data?.account_documents?.documents.find(
      (doc: any) => doc.documentType.id === documentTypeId
    )
    return document ? {name: document.file_name, url: document.file_url} : null
  }

  const individualDocs = documentTypeList?.data?.document_types.filter(
    (documentType: any) =>
      requiredDocuments.includes(documentType.title) ||
      optionalDocuments.includes(documentType.title)
  )
  const data = preliminariesDetails?.data

  const getStringValue = (value: any) => {
    if (typeof value === 'string') return value
    if (typeof value === 'number') return value.toString()
    if (value && typeof value === 'object' && 'title' in value) return value.title
    return ''
  }
  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <div className='row'>
            <section className=''>
              <h3 className='fw-bolder mb-4 text-capitalize'>Account Details</h3>

              {/* <h2 style={{color: 'brown'}}>Account Details</h2> */}
              <FormDetails
                key='screening_id'
                label='Screening Id'
                value={getStringValue(data?.screening_id)}
              />
              <FormDetails
                key='account_type_id'
                label='Account Type'
                value={getStringValue(data?.account_type_id?.cbs_code)}
              />
              <FormDetails
                key='branch_code'
                label='Branch Code'
                value={getStringValue(data.branch_code)}
              />
              <FormDetails
                key='customer_code'
                label='Customer Code'
                value={getStringValue(data.customer_code)}
              />
            </section>
            <hr />
            <section className=''>
              <h3 className='fw-bolder mb-4 text-capitalize'>Personal Details</h3>
              <div className='row'>
                <FormDetails
                  key='first_name'
                  label='First Name'
                  value={getStringValue(data.first_name)}
                />
                <FormDetails
                  key='middle_name'
                  label='Middle Name'
                  value={getStringValue(data.middle_name)}
                />
                <FormDetails
                  key='last_name'
                  label='Last Name'
                  value={getStringValue(data.last_name)}
                />

                <FormDetails
                  key='id_type_id'
                  label='Identification Type'
                  value={getStringValue(data.id_type_id)}
                />
                <FormDetails
                  key='identification_number'
                  label='Identification Number'
                  value={getStringValue(data.identification_number)}
                />
                {/* <FormDetails
                  key='account_category_id'
                  label='Account Category'
                  value={getStringValue(data?.account_opening?.account_category_id)}
                />
                <FormDetails
                  key='account_Scheme_id'
                  label='Account Scheme'
                  value={getStringValue(data?.account_opening?.account_scheme_id)}
                /> */}
                <FormDetails
                  key='permanent_mobile_number'
                  label='Mobile Number'
                  value={getStringValue(data?.permanent_mobile_number)}
                />
                <FormDetails
                  key=''
                  label='Email Address'
                  value={getStringValue(data?.permanent_email)}
                />
              </div>
            </section>
            {data?.account_type_id?.title === 'Joint' && (
              <div>
                <section className=''>
                  <h3 className='fw-bolder mb-4 text-capitalize'>Partner's Details</h3>
                  <div className='row'>
                    {data?.account_holders?.account_holders.map((holder: any, index: any) => (
                      <div key={index} className='row'>
                        <FormDetails
                          key={`cif_number_${index}`}
                          label='CIF Number'
                          value={holder.cif_number}
                        />
                        <FormDetails
                          key={`first_name_${index}`}
                          label='First Name'
                          value={holder.first_name}
                        />
                        <FormDetails
                          key={`middle_name_${index}`}
                          label='Middle Name'
                          value={holder.middle_name}
                        />
                        <FormDetails
                          key={`last_name_${index}`}
                          label='Last Name'
                          value={holder.last_name}
                        />
                        <FormDetails
                          key={`id_type_id_${index}`}
                          label='Identification Type'
                          value={holder.id_type_id?.title}
                        />
                        <FormDetails
                          key={`id_number_${index}`}
                          label='Identification Number'
                          value={holder.id_number}
                        />
                        <hr />
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div>
            <div className='heading'>
              <h2 style={{color: 'brown'}}>Document Requirements - Joint Account</h2>
              <i>Please upload file of size mentioned here:</i>
              <ul>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleCIFFORM}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleAOF}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleIdentificationCertificate}</li>
                {/* <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleSignature}</li> */}
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titlePP}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleMapDocuments}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleAccountVerify}</li>
              </ul>
            </div>
            {loadingPreliminaryData ? (
              <div>
                <h4>Please Wait...</h4>
                <Spinner animation={'border'} />
              </div>
            ) : (
              <div className='row'>
                {individualDocs?.map((documentType: any) => (
                  <div key={documentType.id} style={{marginBottom: '20px', width: '30%'}}>
                    <FileUploadComponent
                      documentType={documentType.title}
                      maxSize={Number(documentType.max_size)}
                      onChange={(file: RcFile) => handleFileUpload(file, documentType.id)}
                      documentTypeId={documentType.id}
                      uniqueID={uniqueId}
                      resetUploads={resetUploads}
                      setResetUploads={setResetUploads}
                      setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                      isMissing={missingDocs.includes(documentType.id)}
                      initialFile={getExistingFile(documentType.id)}
                      existingFilesid={existingFilesIds}
                      validateRequiredDocuments={validateRequiredDocuments}
                      setExistingFiles={setExistingFiles}
                      setUploadedFiles={setUploadedFiles}
                    />
                  </div>
                ))}
              </div>
            )}
            <Button
              type='primary'
              onClick={() => handleSubmit(false)}
              style={{margin: '20px 10px'}}
            >
              {loading && btnFlag === 'save_and_continue' ? 'Saving as draft...' : 'Save as draft'}
            </Button>
            <Button
              type='secondary'
              onClick={() => handleSubmit(true)}
              style={{marginTop: '20px 10px'}}
            >
              {loading && btnFlag === 'save' ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DocumentUploadJoint
