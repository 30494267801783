import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'

import {
  UPDATE_CORPORATE_NEW_CIF,
  UPDATE_CORPORATE_NEW_CIF_NEW_ACCOUNT,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${UPDATE_CORPORATE_NEW_CIF_NEW_ACCOUNT}`

type UpdatePayloadType = {
  id: string
  data: any
}

const updateCorporateAccountPreliminary = async (payload: UpdatePayloadType) => {
  const {id, ...data} = payload
  try {
    const response = await axios.patch(`${API_URL}/${id}`, data)
    return response.data
  } catch (error: any) {
    throw error?.response?.data || error.message

    // throw new Error(`${error.response?.data?.message || 'error updating account'}`)
  }
}

const useUpdateCorporateAccountPreliminary = () => {
  const queryClient = useQueryClient()
  return useMutation(updateCorporateAccountPreliminary, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success(data?.resDesc ?? 'success!')
      }
      queryClient.invalidateQueries('corporateAccountList')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}

export default useUpdateCorporateAccountPreliminary

const updateCorporateAccountPreliminaryCIF = async (payload: UpdatePayloadType) => {
  const {id, ...data} = payload
  try {
    const response = await axios.patch(`${UPDATE_CORPORATE_NEW_CIF}/${id}`, data)
    return response.data
  } catch (error: any) {
    throw error?.response?.data || error.message

    // throw new Error(`${error.response?.data?.message || 'error updating account'}`)
  }
}

export const useUpdateCorporateAccountPreliminaryCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(updateCorporateAccountPreliminaryCIF, {
    onSuccess: (data) => {
      // if (data?.resCod == 200) {
      //   toast.success(data?.resDesc ?? 'success!')
      // }
      queryClient.invalidateQueries('corporatePeliminariesDetailsByIdCIF')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}
