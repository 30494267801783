import axios from 'axios'
import {useQuery, useMutation} from 'react-query'
import {GET_NORMAL_NEW_CIF_NEW_ACCOUNT} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'
// import { ParamsModel } from 'src/app/modules/common/Model'

const QUERY_KEY = 'branchMakerNewCIFAndAccountList'

export const useGetUserActivity = (params: any) => {
  return useQuery([QUERY_KEY, params], async () => {
    const {data} = await axios.get(`${BASE_API_URL}/activity-logs`, {
      params,
    })
    return data
  })
}


export const getUserActivity = async (params: any) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/activity-logs`, {
      params,
    })
    return response.data
  } catch (error) {
    console.error('Error fetching user activity:', error)
    throw error // Re-throw the error to handle it in the caller
  }
}
