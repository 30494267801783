import React from 'react'
import {
  POST_ACCOUNT_OPENING_FORM,
  POST_NORMAL_NEW_CIF_NEW_ACCOUNT,
} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import axios from 'axios'
import { BASE_API_URL } from 'src/cms/helpers/constants/common'

const API_URL = `${POST_ACCOUNT_OPENING_FORM}`
type PayloadType = {
  id: string
  data: any
}

const postAccountOpening = async (payload: PayloadType) => {
  const {id, ...data} = payload
  try {
    const response = await axios.post(`${API_URL}/${id}/account-opening`, payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || error.message}`)
  }
}
export const usePostAccountOpeningForm = () => {
  const queryClient = useQueryClient()
  return useMutation(postAccountOpening, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success('Forwarded to Cpu Checker')
      }
      queryClient.invalidateQueries('branchMakerNewCIFAndAccountList')
      queryClient.invalidateQueries('ListingNewCIFNewAccount')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}

const postExistingAccountOpening = async (payload: PayloadType) => {
  const {id, ...data} = payload
  try {
    const response = await axios.post(`${BASE_API_URL}/existing-cif/${id}/store-acc-form`, payload)
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message

  }
}
export const usePostExistingAccountOpeningForm = () => {
  const queryClient = useQueryClient()
  return useMutation(postExistingAccountOpening, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success('Forwarded to Cpu Checker')
      }
      queryClient.invalidateQueries('branchMakerNewCIFAndAccountList')
      queryClient.invalidateQueries('ListingNewCIFNewAccount')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}
