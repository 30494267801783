import {useContext, ReactNode, useState, useEffect} from 'react'
import {isEmpty} from 'lodash'
import {useSelector} from 'react-redux'
import {IAuthState} from '../auth'
import {ActiveMenuContex} from 'src/cms/layout/MasterLayout'
interface Props {
  type: string
  children: ReactNode
  moduleName?: string
}

const CheckPermissions = ({type, children, moduleName}: Props) => {
  const auth: any = useSelector((state: any) => state.auth)
  console.log('auth', auth)

  const activeMenu: any = useContext(ActiveMenuContex)

  const staticPermission = [
    {
      name: 'View New Cif Account',
      id: '111',
      description: 'View New Cif Account',
    },
  ]

  const [allPermission, setAllPermissions] = useState(auth?.user?.data?.roles[0]?.permissions)

  useEffect(() => {
    if (auth?.user?.data?.roles[0]){
      setAllPermissions([...staticPermission, ...auth?.user?.data?.roles[0]?.permissions])
    }
  }, [auth?.user?.data?.roles[0]?.permissions])

  const hasPermission = moduleName
    ? allPermission?.find((permission: any) => permission?.name === `${type} ${moduleName}`)
    : auth?.user?.data?.roles[0]?.permissions.find(
        (permission: any) => permission?.name === `${type} ${activeMenu?.currentActiveMenu}`
      )
  return <>{!isEmpty(hasPermission) && children}</>
}

export default CheckPermissions
