import SearchIcon from '@rsuite/icons/Search'
import {Flex, Upload} from 'antd'
import {useState} from 'react'
import {FaFileDownload, FaFileExport, FaFileImport, FaPlus, FaRedo} from 'react-icons/fa'
import {FiEdit2, FiTrash} from 'react-icons/fi'
import {IconButton, Input, InputGroup, Pagination, Table, Whisper} from 'rsuite'
import {SortType} from 'rsuite-table/lib/@types/common'
import Tag from 'rsuite/Tag'
import Tooltip from 'rsuite/Tooltip'
import useDeleteTaxCategory from 'src/app/queries/getMasterData/taxCategory/delete/useDeleteTaxCategory'
import useGetTaxCategoryById from 'src/app/queries/getMasterData/taxCategory/get/useGetTaxCategoryById'
import {useGetTaxCategoryList} from 'src/app/queries/getMasterData/taxCategory/get/useGetTaxCategoryList'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import CheckPermissions from '../../common/checkPermission'
import {TableParams} from '../../common/Type/initialData'
import DeleteModalSchema from '../common/modal/DeleteModal'
import TaxCategoryModal from './modal/TaxCategoryModal'
import useGetDownloadSample from 'src/hooks/DownloadSample/MasterData/useDownloadSampleFile'
import usePostFileExport from 'src/hooks/DownloadSample/MasterData/useExportFile'
import usePostImport from 'src/hooks/DownloadSample/MasterData/useFileImport'
import {useQueryClient} from 'react-query'
import {UploadProps} from 'antd/lib'
import {toast} from 'react-toastify'

const Cell = Table.Cell

const TaxCategoryLists = () => {
  const [open, setOpen] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [modalStatus, setModalStautus] = useState<string>('Add')
  const [rowData, setRowData] = useState<any>(null)
  const [params, setParams] = useState(TableParams)

  const [initialData, setInitialData] = useState<any>()
  const {data: category, isLoading, refetch} = useGetTaxCategoryList(params)
  const {mutate: mutateDelete, isLoading: isDeleteLoading} = useDeleteTaxCategory(
    setOpenDeleteModal,
    refetch
  )
  const {mutate: mutateGet, isLoading: isLoadingData} = useGetTaxCategoryById(setInitialData)

  const {mutate: mutatePost, isLoading: isLoadingDataSample} = useGetDownloadSample()
  const {mutate: mutatePostExport, isLoading: isLoadingDataExport} = usePostFileExport()

  const handleClickDownloadSample = () => {
    mutatePost()
  }

  const handleClickExport = () => {
    const payload = {
      search: params?.search,
      table_name: 'md_tax_categories',
      fileFormat: 'csv',
    }
    mutatePostExport(payload)
  }
  const table_name = 'md_tax_categories'
  const caches = useQueryClient()
  const {mutate: mutateImport, isLoading: isLoadingImport} = usePostImport(table_name, refetch)

  const props: UploadProps = {
    showUploadList: false,

    beforeUpload: (file) => {
      const isExcel =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'text/csv'
      if (!isExcel) {
        toast.error('Only Excel file is allowed!')
      } else {
        mutateImport(file)
      }

      return false
    },
  }

  const handleRetry = () => {
    refetch()
  }
  const handleEditAction = (rowData: any) => () => {
    setOpen(true)
    setModalStautus('Edit')
    mutateGet(rowData?.id)
  }

  const handleDeleteAction = (rowData: any) => () => {
    setRowData(rowData)
    setOpenDeleteModal(true)
  }

  const handleClickDelete = () => {
    mutateDelete(rowData.id)
  }

  const handleCloseDelete = () => {
    setOpenDeleteModal(false)
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group' data-cy='link-user-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit </Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={handleEditAction(rowData)}
              data-cy='edit-button'
              icon={<FiEdit2 />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={handleDeleteAction(rowData)}
              data-cy='delete-button'
              icon={<FiTrash />}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 50,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Title',
      dataKey: 'title',
      flexGrow: 1,
      cell: <Cell dataKey='title' />,
      sortable: true,
    },
    {
      label: 'Code',
      dataKey: 'cbs_code',
      flexGrow: 1,
      sortable: true,
      cell: (
        <Cell dataKey='cbs_code'>
          {(rowData) => (
            <Tag color='blue' size='sm'>
              {rowData?.cbs_code}
            </Tag>
          )}
        </Cell>
      ),
    },
    {
      label: 'Account Type',
      dataKey: 'on_acc_type',
      flexGrow: 1,
      sortable: false,
      cell: (
        <Cell>
          {(rowData) => (rowData?.on_acc_type ? <p>{rowData?.on_acc_type}</p> : <p>--</p>)}
        </Cell>
      ),
    },

    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Action',
      width: 120,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]
  const data = category?.data
    ? category?.data?.category?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        title: item?.title,
        cbs_code: item?.cbs_code ?? '--',
        status: item?.status,
        order: item?.order,
        on_acc_type:
          item?.on_acc_type?.length > 0
            ? item?.on_acc_type?.map((subItem: any) => subItem.title).join(', ')
            : '--',
      }))
    : []

  const handleChangeLimit = (dataKey: any) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }
  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType as string})
  }

  const handleAdd = () => {
    setModalStautus('Add')
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className='shadow p-3 bg-white rounded' data-cy='shadow-user'>
      <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
        <Flex justify='space-between'>
          <div className='col-lg-6'>
            <div className='heading__component__title fw-bolder'>Tax Category</div>
          </div>
          <CheckPermissions type='Add'>
            <button
              className='dt-btn dt-btn-primary d-flex justify-content-end'
              data-cy='add-button'
              onClick={handleAdd}
            >
              <FaPlus className='me-2' />
              Add
            </button>
          </CheckPermissions>
        </Flex>
      </div>
      <Flex justify='space-between' align='center' wrap={true}>
        <InputGroup inside className='w-25 mt-5'>
          <Input
            placeholder='Search'
            value={params?.search}
            onChange={(value: string) => setParams({...params, search: value})}
            data-cy='search-field'
          />
          <InputGroup.Addon>
            <SearchIcon />
          </InputGroup.Addon>
        </InputGroup>

        <Flex vertical gap={'0.5rem'} style={{marginBottom: '0.5rem'}}>
          {isLoadingDataSample ? (
            <button
              className='dt-btn dt-btn-outline-primary dt-btn-sm ms-2'
              data-cy='import-show-button'
              disabled={isLoadingDataSample}
            >
              <FaFileDownload className='me-2' />
              Downloading...
            </button>
          ) : (
            <button
              className='dt-btn dt-btn-outline-primary dt-btn-sm ms-2'
              onClick={handleClickDownloadSample}
              data-cy='import-show-button'
            >
              <FaFileDownload className='me-2' />
              Download Template
            </button>
          )}
          <div>
            <button
              className='dt-btn dt-btn-outline-primary dt-btn-sm ms-2'
              data-cy='import-show-button'
              onClick={handleRetry}
            >
              <FaRedo className='me-2' />
            </button>
            {isLoadingDataExport ? (
              <button
                className='dt-btn dt-btn-outline-primary dt-btn-sm ms-2'
                data-cy='import-show-button'
                disabled={isLoadingDataExport}
              >
                <FaFileExport className='me-2' />
                Exporting...
              </button>
            ) : (
              <button
                className='dt-btn dt-btn-outline-primary dt-btn-sm ms-2'
                onClick={handleClickExport}
                data-cy='import-show-button'
              >
                <FaFileExport className='me-2' />
                Export to CSV
              </button>
            )}
            <Upload {...props} accept='.csv, .xlsx' name='file'>
              <button
                className='dt-btn dt-btn-outline-primary dt-btn-sm ms-2'
                data-cy='import-show-button'
                onClick={(e) => e.preventDefault()}
              >
                <FaFileImport className='me-2' />
                Import
              </button>
            </Upload>
          </div>
        </Flex>
      </Flex>

      <div className='datatable' data-cy='datatable-user'>
        <RSuiteTable
          columns={columns}
          data={data}
          showLoading={isLoading}
          handleSort={handleSort}
        />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={category?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
      <TaxCategoryModal
        isOpen={open}
        handleClose={handleClose}
        modalStatus={modalStatus}
        setOpen={setOpen}
        initialData={initialData}
        isLoadingData={isLoadingData}
        refetch={refetch}
      />
      <DeleteModalSchema
        isOpen={openDeleteModal}
        handleClick={handleClickDelete}
        handleClose={handleCloseDelete}
        title='Delete'
        isDeleteLoading={isDeleteLoading}
      />
    </div>
  )
}

export default TaxCategoryLists
