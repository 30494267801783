import {Form, Formik} from 'formik'
import {useRef, useState} from 'react'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import * as placeReducer from 'src/app/modules/common'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import {useGetCorporatePreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import {RootState} from 'src/setup'

import {Button, Spinner, Tab, Tabs} from 'react-bootstrap-v5'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {usePostAdditionalFormsCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/post/useAdditionalForms'
import {useUpdateCorporateKycDetailsCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/update/useUpdateCorporateKYC'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/FormNepaliCalendarDatePicker'
import OtherBanksTable from '../components/formcomponent/dealingWithOtherBank'
import DirectorsTable from '../components/formcomponent/directorsTable'
import FactaDeclarationTable from '../components/formcomponent/facta'
import ShareholdersTable from '../components/formcomponent/shareholdersTable'

import axios from 'axios'
import {toast} from 'react-toastify'
import {UserActivityLogAll} from 'src/app/modules/common/components/AllActivityLog'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import FormDatepicker, {FormDatepickerNew} from 'src/cms/helpers/components/forms/FormDatepicker'
import {BASE_API_URL, BASE_APP_URL} from 'src/cms/helpers/constants/common'
import useGetMunicipilitiesById from 'src/hooks/getMunicipilities/useGetMunicipilitiesData'
import {AccountStatusLog} from 'src/app/modules/common/components/AccountStatusLog'
import {useGetAllZoneData} from 'src/app/queries/getMasterData/zones/useGetAllZone'
import {useGetAllRiskCategoriesList} from 'src/app/queries/getMasterData/riskCategories/get/useGetAllRiskCategoriesList'
import moment from 'moment'
import {CORPORATE_CPU_FORM_VALIDATION} from 'src/app/modules/new-cif-new-account/bankMaker/createNewAccountCorporate/utils/validations/ValidationSchemas'
import {formatDate} from 'src/app/modules/common/SplitFunction'

interface RouteParams {
  id: string
}
const NewCifCorporateCpu = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams<RouteParams>()

  const {data: allMasterData} = useGetAllMasterData()
  const {mutate: updateCorporateKyc, isLoading: isSubmitLoading} = useUpdateCorporateKycDetailsCIF()
  const {mutate: addToAdditionalForm} = usePostAdditionalFormsCIF()
  const {data: corporateDataById, isLoading} = useGetCorporatePreliminariesDetailsByIdCIF(id)

  const [masterData, setMasterData] = useState<any>()
  const [isSave, setIsSave] = useState(false)
  const [activeTab, setActiveTab] = useState<any>('basicInfo') // Added state for managing active tab
  const [isFirstFormSubmitted, setIsFirstFormSubmitted] = useState(false)

  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const userBranchCode = user?.data?.branch?.code
  const [sameAsPermanentAddress, setSameAsPermanentAddress] = useState(false)

  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [kycData, setKycData] = useState({})
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])
  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])
  const [zoneCode, setZoneCode] = useState<string | null>(null)
  const [districtData, setDistrictData] = useState<any>(null)
  const [riskCode, setRiskCode] = useState<string | null>(null)
  const [riskSubCat, setRiskSubCat] = useState<any>(null)
  const [isDisabled, setISDisabled] = useState<boolean>(false)

  const {data: zoneData} = useGetAllZoneData()

  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )

  const [municipalitiesData, setMunicipalitiesData] = useState<any>(null)

  const {mutate: MutateMunicipilities} = useGetMunicipilitiesById(setMunicipalitiesData)
  const {
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading: masterDataLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: false,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })
  const PEPOPTIONS = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'},
  ]

  const onCountryChange = (
    id: string,
    setFieldValue: (field: string, value: any) => void,
    setTouched: any
  ) => {
    const selectedCountry = allMasterDataOptions?.countries?.find(
      (item: any) => item?.id === id
    )?.title

    if (selectedCountry === 'NEPAL') {
      setFieldValue('business_office_outside_country_full_address', '')
      setFieldValue('business_office_outside_country_post_box', '')
      setFieldValue('business_office_mobile_number', '')
      setFieldValue('business_office_email', '')
      setTouched({
        business_office_outside_country_full_address: false,
        business_office_outside_country_post_box: false,
        business_office_mobile_number: false,
        business_office_email: false,
      })
    } else {
      setFieldValue('business_office_province', '')
      setFieldValue('business_office_zone', '')
      setFieldValue('business_office_district', '')
      setFieldValue('business_office_municipality', '')
      setFieldValue('business_office_city', '')
      setFieldValue('business_office_ward_number', '')
      setFieldValue('business_office_street_name', '')
      setFieldValue('business_office_house_number', '')
      setFieldValue('business_office_mobile_number', '')
      setFieldValue('business_office_email', '')
      setTouched({
        business_office_province: false,
        business_office_zone: false,
        business_office_district: false,
        business_office_municipality: false,
        business_office_city: false,
        business_office_ward_number: false,
        business_office_street_name: false,
        business_office_house_number: false,
        business_office_mobile_number: false,
        business_office_email: false,
      })
    }
  }
  useEffect(() => {
    if (zoneCode) {
      const fetchDistrictData = async () => {
        try {
          await axios
            .get(`${BASE_API_URL}/district`, {
              params: {zoneCode},
            })
            .then((res: any) => {
              setDistrictData(res?.data)
            })
          // setDistrictData(data) // Store the district data in state
        } catch (error) {
          console.error('Error fetching district data:', error)
        }
      }

      fetchDistrictData()
    }
  }, [zoneCode])

  const {data: riskData} = useGetAllRiskCategoriesList()
  useEffect(() => {
    if (riskCode) {
      fetchRiskData()
    }
  }, [riskCode])

  const fetchRiskData = async () => {
    try {
      await axios
        .get(`${BASE_API_URL}/risk-sub-categories`, {
          params: {risk_category_id: riskCode},
        })
        .then((res: any) => {
          setRiskSubCat(res?.data)
        })
    } catch (error) {
      console.error('Error fetching district data:', error)
    }
  }

  const getMunicipilities = (id: string, type: 'business' | 'registered', setFieldValue: any) => {
    MutateMunicipilities(id, {
      onSuccess: (response: any) => {
        setFieldValue(`${type}_office_province`, response?.data?.district?.province?.id)
        setFieldValue(`${type}_office_zone`, response?.data?.district?.zone?.id)
        setFieldValue(`${type}_office_district`, response?.data?.district?.id)
      },
      onError: (error) => {
        console.error(`Error fetching ${type} office details:`, error)
      },
    })
  }

  const getZoneCode = (e: any, values: any) => {
    const presentZone = e
    const zoneList = zoneData?.data?.zone
    const matchingZone = zoneList?.find((zone: any) => zone?.id === presentZone)
    if (matchingZone) {
      setZoneCode(matchingZone.code)
    } else {
      console.log('No matching zone found.')
    }
  }
  useEffect(() => {
    let categoryId = allMasterDataOptions?.risk_categories?.find(
      (option: any) => option?.title === 'LOW RISK'
    )?.id
    setRiskCode(categoryId)
    fetchRiskData()
  }, [])

  const getSubRiskCode = (id: string, setFieldValue: any) => {
    const selectedSubCategory = allMasterDataOptions?.risk_sub_categories?.find(
      (option: any) => option?.id === id
    )
    const riskCategoryId = selectedSubCategory?.risk_category?.id || ''
    // const riskCategoryTitle = selectedSubCategory?.risk_category?.title || 'null'
    const riskCategoryTitle = selectedSubCategory?.title || 'null'

    setFieldValue('aml_risk_rating', riskCategoryId)
    if (riskCategoryTitle == 'Others') {
      setISDisabled(false)
    } else if (riskCategoryTitle == 'null') {
      setISDisabled(false)
    } else {
      setISDisabled(true)
    }
  }

  useEffect(() => {
    if (allMasterData) {
      setMasterData(allMasterData)
    }
  }, [allMasterData])

  const {district, province, zone, municipality} = useSelector((state: RootState) => state.place)
  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getMunicipality())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getZone())
  }, [])

  const transformValues = (values: any) => {
    const share_holders = [
      ...values.basic_shareholders.map((basic: any) => ({
        ...basic,
        entity_name: '',
        entity_address: '',
        entity_registration_number: '',
        entity_registration_office: '',
        entity_incorporation_date: '',
        entity_pan_number: '',
        entity_vat_number: '',
        entity_country: '',
        entity_registration_address: '',
        entity_business_address: '',
        entity_phone_number: '',
        entity_website: '',
      })),
      ...values.full_shareholders,
    ]

    return {
      directors: values.directors,
      share_holders: share_holders,
      facta_declarations: values.facta_declarations,
      other_banks: values.other_banks,
    }
  }

  const handleSubmit = (values: any, actions: any) => {
    const payload = {
      ...values,
      id: id,
      action: isSave ? 'save_and_continue' : 'save',
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
      registration_date_ad: formatDate(values?.registration_date_ad),
      registration_date_bs: formatDate(values?.registration_date_bs),
      registration_expiry_date_ad: formatDate(values?.registration_expiry_date_ad),
      registration_expiry_date_bs: formatDate(values?.registration_expiry_date_bs),
      pan_issue_date_ad: formatDate(values?.pan_issue_date_ad),
      pan_issue_date_bs: formatDate(values?.pan_issue_date_bs),
      blacklist_release_date: formatDate(values?.blacklist_release_date),
      cdd_edd_date: formatDate(values?.cdd_edd_date),
      annual_turn_over_as_on: formatDate(values?.annual_turn_over_as_on),
    }

    const additionalFormPayload: any = transformValues(values)

    addToAdditionalForm(
      {id, additionalFormPayload},
      {
        onSuccess: (response: any) => {
          // If addToAdditionalForm is successful, execute updateCorporateKyc
          updateCorporateKyc(payload, {
            onSuccess: (res: any) => {
              if (isSave) {
                setIsFirstFormSubmitted(true)
                setActiveTab('accountOpen')
                toast.success(res?.resDesc)
              } else {
                actions.setSubmitting(false)
                toast.success(res?.resDesc)
              }
              history.go(-1)
            },
            onError: (error) => {
              actions.setSubmitting(false)
            },
          })
        },
        onError: (error) => {
          actions.setSubmitting(false)
        },
      }
    )
  }

  const handleBack = () => {
    history.go(-1)
  }
  useEffect(() => {
    if (riskCode) {
      const fetchRiskData = async () => {
        try {
          await axios
            .get(`${BASE_API_URL}/risk-sub-categories`, {
              params: {risk_category_id: riskCode},
            })
            .then((res: any) => {
              setRiskSubCat(res?.data)
            })
        } catch (error) {
          console.error('Error fetching district data:', error)
        }
      }

      fetchRiskData()
    }
  }, [riskCode])

  const AddressType = [
    {
      value: 'P',
      label: 'Registration Address',
    },
    {
      value: 'C',
      label: 'Business Address',
    },
  ]

  const initialValues = {
    action: '',
    opening_mode: 'normal',
    branch_code: corporateDataById?.data?.branch_code || userBranchCode,
    customer_code: corporateDataById?.data?.customer_code || userBranchCode,
    account_type_id: corporateDataById?.data?.account_type_id?.id,
    institution_name: corporateDataById?.data?.institution_name || '',
    constitution_id: corporateDataById?.data?.constitution_id?.id || '',
    other_constitution: corporateDataById?.data?.other_constitution || '',
    foreign_company_name: corporateDataById?.data?.foreign_company_name || '',
    foreign_company_address: corporateDataById?.data?.foreign_company_address || '',
    registration_number: corporateDataById?.data?.registration_number || '',
    registration_with_id: corporateDataById?.data?.registration_with_id?.id || '',
    registration_date_ad: corporateDataById?.data?.registration_date_ad || '',
    registration_date_bs: corporateDataById?.data?.registration_date_bs || '',
    registration_expiry_date_ad: corporateDataById?.data?.registration_expiry_date_ad || '',
    registration_expiry_date_bs: corporateDataById?.data?.registration_expiry_date_bs || '',
    address_type: corporateDataById?.data?.address_type || '',
    registered_office_country:
      corporateDataById?.data?.registered_office_country?.id ||
      allMasterDataOptions?.countries?.find((item: any) => item?.cbs_code === 'NP')?.id,
    registered_office_zone: corporateDataById?.data?.registered_office_zone?.id || '',
    registered_office_province: corporateDataById?.data?.registered_office_province?.id || '',
    registered_office_district: corporateDataById?.data?.registered_office_district?.id || '',
    registered_office_city: corporateDataById?.data?.registered_office_city?.id || '',
    registered_office_municipality:
      corporateDataById?.data?.registered_office_municipality?.id || '',
    registered_office_ward_number: corporateDataById?.data?.registered_office_ward_number || '',
    registered_office_street_name: corporateDataById?.data?.registered_office_street_name || '',
    registered_office_house_number: corporateDataById?.data?.registered_office_house_number || '',
    registered_office_landline: corporateDataById?.data?.registered_office_landline || '',
    registered_office_mobile_number: corporateDataById?.data?.registered_office_mobile_number || '',
    registered_office_email: corporateDataById?.data?.registered_office_email || '',
    registered_office_fax_number: corporateDataById?.data?.registered_office_fax_number || '',
    registered_office_website: corporateDataById?.data?.registered_office_website || '',
    registered_office_outside_country_post_box:
      corporateDataById?.data?.registered_office_outside_country_post_box,
    registered_office_outside_country_full_address:
      corporateDataById?.data?.registered_office_outside_country_full_address,
    is_business_address_different: corporateDataById?.data?.is_business_address_different,
    business_office_country:
      corporateDataById?.data?.business_office_country?.id ||
      allMasterDataOptions?.countries?.find((item: any) => item?.cbs_code === 'NP')?.id,
    business_office_zone: corporateDataById?.data?.business_office_zone?.id || '',
    business_office_province: corporateDataById?.data?.business_office_province?.id || '',
    business_office_district: corporateDataById?.data?.business_office_district?.id || '',
    business_office_city: corporateDataById?.data?.business_office_city?.id || '',
    business_office_municipality: corporateDataById?.data?.business_office_municipality?.id || '',
    business_office_ward_number: corporateDataById?.data?.business_office_ward_number || '',
    business_office_street_name: corporateDataById?.data?.business_office_street_name || '',
    business_office_house_number: corporateDataById?.data?.business_office_house_number || '',
    business_office_landline: corporateDataById?.data?.business_office_landline || '',
    business_office_mobile_number: corporateDataById?.data?.business_office_mobile_number || '',
    business_office_email: corporateDataById?.data?.business_office_email || '',
    business_office_fax_number: corporateDataById?.data?.business_office_fax_number || '',
    business_office_website: corporateDataById?.data?.business_office_website || '',
    business_office_outside_country_post_box:
      corporateDataById?.data?.business_office_outside_country_post_box || '',
    business_office_outside_country_full_address:
      corporateDataById?.data?.business_office_outside_country_full_address || '',
    working_area: corporateDataById?.data?.working_area || '',
    business_nature_id: corporateDataById?.data?.business_nature_id?.id || '',
    income_tax_assess: 'Yes',
    pan_number: corporateDataById?.data?.pan_number || '',
    vat_number: corporateDataById?.data?.vat_number || '',
    pan_issue_date_ad: corporateDataById?.data?.pan_issue_date_ad || '',
    pan_issue_date_bs: corporateDataById?.data?.pan_issue_date_bs || '',
    pan_issuing_district: corporateDataById?.data?.pan_issuing_district?.id || '',
    expected_annual_turnover: corporateDataById?.data?.expected_annual_turnover?.id || null,
    expected_annual_transaction_number:
      corporateDataById?.data?.expected_annual_transaction_number?.id || '',
    location_map: 'test',
    office_place: corporateDataById?.data?.office_place,
    no_of_branches: corporateDataById?.data?.no_of_branches,
    main_office_name: corporateDataById?.data?.main_office_name,
    screening_id: corporateDataById?.data?.screening_id,
    blacklist_screening: corporateDataById?.data?.blacklist_screening || false,
    sanction_screening: corporateDataById?.data?.sanction_screening || false,
    aml_risk_rating:
      allMasterDataOptions?.risk_categories?.find((item: any) => item?.cbs_code === 'A')?.id ||
      corporateDataById?.data?.aml_risk_rating?.id ||
      '',
    sub_risk_category_id: corporateDataById?.data?.sub_risk_category_id?.id || '',
    pep_status: corporateDataById?.data?.pep_status || 'No',
    pep_information: corporateDataById?.data?.pep_information || '',
    // pep_category_id: '',
    // pep_sub_category_id: '',
    crime_conviction: corporateDataById?.data?.crime_conviction || false,
    crime_details: corporateDataById?.data?.crime_details || '',
    blacklisting_inclusion: corporateDataById?.data?.blacklisting_inclusion || false,
    blacklist_release_date: corporateDataById?.data?.blacklist_release_date || '',
    remarks: '',
    bank_code_id: corporateDataById?.data?.bank_code_id?.id || '',
    customer_category_id: corporateDataById?.data?.customer_category_id?.id || '',
    customer_group_id: corporateDataById?.data?.customer_group_id?.id || '',
    customer_status_id: corporateDataById?.data?.customer_status_id?.id || '',
    customer_free_text: corporateDataById?.data?.customer_free_text || '',
    cdd_edd_date: corporateDataById?.data?.cdd_edd_date || '',
    annual_turn_over_as_on: corporateDataById?.data?.annual_turn_over_as_on || '',
    total_annual_income: corporateDataById?.data?.total_annual_income?.id || '',
    directors: corporateDataById?.data?.directors?.directors || [
      {full_name: '', designation: '', cif_number: '', account_type_id: ''},
    ],
    has_beneficial: 'yes',
    beneficial_account_number: corporateDataById?.data?.beneficial_account_number,
    beneficial_cif_number: corporateDataById?.data?.beneficial_cif_number,
    beneficial_ms: corporateDataById?.data?.beneficial_ms,

    basic_shareholders: corporateDataById?.data.share_holders?.share_holders
      ?.filter(
        (shareholder: any) =>
          shareholder?.shareholder_name &&
          shareholder?.ownership_percentage &&
          shareholder?.shareholder_address &&
          !shareholder?.entity_name &&
          !shareholder?.entity_ownership_percentage &&
          !shareholder?.entity_address &&
          !shareholder?.entity_registration_number &&
          !shareholder?.entity_registration_office &&
          !shareholder?.entity_incorporation_date &&
          !shareholder?.entity_pan_number &&
          !shareholder?.entity_vat_number &&
          !shareholder?.entity_country?.id &&
          !shareholder?.entity_registration_address &&
          !shareholder?.entity_business_address &&
          !shareholder?.entity_phone_number &&
          !shareholder?.entity_website
      )
      .map((shareholder: any) => ({
        shareholder_name: shareholder?.shareholder_name || '',
        ownership_percentage: shareholder?.ownership_percentage || '',
        shareholder_address: shareholder?.shareholder_address || '',
      })) || [
      {
        shareholder_name: '',
        ownership_percentage: '',
        shareholder_address: '',
      },
    ],
    full_shareholders: corporateDataById?.data.share_holders?.share_holders
      ?.filter(
        (shareholder: any) =>
          shareholder?.shareholder_name &&
          shareholder?.ownership_percentage &&
          shareholder?.shareholder_address &&
          (shareholder?.entity_name ||
            shareholder?.entity_ownership_percentage ||
            shareholder?.entity_address ||
            shareholder?.entity_registration_number ||
            shareholder?.entity_registration_office ||
            shareholder?.entity_incorporation_date ||
            shareholder?.entity_pan_number ||
            shareholder?.entity_vat_number ||
            shareholder?.entity_country?.id ||
            shareholder?.entity_registration_address ||
            shareholder?.entity_business_address ||
            shareholder?.entity_phone_number ||
            shareholder?.entity_website)
      )
      .map((shareholder: any) => ({
        shareholder_name: shareholder?.shareholder_name || '',
        ownership_percentage: shareholder?.ownership_percentage || '',
        shareholder_address: shareholder?.shareholder_address || '',
        entity_name: shareholder?.entity_name || '',
        entity_ownership_percentage: shareholder?.entity_ownership_percentage || '',
        entity_address: shareholder?.entity_address || '',
        entity_registration_number: shareholder?.entity_registration_number || '',
        entity_registration_office: shareholder?.entity_registration_office || '',
        entity_incorporation_date: shareholder?.entity_incorporation_date || '',
        entity_pan_number: shareholder?.entity_pan_number || '',
        entity_vat_number: shareholder?.entity_vat_number || '',
        entity_country: shareholder?.entity_country?.id || '',
        entity_registration_address: shareholder?.entity_registration_address || '',
        entity_business_address: shareholder?.entity_business_address || '',
        entity_phone_number: shareholder?.entity_phone_number || '',
        entity_website: shareholder?.entity_website || '',
      })) || [
      {
        shareholder_name: '',
        ownership_percentage: '',
        shareholder_address: '',
        entity_name: '',
        entity_ownership_percentage: '',
        entity_address: '',
        entity_registration_number: '',
        entity_registration_office: '',
        entity_incorporation_date: '',
        entity_pan_number: '',
        entity_vat_number: '',
        entity_country: '',
        entity_registration_address: '',
        entity_business_address: '',
        entity_phone_number: '',
        entity_website: '',
      },
    ],

    other_banks: corporateDataById?.data?.other_banks?.other_banks || [
      {
        bank_name: '',
        branch_name: '',
        saving_ac: '',
        current_ac: '',
        od_ac: '',
        term_loan: '',
        others: '',
      },
    ],
    facta_declarations:
      corporateDataById?.data?.facta_declarations?.facta_declarations?.length > 0
        ? corporateDataById.data.facta_declarations.facta_declarations.map((declaration: any) => ({
            us_person: declaration?.us_person || false,
            us_person_remarks: declaration?.us_person_remarks || '',
            us_telephone_number: declaration?.us_telephone_number || '',
            us_indicia_remarks: declaration?.us_indicia_remarks || '',
            tin_number: declaration?.tin_number || '',
            giin_number: declaration?.giin_number || '',
            giin_number_remarks: declaration?.giin_number_remarks || '',
            w9_form_submitted: declaration?.w9_form_submitted || '',
          }))
        : [
            {
              us_person: false,
              us_person_remarks: '',
              us_telephone_number: '',
              us_indicia_remarks: '',
              tin_number: '',
              giin_number: false,
              giin_number_remarks: '',
              w9_form_submitted: '',
            },
          ],
  }
  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body text-center '>
          <h3>
            <Spinner animation='border' variant='danger' className='mb-3' />
            <br />
            Please Wait...
          </h3>
        </div>
      </div>
    )
  }

  const blackListAndCrime = [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'},
  ]
  const incomeTaxAccess = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'},
  ]

  const handleChangeBoolean = (event: any, setFieldValue: any, setTouched: any) => {
    const {name, value} = event.target
    setFieldValue(name, value === 'true')
    setTouched({[name]: true}, false)
  }
  const data: any = corporateDataById?.data

  const handleCheckboxChange = (setFieldValue: any, values: any, setTouched: any) => {
    setFieldValue('is_business_address_different', !values.is_business_address_different)

    if (!values.is_business_address_different) {
      setFieldValue('business_office_country', values.registered_office_country)
      setFieldValue('business_office_province', values.registered_office_province)
      setFieldValue('business_office_zone', values.registered_office_zone)
      setFieldValue('business_office_district', values.registered_office_district)
      setFieldValue('business_office_municipality', values.registered_office_municipality)
      setFieldValue('business_office_city', values.registered_office_city)
      setFieldValue('business_office_ward_number', values.registered_office_ward_number)
      setFieldValue('business_office_street_name', values.registered_office_street_name)
      setFieldValue('business_office_house_number', values.registered_office_house_number)
      setFieldValue('business_office_mobile_number', values.registered_office_mobile_number)
      setFieldValue('business_office_fax_number', values.registered_office_fax_number)
      setFieldValue('business_office_email', values.registered_office_email)
      setFieldValue('business_office_website', values.registered_office_website)
      setFieldValue(
        'business_office_outside_country_post_box',
        values.registered_office_outside_country_post_box
      )
      setFieldValue(
        'business_office_outside_country_full_address',
        values.registered_office_outside_country_full_address
      )

      setTouched(
        {
          business_office_country: false,
          business_office_province: false,
          business_office_zone: false,
          business_office_district: false,
          business_office_municipality: false,
          business_office_city: false,
          business_office_ward_number: false,
          business_office_street_name: false,
          business_office_house_number: false,
          business_office_mobile_number: false,
          business_office_fax_number: false,
          business_office_email: false,
          business_office_website: false,
          business_office_outside_country_post_box: false,
          business_office_outside_country_full_address: false,
        },
        false
      )
    } else {
      setFieldValue('business_office_province', '')
      setFieldValue('business_office_zone', '')
      setFieldValue('business_office_district', '')
      setFieldValue('business_office_municipality', '')
      setFieldValue('business_office_city', '')
      setFieldValue('business_office_ward_number', '')
      setFieldValue('business_office_street_name', '')
      setFieldValue('business_office_house_number', '')
      setFieldValue('business_office_mobile_number', '')
      setFieldValue('business_office_fax_number', '')
      setFieldValue('business_office_email', '')
      setFieldValue('business_office_website', '')
      setFieldValue('business_office_outside_country_post_box', '')
      setFieldValue('business_office_outside_country_full_address', '')

      setTouched(
        {
          business_office_country: false,
          business_office_province: false,
          business_office_zone: false,
          business_office_district: false,
          business_office_municipality: false,
          business_office_city: false,
          business_office_ward_number: false,
          business_office_street_name: false,
          business_office_house_number: false,
          business_office_mobile_number: false,
          business_office_fax_number: false,
          business_office_email: false,
          business_office_website: false,
          business_office_outside_country_post_box: false,
          business_office_outside_country_full_address: false,
        },
        false
      )
    }
    setSameAsPermanentAddress(!sameAsPermanentAddress)
  }

  const handleScreening = () => {
    setScreeningLoading(true)

    const checkAllPayload: any = {
      customer_name: corporateDataById?.data?.institution_name,
      pan_number: corporateDataById?.data?.pan_number,
      model_name: 'NewCIF',
    }
    mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
      },
    })
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        // validationSchema={CORPORATE_CPU_FORM_VALIDATION}
        validationSchema={
          isSave ? CORPORATE_CPU_FORM_VALIDATION(allMasterDataOptions, true) : undefined
        }
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          isSubmitting,
          touched,
          handleChange,
          handleSubmit,
          errors,
          setFieldValue,
          values,
          setTouched,
        }) => (
          <Form className='bg-white'>
            <div className='card mb-5 mb-xl-10 '>
              <div className='card-body border-top p-0'>
                <div className='row'>
                  <div className='col-12 col-md-6 pe-0'>
                    <div className='card mb-5 mb-xl-10' style={{position: 'sticky', top: '80px'}}>
                      <div className='card-body p-0 '>
                        <DocumentsPreview data={data} />
                      </div>
                    </div>
                  </div>
                  <div
                    className='col-12 col-md-6 ps-2'
                    // style={{position: 'sticky', top: '80px', marginLeft: '-25px'}}
                  >
                    <Tabs
                      activeKey={activeTab}
                      onSelect={(tab) => setActiveTab(tab || 'basicInfo')}
                      id='controlled-tab-example'
                    >
                      <Tab
                        eventKey='basicInfo'
                        title='CIF Form'
                        style={{
                          maxHeight: '700px',
                          overflowY: 'auto',
                        }}
                      >
                        <>
                          {/* <div className='d-flex justify-content-start mb-3 text-align-center'>
                          <h3
                            style={{marginRight: '0.8rem'}}
                          >{`CORPORATE PRELIMINARY Form`}</h3>
                        </div> */}
                          <section className='py-3'>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Branch Code'
                                  name='branch_code'
                                  label='Branch Code'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={6}
                                  disabled
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Screening Id'
                                  name='screening_id'
                                  label='Screening Id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={6}
                                  disabled
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Customer Code'
                                  name='customer_code'
                                  label='Customer Code'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={6}
                                  disabled
                                />
                              </div>
                              <h3 className=' heading__component__title fw-bolder text-uppercase col-12'>
                                A. Name of Institution:
                              </h3>{' '}
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12 '
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Institution Name'
                                  name='institution_name'
                                  label='Name of institution'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  disabled
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  uppercase={true}
                                />
                              </div>
                            </div>
                          </section>
                          <hr />
                          <section className='py-3'>
                            <div className='row'>
                              <h3 className=' heading__component__title fw-bolder text-uppercase'>
                                B. Constitution:
                              </h3>{' '}
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Constitution'
                                  name='constitution_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.constitutions?.map(
                                    (item: any) => ({
                                      label: item?.title?.toLocaleUpperCase() || '',
                                      value: item?.id,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  required
                                  disabled
                                  // colors={true}
                                />
                              </div>
                              {allMasterDataOptions?.constitutions?.find(
                                (item: any) => item?.id === values?.constitution_id
                              )?.title === 'Other (specify)' && (
                                <div className='col-md-4 col-sm-6 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Other Constitutions'
                                    name='other_constitution'
                                    label='Other Constitution'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    required
                                    //  className={'bg-warning w-100 p-3 rounded-2'}
                                    disabled
                                  />
                                </div>
                              )}
                              {allMasterDataOptions?.constitutions?.find(
                                (item: any) => item?.id === values?.constitution_id
                              )?.title === 'FOREIGN ENTERPRISE' && (
                                <>
                                  <label className='form-label fw-bolder text-dark fs-6 '>
                                    if the associates of foreign company/entity, details of foreign
                                    company/entity
                                  </label>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='name'
                                      name='foreign_company_name'
                                      label='Name'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Address'
                                      name='foreign_company_address'
                                      label='Address'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </section>
                          <hr />
                          <section className='py-3'>
                            <h3 className=' heading__component__title fw-bolder text-uppercase'>
                              C. Details of Registration:
                            </h3>{' '}
                            <div className='row'>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='registration'
                                  name='registration_number'
                                  label=' i. Registration No.'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  disabled
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='ii. Registered With'
                                  name='registration_with_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.corporate_registrations?.map(
                                    (item: any) => ({
                                      label: item?.title?.toLocaleUpperCase() || '',
                                      value: item?.id,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  required
                                  // colors={true}
                                />
                              </div>

                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  label='iii. Registration Date (AD)'
                                  name='registration_date_ad'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.registration_date_ad}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  // disabled={values.start_date ? false : true}
                                  required
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                  data={'registration_date_bs'}
                                />
                              </div>

                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  nepaliFormName='registration_date_bs'
                                  englishFormName='registration_date_ad'
                                  label='iv. Registration Date (BS)'
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  required
                                />
                              </div>

                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  label='v. Registration Expiry Date (AD)'
                                  name='registration_expiry_date_ad'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.registration_expiry_date_ad}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  // disabled={values.start_date ? false : true}
                                  // required
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                  data={'registration_expiry_date_bs'}
                                />
                              </div>

                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  nepaliFormName='registration_expiry_date_bs'
                                  englishFormName='registration_expiry_date_ad'
                                  label='vi. Registration Expiry Date (BS)'
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  // required
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Address Type'
                                  name={'address_type'}
                                  label='Address Type'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  options={AddressType}
                                  setFieldValue={setFieldValue}
                                  arrValue={values?.address_type}
                                />
                              </div>
                            </div>
                          </section>
                          <hr />
                          <section className='py-3'>
                            <div>
                              <h3 className=' heading__component__title fw-bolder text-uppercase'>
                                v. Registered Address:
                              </h3>{' '}
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Country'
                                  label='Country'
                                  name={'registered_office_country'}
                                  onChange={(e: any) => {
                                    handleChange(e)
                                    onCountryChange(e, setFieldValue, setTouched)
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.countries?.map((country: any) => ({
                                    value: country?.id || country?.value,
                                    label: country?.title || country?.label,
                                  }))}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  required
                                  // colors={true}
                                />
                              </div>
                              {allMasterDataOptions?.countries?.find(
                                (item: any) => item?.id === values?.registered_office_country
                              )?.title === 'NEPAL' ? (
                                <>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select Province'
                                      label='Province'
                                      name={'registered_office_province'}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      options={provinceOptions}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      required
                                      // colors={true}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select Zone'
                                      label='Zone'
                                      name='registered_office_zone'
                                      onChange={(w: any) => {
                                        handleChange(w)
                                        getZoneCode(w, values)
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      options={zoneOptions}
                                      setFieldValue={setFieldValue}
                                      required
                                      // colors={true}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select District'
                                      label='District'
                                      name={'registered_office_district'}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      options={
                                        !districtData
                                          ? districtOptions.map((district: any) => ({
                                              value: district?.id || district?.value,
                                              label: district?.title || district?.label,
                                            }))
                                          : districtData?.data?.district?.map((district: any) => ({
                                              value: district?.id || district?.value,
                                              label: district?.title || district?.label,
                                            }))
                                      }
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      required
                                    />
                                  </div>

                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select Municipality'
                                      label='Municipality'
                                      name={'registered_office_municipality'}
                                      // onChange={handleChange}
                                      onChange={(e: any) => {
                                        handleChange(e)
                                        if (districtData) {
                                          return
                                        } else {
                                          getMunicipilities(e, 'registered', setFieldValue)
                                        }
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      options={municipalitiesOptions}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      required
                                    />
                                  </div>

                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Registered Office City'
                                      name={`registered_office_city`}
                                      label='Registered Office City'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      options={allMasterDataOptions?.cities?.map((city: any) => ({
                                        value: city?.id || city.value,
                                        label: city?.title || city.label,
                                      }))}
                                      setFieldValue={setFieldValue}
                                      required
                                      // colors={true}
                                    />
                                  </div>

                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Ward Number'
                                      name={'registered_office_ward_number'}
                                      label='Ward Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                      required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Tole'
                                      name={'registered_office_street_name'}
                                      label='Tole'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                      required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='House Number'
                                      name={'registered_office_house_number'}
                                      label='House Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Mobile Number'
                                      name={'registered_office_mobile_number'}
                                      label='Mobile Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                      disabled
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Email'
                                      name={'registered_office_email'}
                                      label='Email'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                      required
                                      disabled
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className='row'>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Full Address'
                                      name='registered_office_outside_country_full_address'
                                      label='Foreign Company Address'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Post Box No.'
                                      name='registered_office_outside_country_post_box'
                                      label='Post Box No.'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                    />
                                  </div>

                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Mobile Number'
                                      name={'registered_office_mobile_number'}
                                      label='Mobile Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                      disabled
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Email'
                                      name={'registered_office_email'}
                                      label='Email'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                      required
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <h3 className=' heading__component__title fw-bolder text-uppercase'>
                                Vi. Registered Address:
                              </h3>{' '}
                            </div>
                            <div className='row'>
                              <div className='form-check ms-4'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='is_business_address_different'
                                  value=''
                                  id='sameAsPermanentAddress'
                                  checked={
                                    values?.is_business_address_different === true ? true : false
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(setFieldValue, values, setTouched)
                                  }
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='sameAsPermanentAddress'
                                >
                                  Same as Registered address
                                </label>
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Country'
                                  label='Country'
                                  name={'business_office_country'}
                                  onChange={(e: any) => {
                                    handleChange(e)
                                    onCountryChange(e, setFieldValue, setTouched)
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.countries?.map((country: any) => ({
                                    value: country?.id || country.value,
                                    label: country?.title || country.label,
                                  }))}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  required
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                />
                              </div>
                              {allMasterDataOptions?.countries?.find(
                                (item: any) => item?.id === values.business_office_country
                              )?.title === 'NEPAL' ? (
                                <>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select Province'
                                      label='Province'
                                      name={'business_office_province'}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      options={provinceOptions}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      required
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select Zone'
                                      label='Zone'
                                      name='business_office_zone'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      options={zoneOptions}
                                      setFieldValue={setFieldValue}
                                      required
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select District'
                                      label='District'
                                      name={'business_office_district'}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      options={districtOptions}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      required
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select Municipality'
                                      label='Municipality'
                                      name={'business_office_municipality'}
                                      onChange={(e: any) => {
                                        handleChange(e)
                                        getMunicipilities(e, 'business', setFieldValue) // Pass 'permanent'
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      options={municipalitiesOptions}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      required
                                      //  className={'bg-warning w-100 p-3 rounded-2'}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Business Office City'
                                      name={`business_office_city`}
                                      label='Business Office City'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      options={allMasterDataOptions?.cities?.map((city: any) => ({
                                        value: city?.id || city.value,
                                        label: city?.title || city.label,
                                      }))}
                                      setFieldValue={setFieldValue}
                                      required
                                    />
                                  </div>

                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Ward Number'
                                      name={'business_office_ward_number'}
                                      label='Ward Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Tole'
                                      name={'business_office_street_name'}
                                      label='Tole'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='House Number'
                                      name={'business_office_house_number'}
                                      label='House Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Mobile Number'
                                      name={'business_office_mobile_number'}
                                      label='Mobile Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                      maxLength={10}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Email'
                                      name={'business_office_email'}
                                      label='Email'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                      maxLength={50}
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className='row'>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Full Address'
                                      name='business_office_outside_country_full_address'
                                      label='Foreign Company Address'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Post Box No.'
                                      name='business_office_outside_country_post_box'
                                      label='Post Box No.'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                    />
                                  </div>

                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Mobile Number'
                                      name={'business_office_mobile_number'}
                                      label='Mobile Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Email'
                                      name={'business_office_email'}
                                      label='Email'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                      maxLength={50}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className='row'>
                                <div className='col-md-4 col-sm-6 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Area'
                                    name='working_area'
                                    label=' vii. Working Area, If defined'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                                <div className='col-md-4 col-sm-6 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Select'
                                    label='viii. Business / Occupation '
                                    name='business_nature_id'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    options={allMasterDataOptions?.business_natures?.map(
                                      (item: any) => ({
                                        label: item?.title?.toLocaleUpperCase() || '',
                                        value: item?.id,
                                      })
                                    )}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    required
                                    // colors={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </section>
                          <hr />
                          <div className='row'>
                            <h3 className=' heading__component__title fw-bolder text-uppercase'>
                              Branch Office Details
                            </h3>{' '}
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='(List the Places having offices)'
                                name='office_place'
                                label='Offices At'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                // uppercase={true}
                                maxLength={50}
                              />
                            </div>
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Main Branches'
                                name='main_office_name'
                                label='Main Branch'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                // uppercase={true}
                                maxLength={50}
                              />
                            </div>
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Number of Branch'
                                name='no_of_branches'
                                label='Number of Branch'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                // uppercase={true}
                                maxLength={2}
                              />
                            </div>
                          </div>
                          <section className='py-3'>
                            <h3 className=' heading__component__title fw-bolder text-uppercase'>
                              E. Income Tax Assessed:
                            </h3>{' '}
                            {/* {values.income_tax_assess === 'Yes' && ( */}
                            <>
                              {/* <label self declarationclassName='form-label fw-bolder text-dark fs-6 required'>
                                If Yes, please furnish PAN/VAT Number
                              </label> */}
                              <div className='row'>
                                {values.pan_number && (
                                  <>
                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                      <FormTextBox
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='PAN No.'
                                        name='pan_number'
                                        label='PAN NO.'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        //  className={'bg-warning w-100 p-3 rounded-2'}
                                        required
                                        disabled
                                      />
                                    </div>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormDatepicker
                                        labelClassName='col-12'
                                        containerClassName='col-12 '
                                        label='Issued Date (AD)'
                                        name='pan_issue_date_ad'
                                        placeholderText='YYYY-MM-DD'
                                        setFieldValue={setFieldValue}
                                        value={values?.pan_issue_date_ad}
                                        // minDate={values?.start_date}
                                        errors={errors}
                                        touched={touched}
                                        showIcon={true}
                                        required
                                        // isClearable={isEndDateNotRequired}
                                        data-cy='end-date-drop-down'
                                        data={'pan_issue_date_bs'}
                                      />
                                    </div>

                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormNepaliCalendarDatePicker
                                        nepaliFormName='pan_issue_date_bs'
                                        englishFormName='pan_issue_date_ad'
                                        label='Issued Date (BS)'
                                        setFieldValue={setFieldValue}
                                        touched={touched}
                                        values={values}
                                        required
                                      />
                                    </div>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormSelect
                                        labelClassName='col-md-12'
                                        containerClassName='col-lg-12'
                                        placeholder='Select'
                                        label='Issuing District'
                                        name='pan_issuing_district'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        options={districtOptions}
                                        setFieldValue={setFieldValue}
                                        required
                                        arrValue={values?.pan_issuing_district}
                                      />
                                    </div>
                                  </>
                                )}
                                {values.vat_number && (
                                  <>
                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                      <FormTextBox
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='VAT No.'
                                        name='vat_number'
                                        label='VAT NO.'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        required
                                        disabled
                                        //  className={'bg-warning w-100 p-3 rounded-2'}
                                      />
                                    </div>
                                  </>
                                )}
                                <div className='col-md-4 col-12'>
                                  <FormSelect
                                    name='pep_status'
                                    label='PEP Confirmation'
                                    labelClassName='col-12'
                                    containerClassName='col-12'
                                    options={[
                                      {label: 'YES', value: 'Yes'},
                                      {label: 'NO', value: 'No'},
                                    ]}
                                    setFieldValue={setFieldValue}
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    // disabled
                                    required
                                  />
                                </div>
                                {values.pep_status === 'Yes' && (
                                  <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='PEP Information'
                                      name='pep_information'
                                      label='PEP information'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                      // uppercase={true}
                                    />
                                  </div>
                                )}
                              </div>
                            </>
                          </section>
                          <hr />

                          {/* )} */}
                          <section className='py-3'>
                            <DirectorsTable
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </section>
                          <hr />

                          <section className='py-3'>
                            <ShareholdersTable
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </section>
                          <hr />
                          <section className='py-3'>
                            <div className=''>
                              <h3 className=' heading__component__title fw-bolder text-uppercase'>
                                H. Expected Annual Turnover:
                              </h3>{' '}
                              <div className='row'>
                                <div className='col-md-6 col-sm-12 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Expected Annual Turnover'
                                    label='Expected Annual Turnover'
                                    values={values}
                                    name='expected_annual_turnover'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    options={allMasterDataOptions?.annual_turn_overs
                                      ?.filter((turnOver: any) => turnOver.is_annual_turnover) // Filter for items with is_annual_income as true
                                      .map((turnOver: any) => ({
                                        value: turnOver.id || turnOver.value,
                                        label: turnOver.title || turnOver.label,
                                      }))}
                                    setFieldValue={setFieldValue}
                                    required
                                    // colors={true}
                                  />
                                </div>
                                <div className='col-md-6 col-sm-12 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Select Annual Income'
                                    label='Annual Income'
                                    values={values?.total_annual_income}
                                    name='total_annual_income'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    options={allMasterDataOptions?.annual_turn_overs
                                      ?.filter((turnOver: any) => turnOver.is_annual_income) // Filter for items with is_annual_income as true
                                      .map((turnOver: any) => ({
                                        value: turnOver.id || turnOver.value,
                                        label: turnOver.title || turnOver.label,
                                      }))}
                                    setFieldValue={setFieldValue}
                                    arrValue={values?.total_annual_income}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            {/* <label className='form-label fw-bolder text-dark fs-6 required'>
                            Expected Annual Transaction No.
                          </label> */}
                            <div className=''>
                              <h3 className=' heading__component__title fw-bolder text-uppercase'>
                                I. Expected annual transaction No:
                              </h3>{' '}
                              <FormSelect
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-6'
                                placeholder='Expected Annual Transaction Number'
                                name='expected_annual_transaction_number'
                                label='Expected Annual Transaction Number'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                                options={allMasterDataOptions?.annual_transactions?.map(
                                  (transaction: any) => ({
                                    value: transaction?.id || transaction.value,
                                    label: transaction?.title || transaction.label,
                                  })
                                )}
                                setFieldValue={setFieldValue}
                                required
                                // colors={true}
                              />
                            </div>
                          </section>
                          <hr />
                          {/* <section className='py-3'>
                            <div className='row'>
                              <h3 className=' heading__component__title fw-bolder text-uppercase'>
                                J. Location Map of Company/ Institutions
                              </h3>{' '}
                            </div>
                            <div
                              className='location-map'
                              style={{width: '500px', height: '200px', border: '1px solid black'}}
                            ></div>
                          </section>
                          <hr /> */}
                          <section className='py-3'>
                            <OtherBanksTable
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </section>
                          <hr />

                          <div className='row'>
                            <h3 className=' heading__component__title fw-bolder text-uppercase'>
                              K. Self Declaration :
                            </h3>{' '}
                          </div>
                          <div className='row'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Declaration of Convicted or charged of any crime in the past
                            </label>
                            <div className='row py-4 align-items-center col-sm-12 col-xs-12'>
                              {blackListAndCrime?.map((status) => (
                                <div className='col-lg-4'>
                                  <FormRadio
                                    containerClassName='col-lg-12'
                                    label='Select Status'
                                    name='crime_conviction'
                                    type='radio'
                                    onChange={(e) =>
                                      handleChangeBoolean(e, setFieldValue, setTouched)
                                    }
                                    errors={errors}
                                    touched={touched}
                                    checkBoxText={status?.label}
                                    value={status?.value}
                                    checked={status?.value === values?.crime_conviction}
                                    required={true}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                          {values?.crime_conviction === true && (
                            <div className='mb-3'>
                              <FormTextBox
                                labelClassName='col-lg-4'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Specify'
                                name='crime_details'
                                label='If yes, specify'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={values?.crime_conviction === true}
                              />
                            </div>
                          )}
                          <div className='row'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              BlackListing Inclusion
                            </label>
                            <div className='row py-4 align-items-center col-sm-12 col-xs-12'>
                              {blackListAndCrime?.map((status) => (
                                <div className='col-lg-4'>
                                  <FormRadio
                                    containerClassName='col-lg-12'
                                    label='Select Status'
                                    name='blacklisting_inclusion'
                                    type='radio'
                                    onChange={(e) =>
                                      handleChangeBoolean(e, setFieldValue, setTouched)
                                    }
                                    errors={errors}
                                    touched={touched}
                                    checkBoxText={status?.label}
                                    value={status?.value}
                                    checked={status?.value === values?.blacklisting_inclusion}
                                    required={true}
                                  />
                                </div>
                              ))}
                            </div>
                            {values?.blacklisting_inclusion === true && (
                              <div className='mb-3'>
                                {/* <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='date'
                                  placeholder='Specify'
                                  name='blacklist_release_date'
                                  label='If now released from blacklist, date of release'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={values?.blacklisting_inclusion === true}
                                /> */}
                                <FormDatepickerNew
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  label='If now released from blacklist, date of release'
                                  placeholderText='YYYY-MM-DD'
                                  name='blacklist_release_date'
                                  setFieldValue={setFieldValue}
                                  value={values?.blacklist_release_date}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  required={values?.blacklisting_inclusion === true}
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                />
                              </div>
                            )}
                          </div>
                          <div className='col-12'>
                            <FactaDeclarationTable
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                            />
                          </div>

                          <section className='py-3'>
                            <h3 className=' heading__component__title fw-bolder text-uppercase'>
                              M. MIS Details :
                            </h3>{' '}
                            <div className='row'>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Bank Code'
                                  name='bank_code_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  arrValue={values?.bank_code_id}
                                  options={allMasterDataOptions?.bank_codes?.map(
                                    (bankCode: any) => ({
                                      value: bankCode?.id || bankCode.value,
                                      label: bankCode?.title || bankCode.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                              {/* <div className='col-md-4 col-sm-6 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Select'
                                    label='Customer Category'
                                    name='customer_category_id'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    options={allMasterDataOptions?.customer_categories?.map(
                                      (item: any) => ({
                                        label: item?.title?.toLocaleUpperCase() || '',
                                        value: item?.id,
                                      })
                                    )}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    required
                                  />
                                </div> */}
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Customer Group '
                                  name='customer_group_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  options={allMasterDataOptions?.customer_groups?.map(
                                    (customer_groups: any) => ({
                                      value: customer_groups?.id || customer_groups.value,
                                      label: customer_groups?.title || customer_groups.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Customer Status '
                                  name='customer_status_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  options={allMasterDataOptions?.customer_statuses?.map(
                                    (customer_statuses: any) => ({
                                      value: customer_statuses?.id || customer_statuses.value,
                                      label: customer_statuses?.title || customer_statuses.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Details'
                                  name='customer_free_text'
                                  label='Customer Status Details'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // uppercase={true}
                                  required
                                  maxLength={50}
                                />
                              </div>

                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                {/* <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='date'
                                  placeholder='CDD & EDD date'
                                  name='cdd_edd_date'
                                  label='CDD & EDD date'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                 // uppercase={true}
                                  required
                                  value={values?.cdd_edd_date}
                                /> */}
                                <FormDatepickerNew
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  label='CDD & EDD date'
                                  name='cdd_edd_date'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.cdd_edd_date}
                                  // minDate={new Date()}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  required
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                {/* <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='date'
                                  placeholder='Annual Turnover As On'
                                  name='annual_turn_over_as_on'
                                  label='Annual Turnover As On'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                 // uppercase={true}
                                  required
                                  value={values?.annual_turn_over_as_on}
                                /> */}
                                <FormDatepickerNew
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  label='Annual Turnover As On'
                                  name='annual_turn_over_as_on'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.annual_turn_over_as_on}
                                  // minDate={new Date()}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  required
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Risk Rating'
                                  label='Risk Rating'
                                  name='aml_risk_rating'
                                  // onChange={(w: any) => {
                                  //   handleChange(w)
                                  //   getRiskCode(w, values)
                                  // }}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.risk_categories?.map(
                                    (option: any) => ({
                                      value: option?.id || option.value,
                                      label: option?.title || option.label,
                                    })
                                  )}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  required
                                  disabled={isDisabled}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Risk Rating SubCategory'
                                  label='Risk Rating SubCategory'
                                  name='sub_risk_category_id'
                                  onChange={(w: any) => {
                                    handleChange(w)
                                    getSubRiskCode(w, setFieldValue)
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.risk_sub_categories?.map(
                                    (option: any) => ({
                                      value: option?.id || option.value,
                                      label: option?.title || option.label,
                                    })
                                  )}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                            </div>
                          </section>
                          <hr />
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Remarks'
                              name='remarks'
                              label='Remarks'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                              as='textarea'
                            />
                          </div>
                          {corporateDataById?.data?.forced_continue && (
                            <div
                              style={{
                                padding: '10px',
                                backgroundColor: '#d4edda',
                                border: '1px solid #c3e6cb',
                                borderRadius: '5px',
                                marginBottom: '20px',
                              }}
                            >
                              <strong>This account was found in the deduplication Screening</strong>
                            </div>
                          )}
                          <div
                            style={{
                              padding: '10px',
                              backgroundColor: '#d4edda',
                              border: '1px solid #c3e6cb',
                              borderRadius: '5px',
                              marginBottom: '20px',
                            }}
                          >
                            <strong>All documents are verified.</strong>
                          </div>

                          <div className='col-12'>
                            <div className='row'>
                              {screeningPerformed ? (
                                checkAllData && (
                                  <Button
                                    type='button'
                                    onClick={handleOpenPdf}
                                    className='col-md-3 col-sm-6 col-xs-12'
                                  >
                                    View Details
                                  </Button>
                                )
                              ) : screeningLoading ? (
                                <Spinner animation='border' role='status'>
                                  <span className='sr-only'>Loading...</span>
                                </Spinner>
                              ) : (
                                <Button
                                  type='button'
                                  onClick={handleScreening}
                                  className='col-md-4 col-sm-3 col-xs-12'
                                >
                                  Perform Screening
                                </Button>
                              )}
                              <div className='my-7'>
                                <h3>Screening Files</h3>
                                {corporateDataById?.data?.screening_files?.screening_files?.length >
                                0 ? (
                                  <table className='table table-striped'>
                                    <thead>
                                      <tr>
                                        <th>S.No.</th>
                                        <th>File Name</th>
                                        <th>Performed By</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {corporateDataById?.data?.screening_files?.screening_files?.map(
                                        (file: any, index: any) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{file.file_name}</td>
                                            <td>{file.created_by}</td>
                                            <td>
                                              <button
                                                type='button'
                                                onClick={() => handlePdfFileClick(file.file_path)}
                                                className='btn btn-primary'
                                              >
                                                View
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                ) : (
                                  <p>No files available</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-end my-5 px-5'>
                            <div className='card-footer text-end'>
                              <button
                                type='button'
                                className='btn btn-secondary me-2'
                                onClick={handleBack}
                              >
                                Back
                              </button>
                              <button
                                type='submit'
                                className='btn btn-primary me-2'
                                onClick={() => {
                                  setIsSave(true)
                                }}
                              >
                                {isSave && isSubmitLoading ? 'Submitting...' : 'Submit'}
                              </button>
                              <button
                                type='submit'
                                className='btn btn-warning me-2'
                                onClick={() => {
                                  setIsSave(false)
                                }}
                              >
                                {!isSave && isSubmitLoading
                                  ? 'Saving as Draft...'
                                  : 'Save as Draft'}
                              </button>
                            </div>
                          </div>
                          {/* </div> */}
                        </>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <AccountStatusLog data={data} />
            <UserActivityLogAll caseId={id} modalName={data?.model_name} modalData={data} />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default NewCifCorporateCpu
