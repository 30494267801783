import {isEmpty} from 'lodash'
import React, {useState, useEffect} from 'react'
import {Table, SelectPicker, Button, Modal} from 'rsuite'
import {getUserActivity} from 'src/app/queries/new-cif-new-account-queries/getUserActivity'

export const UserActivityLogAll = ({caseId, modalName, modalData}: any) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null)
  const [tableData, setTableData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [compareData, setCompareData] = useState<any>(null) // For modal data

  // Function to fetch activity logs for a single request
  const fetchActivityLog = async (params: any) => {
    setLoading(true)
    try {
      const response = await getUserActivity(params)
      if (response?.data?.activity_logs) {
        return response.data.activity_logs
      }
      return []
    } catch (error) {
      console.error('Error fetching activity logs:', error)
      return []
    } finally {
      setLoading(false)
    }
  }

  // Function to fetch activity logs for multiple requests (looped calls)
  const fetchActivityLogs = async (data: any[]) => {
    setLoading(true)
    const allLogs: any[] = []

    try {
      const promises = data.map((item) =>
        getUserActivity({
          model_name: item.model_name,
          model_id: item.id,
          page: 1,
          limit: 10,
          order: 'desc',
          orderBy: 'updated_at',
        })
      )

      const responses = await Promise.all(promises)
      responses.forEach((response) => {
        if (response?.data?.activity_logs) {
          allLogs.push(...response.data.activity_logs) // Extract activity_logs correctly
        }
      })

      setTableData(allLogs) // Update the table data with extracted logs
    } catch (error) {
      console.error('Error fetching activity logs:', error)
      setTableData([])
    } finally {
      setLoading(false)
    }
  }

  // Initial API Call on Component Render
  useEffect(() => {
    if (caseId && modalName) {
      fetchActivityLog({
        model_name: modalName,
        model_id: caseId,
        page: 1,
        limit: 10,
        order: 'desc',
        orderBy: 'updated_at',
      }).then((data) => setTableData(data))
    }
  }, [caseId, modalName])
  // Handle dropdown selection
  const handleDropdownChange = (value: any) => {
    setSelectedItem(value)

    const selectedData = modalData[value]?.[value] || modalData[value] || []

    // Check if `selectedData` is an array or a single object
    const mappedData = Array.isArray(selectedData)
      ? selectedData.map((item: any) => ({
          id: item.id,
          model_name: item.model_name,
        }))
      : [
          {
            id: selectedData.id,
            model_name: selectedData.model_name,
          },
        ]

    fetchActivityLogs(mappedData) // Call the fetch function
  }

  // Handle opening the modal to show old and new data
  // Handle opening the modal to show old and new data
  const openModal = (oldData: any, newData: any) => {
    // Filter out unwanted fields, including 'additional_info'
    const filteredOldData = Object.fromEntries(
      Object.entries(oldData || {}).filter(
        ([key]) =>
          ![
            'short_name',
            'updated_by',
            'created_at',
            'created_by',
            'updated_at',
            'screening',
            'additional_info',
            'id',
            'deleted_at',
          ].includes(key)
      )
    )
    const filteredNewData = Object.fromEntries(
      Object.entries(newData || {}).filter(
        ([key]) =>
          ![
            'short_name',
            'updated_by',
            'updated_at',
            'created_at',
            'created_by',
            'screening',
            'additional_info',
            'id',
            'deleted_at',
          ].includes(key)
      )
    )

    // Check if there is any data left after filtering
    if (Object.keys(filteredOldData).length > 0 || Object.keys(filteredNewData).length > 0) {
      setCompareData({oldData: filteredOldData, newData: filteredNewData})
      setShowModal(true)
    }
  }

  // Cell for "View Data" with filtering

  const {Column, HeaderCell, Cell} = Table

  return (
    <div className='shadow p-3 bg-white rounded my-8'>
      <div className='heading__component__title fs-2 fw-bolder mb-2 text-center'>
        USER ACTIVITY LOG
      </div>

      {/* Dropdown to select data */}
      <div style={{marginBottom: '20px'}}>
        <SelectPicker
          data={[
            {label: 'Family Details', value: 'family_details'},
            {label: 'Businesses', value: 'businesses'},
            {label: 'Ancillary Services', value: 'ancillary_services'},
            {label: 'Fatca Declarations', value: 'facta_declarations'},
            {label: 'Account Holders', value: 'account_holders'},
            {label: 'Account Opening', value: 'account_opening'}, // Added account_opening key
          ]}
          placeholder='Select Data Category'
          onChange={handleDropdownChange}
          value={selectedItem}
          style={{width: 300}}
        />
      </div>

      {/* Table */}
      <div className='datatable'>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Table data={tableData} rowHeight={90} autoHeight>
            <Column align='left' width={200}>
              <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>SN</HeaderCell>
              <Cell>{(_, rowIndex: any) => rowIndex + 1}</Cell>
            </Column>

            <Column align='left' width={250}>
              <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>
                Description
              </HeaderCell>
              <Cell dataKey='description' />
            </Column>

            <Column align='left' width={250}>
              <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>
                Modified By
              </HeaderCell>
              <Cell dataKey='causer_id' />
            </Column>

            <Column align='left' width={250}>
              <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>
                Modified At
              </HeaderCell>
              <Cell dataKey='updated_date' />
            </Column>

            <Column align='left' width={220}>
              <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>Details</HeaderCell>
              <Cell>
                {(rowData) => {
                  const filteredOldData = Object.fromEntries(
                    Object.entries(rowData.old_data || {}).filter(
                      ([key]) =>
                        !['short_name', 'updated_by', 'updated_at', 'screening'].includes(key)
                    )
                  )
                  const filteredNewData = Object.fromEntries(
                    Object.entries(rowData.new_data || {}).filter(
                      ([key]) =>
                        !['short_name', 'updated_by', 'updated_at', 'screening'].includes(key)
                    )
                  )

                  // Only show the "View Data" button if filtered data is not empty
                  if (
                    Object.keys(filteredOldData).length > 0 ||
                    Object.keys(filteredNewData).length > 0
                  ) {
                    return (
                      <Button
                        onClick={() => openModal(filteredOldData, filteredNewData)}
                        appearance='link'
                      >
                        View Data
                      </Button>
                    )
                  }
                  return null // Don't show the button if no relevant data
                }}
              </Cell>
            </Column>
          </Table>
        )}
      </div>
      {/* Modal to display old and new data */}
      <Modal open={showModal} onClose={() => setShowModal(false)} size='lg'>
        <Modal.Header>
          <Modal.Title>User Activity Log Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {compareData?.oldData && Object.keys(compareData.oldData).length > 0 ? (
            <div style={{display: 'flex', gap: '20px'}}>
              {/* Previous Data Table */}
              <div style={{flex: 1}}>
                <h5>Previous Data</h5>
                <Table
                  data={Object.entries(compareData.oldData || {}).map(([key, value]) => ({
                    key,
                    value,
                  }))}
                  rowHeight={50}
                  autoHeight
                >
                  <Column width={200}>
                    <HeaderCell>Field</HeaderCell>
                    <Cell dataKey='key' />
                  </Column>
                  <Column width={400}>
                    <HeaderCell>Value</HeaderCell>
                    <Cell dataKey='value' />
                  </Column>
                </Table>
              </div>

              {/* Latest Data Table */}
              <div style={{flex: 1}}>
                <h5>Latest Data</h5>
                <Table
                  data={Object.entries(compareData?.newData || {}).map(([key, value]) => ({
                    key,
                    value,
                  }))}
                  rowHeight={50}
                  autoHeight
                >
                  <Column width={200}>
                    <HeaderCell>Field</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        const oldValue = compareData.oldData?.[rowData.key]
                        const isChanged = oldValue !== rowData.value
                        return (
                          <div
                            style={{
                              backgroundColor: isChanged ? 'yellow' : 'transparent', // Highlight field if changed
                              padding: '5px',
                            }}
                          >
                            {rowData.key}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>
                  <Column width={400}>
                    <HeaderCell>Value</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        const oldValue = compareData.oldData?.[rowData.key]
                        const isChanged = oldValue !== rowData.value
                        return (
                          <div
                            style={{
                              backgroundColor: isChanged ? 'yellow' : 'transparent', // Highlight value if changed
                              padding: '5px',
                            }}
                          >
                            {rowData.value}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>
                </Table>
              </div>
            </div>
          ) : (
            <div style={{display: 'flex', gap: '20px'}}>
              <div style={{flex: 1}}>
                <h5>Latest Data</h5>
                <Table
                  data={Object.entries(compareData?.newData || {}).map(([key, value]) => ({
                    key,
                    value,
                  }))}
                  rowHeight={50}
                  autoHeight
                >
                  <Column width={200}>
                    <HeaderCell>Field</HeaderCell>
                    <Cell dataKey='key' />
                  </Column>
                  <Column width={400}>
                    <HeaderCell>Value</HeaderCell>
                    <Cell>
                      {(rowData) => (
                        <div
                          style={{
                            backgroundColor: 'transparent',
                            padding: '5px',
                          }}
                        >
                          {rowData.value}
                        </div>
                      )}
                    </Cell>
                  </Column>
                </Table>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)} appearance='subtle'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
