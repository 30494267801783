import {BASE_API_URL} from 'src/cms/helpers/constants/common'

//New CIF New Account End Points
export const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

// DashBoard Count
export const DashBoardCount = `${API_URL}/dashboard`

// New CIF New Account End Points Status Detail
export const StatusDetail = `${API_URL}/new-cif-new-account`

// Enquiry List CIF
export const enquiryList = `${API_URL}/customer-enquiry-info`
// Municiplities
export const GET_Municipilities = `${API_URL}/municipality`

//User Management End Points
const GET_USER_LIST = `${API_URL}/user`
const GET_USER_BY_ID = `${API_URL}/user/:id`
const GET_NORMAL_NEW_CIF_NEW_ACCOUNT = `${API_URL}/new-cif-new-account`
const ONLINE_ACCOUNT_OPENING = `${API_URL}/onlineAccountOpening`
const GET_NORMAL_NEW_CIF_NEW_ACCOUNT_BY_ID = `${API_URL}/new-cif-new-account/:id`
const POST_NORMAL_NEW_CIF_NEW_ACCOUNT = `${API_URL}/new-cif-new-account`
const NORMAL_NEW_CIF_NEW_ACCOUNT_BRANCH_CHECKER_REVIEW = `${API_URL}/new-cif-new-account`
const NORMAL_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW = `${API_URL}/new-cif-new-account`
const UPDATE_NEW_CIF_NEW_ACCOUNT_BM = `${API_URL}/new-cif-new-account`
const POST_ACCOUNT_OPENING_FORM = `${API_URL}/new-cif-new-account`
//DOCUMENT UPLOAD PRELIMINARY FORM
const POST_DOCUMENT_UPLOAD = `${API_URL}/new-cif-new-account`
// ADDITIONAL FORMS
const POST_ADDITIONAL_FORMS = `${API_URL}/new-cif-new-account`
//CORPORATE END-POINTS
const GET_CORPORATE_NEW_CIF_NEW_ACCOUNT = `${API_URL}/corporate-new-cif-new-account`
const GET_CORPORATE_NEW_CIF_NEW_ACCOUNT_BY_ID = `${API_URL}/corporate-new-cif-new-account/:id`
const POST_CORPORATE_NEW_CIF_NEW_ACCOUNT = `${API_URL}/corporate-new-cif-new-account`
const UPDATE_CORPORATE_NEW_CIF_NEW_ACCOUNT = `${API_URL}/corporate-new-cif-new-account`
const UPDATE_CORPORATE_NEW_CIF_NEW_ACCOUNT_KYC = `${API_URL}/corporate-new-cif-new-account`
const CORPORATE_NEW_CIF_NEW_ACCOUNT_BRANCH_CHECKER_REVIEW = `${API_URL}/corporate-new-cif-new-account`
const CORPORATE_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW = `${API_URL}/corporate-new-cif-new-account`
const CORPORATE_NEW_CIF_NEW_ACCOUNT_UPDATE_KYC = `${API_URL}/corporate-new-cif-new-account`
const CORPORATE_NEW_CIF_NEW_ACCOUNT_ACCOUNT_OPENING = `${API_URL}/corporate-new-cif-new-account`
const CORPORATE_NEW_CIF_NEW_ACCOUNT_QA_REVIEW = `${API_URL}/corporate-new-cif-new-account`

//NEW CIF NEW ACCOUNT BY CUSTOMER END-POINTS

//Master Data Endpoints
const GET_MASTER_DATA = `${API_URL}/master-data`
const GET_ALL_MASTER_DATA_PROVINCES_OPTIONS = `${API_URL}/province`
const GET_ALL_MASTER_DATA_ZONE_OPTIONS = `${API_URL}/zone`
const GET_ALL_MASTER_DATA_DISTRICT = `${API_URL}/district`
const GET_ALL_MASTER_DATA_DISTRICT_OPTIONS = `${API_URL}/district-category-option`
const GET_ALL_MASTER_DATA_MUNICIPALITY = `${API_URL}/municipality`
const GET_ALL_MASTER_DATA_DOCUMENT = `${API_URL}/document-types`
//ADDITIONAL FORM FIELDS API ENDPOINTS
const POST_INDIVIDUAL_ADDITIONAL_BUSINESS_FORM_FIELD = `${API_URL}/new-cif-new-account`
//  QA-UNIT API ENDPOINTS
const INDIVIDUAL_NEW_CIF_NEW_ACCOUNT_QA_REVIEW = `${API_URL}/new-cif-new-account`

const UPDATEKYCDETAILS = `${API_URL}/new-cif-new-account`

// Update KYC
const UPDATEKYC = `${API_URL}`

// Account Schema
const GetAccountSchema = `${API_URL}/account-schemas`
const GetAccountSchemaByID = `${API_URL}/account-schemas`

const PostSchema = `${API_URL}/account-schemas`
const DeleteSchema = `${API_URL}/account-schemas`

// Income Source
const GetIncomeSource = `${API_URL}/income-source`
const PostIncomeSource = `${API_URL}/income-source`

//Occupation
const Occupations = `${API_URL}/occupations`

// Risk Category Master Data API Endpoints
const GET_RISK_CATEGORY = `${API_URL}/risk-categories`
const POST_RISK_CATEGORY = `${API_URL}/risk-categories`
const GET_RISK_CATEGORY_BY_ID = `${API_URL}/risk-categories/:id`
const PUT_RISK_CATEGORY = `${API_URL}/risk-categories/:id`
const DELETE_RISK_CATEGORY = `${API_URL}/risk-categories/:id`

// Risk Sub Category
export const RiskSubCategory = `${API_URL}/risk-sub-categories`

//Pep Sub Category
export const PepSubCategory = `${API_URL}/pep-sub-category`

// Maritial Status Master Data API Endpoints
const GET_MARITAL_STATUS = `${API_URL}/marital-statuses`
const POST_MARITAL_STATUS = `${API_URL}/marital-statuses`
const GET_MARITAL_STATUS_BY_ID = `${API_URL}/marital-statuses/:id`
const PUT_MARITAL_STATUS = `${API_URL}/marital-statuses/:id`
const DELETE_MARITAL_STATUS = `${API_URL}/marital-statuses/:id`

//Tax Category Master Data API Endpoints
const GET_TAX_CATEGORY = `${API_URL}/tax-categories`
const POST_TAX_CATEGORY = `${API_URL}/tax-categories`
const GET_TAX_CATEGORY_BY_ID = `${API_URL}/tax-categories/:id`
const PUT_TAX_CATEGORY = `${API_URL}/tax-categories/:id`
const DELETE_TAX_CATEGORY = `${API_URL}/tax-categories/:id`

//Schedule Code Master Data API Endpoints
const GET_SCHEDULECODE = `${API_URL}/schedule-code`
const POST_SCHEDULECODE = `${API_URL}/schedule-code`
const GET_SCHEDULECODE_BY_ID = `${API_URL}/schedule-code/:id`
const PUT_SCHEDULECODE = `${API_URL}/schedule-code/:id`
const DELETE_SCHEDULECODE = `${API_URL}/schedule-code/:id`
//Schedule Code Master Data API Endpoints
const GET_LOANDETAILS = `${API_URL}/loan-details`
const POST_LOANDETAILS = `${API_URL}/loan-details`
const GET_LOANDETAILS_BY_ID = `${API_URL}/loan-details/:id`
const PUT_LOANDETAILS = `${API_URL}/loan-details/:id`
const DELETE_LOANDETAILS = `${API_URL}/loan-details/:id`
//Schedule Code Master Data API Endpoints
const GET_DEPOSITDETAILS = `${API_URL}/deposit-details`
const POST_DEPOSITDETAILS = `${API_URL}/deposit-details`
const GET_DEPOSITDETAILS_BY_ID = `${API_URL}/deposit-details/:id`
const PUT_DEPOSITDETAILS = `${API_URL}/deposit-details/:id`
const DELETE_DEPOSITDETAILS = `${API_URL}/deposit-details/:id`

//Salutations Master Data API Endpoints
const GET_SALUTATIONS_LIST = `${API_URL}/salutations`
const POST_SALUTATION = `${API_URL}/salutations`
const GET_SALUTATIONS_BY_ID = `${API_URL}/salutations/:id`
const PUT_SALUTATIONS = `${API_URL}/salutations/:id`
const DELETE_SALUTATIONS = `${API_URL}/salutations/:id`

//Gender Master Data API Endpoints
const GET_ALL_GENDER_LISTS = `${API_URL}/genders`
const POST_GENDERS = `${API_URL}/genders`
const GET_GENDERS_BY_ID = `${API_URL}/genders/:id`
const PUT_GENDER = `${API_URL}/genders/:id`
const DELETE_GENDER = `${API_URL}/genders/:id`

//Sectors
const GET_ALL_SECTORS = `${API_URL}/sectors`
const POST_SECTORS = `${API_URL}/sectors`
const GET_ALL_SECTORS_BY_ID = `${API_URL}/sectors/:id`
const PUT_SECTORS = `${API_URL}/sectors/:id`
const DELETE_SECTORS = `${API_URL}/sectors/:id`

//Annual Incomes
const GET_ALL_ANNUAL_INCOMES = `${API_URL}/annual-incomes`
const POST_ANNUAL_INCOMES = `${API_URL}/annual-incomes`
const GET_ANNUAL_INCOME_BY_ID = `${API_URL}/annual-incomes/:id`
const PUT_ANNUAL_INCOME = `${API_URL}/annual-incomes/:id`
const DELETE_ANNUAL_INCOME = `${API_URL}/annual-incomes/:id`

//ID Types
const GET_ALL_ID_TYPES = `${API_URL}/id-types`
const POST_ID_TYPES = `${API_URL}/id-types`
const GET_ID_TYPES_BY_ID = `${API_URL}/id-types/:id`
const PUT_ID_TYPES = `${API_URL}/id-types/:id`
const DELETE_ID_TYPES = `${API_URL}/id-types/:id`

//Annual Tansactions
const GET_ALL_ANNUAL_TRANSACTIONS = `${API_URL}/annual-transactions`
const POST_ANNUAL_TRANSACTIONS = `${API_URL}/annual-transactions`
const GET_ANNUAL_TRANSACTIONS_BY_ID = `${API_URL}/annual-transactions/:id`
const PUT_ANNUAL_TRANSACTIONS = `${API_URL}/annual-transactions/:id`
const DELETE_ANNUAL_TRANSACTIONS = `${API_URL}/annual-transactions/:id`

//Existing CIF New Account APIS
const GET_EXISTING_CIF_GET_DETAILS = `${API_URL}/existing-cif/get-details`
const GET_LIST_EXISTING_CIF_NEW_ACCOUNT = `${API_URL}/existing-cif/list`
const GET_EXISTING_CIF_GET_DETAILS_BY_CUSTOMER_ID = `${API_URL}/existing-cif/:id`
const POST_BRANCH_MAKER_INIT_CASE = `${API_URL}/existing-cif/init-case`
const POST_BRANCH_CHECKER_REVIEW = `${API_URL}/existing-cif/:id/branch-checker-review`
const POST_BRANCH_MAKER_DOCUMENT_UPLOAD = `${API_URL}/existing-cif/:id/documents`
const POST_BRANCH_MAKER_DOCUMENT_FORM = `${API_URL}/existing-cif/:id/branch-maker-document-form`
const POST_QA_REVIEW = `${API_URL}/existing-cif/:id/qa-review`
const POST_CPU_MAKER_STORE_API = `${API_URL}/existing-cif/:id/store-acc-form`
const POST_CPU_CHECKER_REVIEW = `${API_URL}/existing-cif/:id/cpu-checker-review`

// Linked Entites API Endpoints
const GET_ALL_LINKED_ENTITIES = `${API_URL}/linked-entities`
const GET_LINKED_ENTITIES_DATA = `${API_URL}/linked-entities/get-details`
const POST_CPU_MAKER_LINKED_ENTITIES_DATA = `${API_URL}/linked-entities`
const GET_LINKED_ENTITIES_BY_ID = `${API_URL}/linked-entities/:id/show`
const POST_CPU_CHECKER_REVIEW_URL = `${API_URL}/linked-entities/:id/cpu-checker-review`
const PATCH_CPU_MAKER_UPDATE_URL = `${API_URL}/linked-entities/:id`

// Relationship
const RelationshipRoute = `${API_URL}/relationship`

// Religion
const ReligionRoute = `${API_URL}/religions`

//Education Qualifications
const EducationRoute = `${API_URL}/education-qualifications`

//Account Purpose
const AccountPurposeRoute = `${API_URL}/account-purposes`

//Account Category
const AccountCategoryRoute = `${API_URL}/account-categories`

// Registration With
const RegistrationWithRoute = `${API_URL}/corporate-registration-with`

// Business Nature
const BusinessNatureRoute = `${API_URL}/business-natures`

// Individual_Unique_Id
const Individual_Unique_Id = `${API_URL}/individual-unique-id`

//Pep Category
export const Pep_category = `${API_URL}/pep-category`

// Master Data Export/Download/Import
const masterData = `${API_URL}/master-data`

// NEw CIF Individual
const GET_NEW_CIF = `${API_URL}/new-cif`
const UPDATE_NEW_CIF_BM = `${API_URL}/new-cif`
export const POST_ADDITIONAL_FORMS_CIF = `${API_URL}/new-cif`
export const POST_ADDITIONAL_FORMS_EXISTING_CIF = `${API_URL}/existing-cif`
export const NORMAL_NEW_CIF_BRANCH_CHECKER_REVIEW = `${API_URL}/new-cif`

// New CIF Corporate
const GET_NEW_CIF_Corporate = `${API_URL}/corporate-new-cif`
const POST_NORMAL_NEW_CIF = `${API_URL}/new-cif`
export const UPDATE_CORPORATE_NEW_CIF = `${API_URL}/corporate-new-cif`
export const GET_CORPORATE_NEW_CIF_BY_ID = `${API_URL}/corporate-new-cif/:id`

// Account Maintenance
export const ACCOUT_MAINTENANCE_URI = `${API_URL}/account-maintenance`
// Account Closure
export const ACCOUNT_CLOSURE_URI = `${API_URL}/accountClosure`

export {
  GET_NORMAL_NEW_CIF_NEW_ACCOUNT,
  GET_NORMAL_NEW_CIF_NEW_ACCOUNT_BY_ID,
  POST_NORMAL_NEW_CIF_NEW_ACCOUNT,
  ONLINE_ACCOUNT_OPENING,
  GET_MASTER_DATA,
  UPDATE_CORPORATE_NEW_CIF_NEW_ACCOUNT,
  GET_ALL_MASTER_DATA_DISTRICT,
  GET_ALL_MASTER_DATA_DISTRICT_OPTIONS,
  GET_ALL_MASTER_DATA_PROVINCES_OPTIONS,
  GET_ALL_MASTER_DATA_ZONE_OPTIONS,
  GET_ALL_MASTER_DATA_MUNICIPALITY,
  GET_CORPORATE_NEW_CIF_NEW_ACCOUNT,
  GET_CORPORATE_NEW_CIF_NEW_ACCOUNT_BY_ID,
  POST_CORPORATE_NEW_CIF_NEW_ACCOUNT,
  CORPORATE_NEW_CIF_NEW_ACCOUNT_BRANCH_CHECKER_REVIEW,
  CORPORATE_NEW_CIF_NEW_ACCOUNT_QA_REVIEW,
  NORMAL_NEW_CIF_NEW_ACCOUNT_BRANCH_CHECKER_REVIEW,
  INDIVIDUAL_NEW_CIF_NEW_ACCOUNT_QA_REVIEW,
  POST_DOCUMENT_UPLOAD,
  GET_ALL_MASTER_DATA_DOCUMENT,
  UPDATEKYCDETAILS,
  CORPORATE_NEW_CIF_NEW_ACCOUNT_UPDATE_KYC,
  CORPORATE_NEW_CIF_NEW_ACCOUNT_ACCOUNT_OPENING,
  POST_INDIVIDUAL_ADDITIONAL_BUSINESS_FORM_FIELD,
  CORPORATE_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW,
  UPDATE_NEW_CIF_NEW_ACCOUNT_BM,
  POST_ADDITIONAL_FORMS,
  POST_ACCOUNT_OPENING_FORM,
  NORMAL_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW,
  UPDATEKYC,
  GetAccountSchema,
  PostSchema,
  DeleteSchema,
  GetAccountSchemaByID,
  GetIncomeSource,
  PostIncomeSource,
  Occupations,
  GET_USER_BY_ID,
  // Existing CIF New Account APIS
  GET_LIST_EXISTING_CIF_NEW_ACCOUNT,
  POST_BRANCH_MAKER_INIT_CASE,
  GET_EXISTING_CIF_GET_DETAILS,
  GET_EXISTING_CIF_GET_DETAILS_BY_CUSTOMER_ID,
  POST_BRANCH_CHECKER_REVIEW,
  POST_BRANCH_MAKER_DOCUMENT_UPLOAD,
  POST_BRANCH_MAKER_DOCUMENT_FORM,
  POST_QA_REVIEW,
  POST_CPU_MAKER_STORE_API,
  POST_CPU_CHECKER_REVIEW,
  //Master data risk category
  GET_RISK_CATEGORY,
  POST_RISK_CATEGORY,
  GET_RISK_CATEGORY_BY_ID,
  PUT_RISK_CATEGORY,
  DELETE_RISK_CATEGORY,
  //Marital status master data
  GET_MARITAL_STATUS,
  POST_MARITAL_STATUS,
  GET_MARITAL_STATUS_BY_ID,
  PUT_MARITAL_STATUS,
  DELETE_MARITAL_STATUS,
  //Tax category master data
  GET_TAX_CATEGORY,
  POST_TAX_CATEGORY,
  GET_TAX_CATEGORY_BY_ID,
  PUT_TAX_CATEGORY,
  DELETE_TAX_CATEGORY,
  //Schedule Code master data
  GET_SCHEDULECODE,
  POST_SCHEDULECODE,
  GET_SCHEDULECODE_BY_ID,
  PUT_SCHEDULECODE,
  DELETE_SCHEDULECODE,
  //Schedule Code master data
  GET_LOANDETAILS,
  POST_LOANDETAILS,
  GET_LOANDETAILS_BY_ID,
  PUT_LOANDETAILS,
  DELETE_LOANDETAILS,
  //Schedule Code master data
  GET_DEPOSITDETAILS,
  POST_DEPOSITDETAILS,
  GET_DEPOSITDETAILS_BY_ID,
  PUT_DEPOSITDETAILS,
  DELETE_DEPOSITDETAILS,
  // Salutations Data Lists
  GET_SALUTATIONS_LIST,
  POST_SALUTATION,
  GET_SALUTATIONS_BY_ID,
  PUT_SALUTATIONS,
  DELETE_SALUTATIONS,
  //Gender master data
  GET_ALL_GENDER_LISTS,
  GET_GENDERS_BY_ID,
  POST_GENDERS,
  PUT_GENDER,
  DELETE_GENDER,
  //SECTORES
  GET_ALL_SECTORS,
  GET_ALL_SECTORS_BY_ID,
  POST_SECTORS,
  PUT_SECTORS,
  DELETE_SECTORS,
  //Annual Incomes
  GET_ALL_ANNUAL_INCOMES,
  GET_ANNUAL_INCOME_BY_ID,
  POST_ANNUAL_INCOMES,
  PUT_ANNUAL_INCOME,
  DELETE_ANNUAL_INCOME,
  //ID Types
  GET_ALL_ID_TYPES,
  GET_ID_TYPES_BY_ID,
  POST_ID_TYPES,
  PUT_ID_TYPES,
  DELETE_ID_TYPES,
  //Annual Tansactions
  GET_ALL_ANNUAL_TRANSACTIONS,
  POST_ANNUAL_TRANSACTIONS,
  GET_ANNUAL_TRANSACTIONS_BY_ID,
  PUT_ANNUAL_TRANSACTIONS,
  DELETE_ANNUAL_TRANSACTIONS,
  RelationshipRoute,
  ReligionRoute,
  EducationRoute,
  AccountPurposeRoute,
  AccountCategoryRoute,
  RegistrationWithRoute,
  BusinessNatureRoute,
  Individual_Unique_Id,
  masterData,
  GET_NEW_CIF,
  GET_NEW_CIF_Corporate,
  POST_NORMAL_NEW_CIF,
  UPDATE_NEW_CIF_BM,
  //Linked entities
  GET_ALL_LINKED_ENTITIES,
  GET_LINKED_ENTITIES_DATA,
  POST_CPU_CHECKER_REVIEW_URL,
  GET_LINKED_ENTITIES_BY_ID,
  POST_CPU_MAKER_LINKED_ENTITIES_DATA,
  PATCH_CPU_MAKER_UPDATE_URL,
}
