import React, {useEffect, useState} from 'react'
import {FieldArray, useFormikContext} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import usePostEnquiryList from 'src/hooks/APIMSEnquiry/usePostGetEnquiryList'
import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'

const DirectorsTable = ({values, handleChange, errors, touched}: any) => {
  const {setFieldValue} = useFormikContext()
  const {mutate: mutateEnquiry} = usePostEnquiryList()
  const [loadingStates, setLoadingStates] = useState<{[key: number]: boolean}>({}) // Track loading state for each button

  const handleEnquiry = (index: number, cif_number: string) => {
    if (cif_number) {
      setLoadingStates((prevState) => ({
        ...prevState,
        [index]: true, // Set the loading state to true for the current button
      }))

      const payload = {
        customer_id: cif_number, // Replace this with dynamic value if needed
      }

      mutateEnquiry(payload, {
        onSuccess: (response: any) => {
          const fullName = response?.data?.generalDetails[0]?.CUST_NAME || ''
          setFieldValue(`directors.${index}.full_name`, fullName)
        },
        onError: (error: any) => {
          console.error('Error fetching data:', error)
        },
        onSettled: () => {
          setLoadingStates((prevState) => ({
            ...prevState,
            [index]: false, // Reset the loading state for the current button
          }))
        },
      })
    }
  }
  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )

  return (
    <>
      <h3 className='heading__component__title fw-bolder text-uppercase'>
        F. Name and designation of
        Individuals/Proprietor/Partner/Director/Trustee/Members/CEO/Senior Management/Authorised
        Signatory & Shareholders holding 10% & above Shares :
      </h3>
      <FieldArray name='directors'>
        {({remove, push}) => (
          <div>
            {values?.directors?.length > 0 ? (
              values?.directors?.map((director: any, index: any) => (
                <div className='row mb-3' key={index}>
                  <div className='col-md-5'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Cif Number'
                      name={`directors.${index}.cif_number`}
                      label='Cif Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      maxLength={9}
                      required
                    />
                  </div>
                  <div className='col-md-5'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Full Name'
                      name={`directors.${index}.full_name`}
                      label='Full Name'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-5'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Designation'
                      name={`directors.${index}.designation`}
                      label='Designation'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-5'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Relation Type'
                      label='Relation Type'
                      values={values}
                      name='account_relation_id'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={allMasterDataOptions?.account_relations?.map((relations: any) => ({
                        value: relations.id || relations.value,
                        label: relations.title || relations.label,
                      }))}
                      setFieldValue={setFieldValue}
                      required
                      // colors={true}
                    />
                  </div>
                  <div className='col-md-8 gap-4 d-flex align-items-center'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() => handleEnquiry(index, values.directors[index]?.cif_number)}
                      disabled={loadingStates[index]} // Disable only the specific button
                    >
                      {loadingStates[index] ? 'Loading...' : 'Enquiry'}
                    </button>
                    <button type='button' className='btn btn-danger' onClick={() => remove(index)}>
                      Remove
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No Directors added. Click 'Add Directors' to add.</p>
            )}
            <button
              type='button'
              className='btn btn-primary mb-3'
              onClick={() => push({full_name: '', designation: '', cif_number: ''})}
            >
              Add Director
            </button>
          </div>
        )}
      </FieldArray>
    </>
  )
}

export default DirectorsTable
