import {useEffect, useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {BASE_APP_URL} from '../../constants/common'
// @ts-ignore
import ImageZoom from 'react-image-zooom'
import {Viewer} from '@react-pdf-viewer/core'
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'
import {zoomPlugin} from '@react-pdf-viewer/zoom'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/zoom/lib/styles/index.css'
import {TIFFViewer} from 'react-tiff'

interface DocumentsPreviewProps {
  data?: any
  isOnline?: boolean // New prop to decide which URL to use
}

const DocumentsPreview = ({data, isOnline}: DocumentsPreviewProps) => {
  const [index, setIndex] = useState('1')

  const titleCounts: {[key: string]: number} = {}

  const isPdf = (filePath: any) => {
    return (
      filePath?.file_path?.toLowerCase()?.endsWith('.pdf') ||
      filePath?.front_img?.toLowerCase()?.endsWith('.pdf') ||
      filePath?.back_img?.toLowerCase()?.endsWith('.pdf')
    )
  }

  const accountDocs = data?.account_documents?.documents ?? []
  const additionalDocs = data?.additional_documents?.documents ?? []
  const extraAdditionalDocs = data?.additional_documents?.additional_documents ?? []
  const generalDocs = data?.documents?.documents ?? []
  const generalDocsForExisting = data?.data?.account_details?.documents?.documents ?? []
  const extraAdditionalDocsForExisting =
    data?.data?.account_details?.additional_documents?.additional_documents ?? []
  const extraSecondAdditionalDocsForExisting =
    data?.data?.account_details?.additional_documents?.documents ?? []

  const mergedDocuments = [
    ...accountDocs,
    ...additionalDocs,
    ...extraAdditionalDocs,
    ...generalDocs,
    ...generalDocsForExisting,
    ...extraAdditionalDocsForExisting,
    ...extraSecondAdditionalDocsForExisting,
  ]

  const getUniqueTitle = (title: string, index: number) => {
    if (!titleCounts[title]) {
      titleCounts[title] = 1
    } else {
      titleCounts[title] += 1
    }
    return `${title} ${titleCounts[title] > 1 ? titleCounts[title] : ''}`
  }

  const convertBase64ToBlobUrl = (base64Data: string) => {
    const byteCharacters = atob(base64Data.split(',')[1])
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {type: 'image/tiff'})
    return URL.createObjectURL(blob)
  }

  const enhancedDocuments = mergedDocuments.flatMap((document, index) => {
    if (document.documentType?.title?.toLowerCase() === 'citizenship') {
      const enhancedEntries = []

      // Check for front_img and back_img
      if (document?.front_img) {
        enhancedEntries.push({
          ...document,
          customTabKey: 'citizenship-front-' + document.id,
          customTitle: 'Citizenship Front',
          customUrl: document?.front_img,
        })
      }
      if (document?.back_img) {
        enhancedEntries.push({
          ...document,
          customTabKey: 'citizenship-back-' + document.id,
          customTitle: 'Citizenship Back',
          customUrl: document?.back_img,
        })
      }

      // If neither front_img nor back_img exists, use file_path
      if (!document?.front_img && !document?.back_img && document?.file_path) {
        enhancedEntries.push({
          ...document,
          customTabKey: 'citizenship-' + document.id,
          customTitle: 'Citizenship',
          customUrl: document?.file_path,
        })
      }

      return enhancedEntries
    }
    return [document]
  })

  const getDocumentUrl = (filePath: string) => {
    return isOnline
      ? `${BASE_APP_URL}/framework/${filePath}`
      : `${BASE_APP_URL}/framework/${filePath}`
  }

  const zoomPluginInstance = zoomPlugin()

  const CustomToolbar = () => {
    const {ZoomInButton, ZoomOutButton} = zoomPluginInstance
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <ZoomOutButton />
        <ZoomInButton />
      </div>
    )
  }

  return (
    <div>
      <Tabs
        defaultActiveKey={enhancedDocuments?.[0]?.documentType?.cbs_code}
        id='uncontrolled-tab-example'
      >
        {enhancedDocuments?.map(
          (
            document: {
              id: string
              file_path: string
              base64_data: string
              documentType: {title: string; cbs_code: string}
              customTabKey?: string
              customTitle?: string
              customUrl?: string
            },
            index: number
          ) => {
            const tabTitle =
              document.customTitle || getUniqueTitle(document?.documentType?.title, index)

            return (
              <Tab
                eventKey={
                  document.customTabKey || document?.id + '-' + document?.documentType?.cbs_code
                }
                title={tabTitle}
                onEntered={() => setIndex((index + 1).toString())}
                key={document?.id}
              >
                {isPdf(document) ? (
                  <div
                    style={{
                      border: '2px solid rgba(0, 0, 0, 0.3)',
                      height: '750px',
                    }}
                  >
                    <CustomToolbar />
                    <Viewer
                      fileUrl={
                        document?.customUrl
                          ? getDocumentUrl(document?.customUrl)
                          : getDocumentUrl(document?.file_path)
                      }
                      plugins={[zoomPluginInstance]}
                    />
                  </div>
                ) : document?.file_path?.toLowerCase()?.endsWith('.tif') &&
                  document?.base64_data ? (
                  <div style={{width: '100%', height: '750px', overflow: 'auto'}}>
                    <TIFFViewer
                      tiff={convertBase64ToBlobUrl(document.base64_data)}
                      lang='en'
                      buttonColor='#141414'
                      buttonLabel='View Document'
                      width='80%'
                      height='100%'
                    />
                  </div>
                ) : (
                  <ImageZoom
                    src={
                      document?.customUrl
                        ? getDocumentUrl(document?.customUrl)
                        : getDocumentUrl(document?.file_path)
                    }
                    alt='Document Preview'
                    zoomStyle={{
                      border: '1px solid gray',
                      borderRadius: '10px',
                      marginTop: '10px',
                    }}
                    zoomWidth={700}
                    zoomHeight={600}
                    style={{
                      width: '100%',
                      height: '400px',
                      objectFit: 'contain',
                      border: '1px solid gray',
                      borderRadius: '10px',
                      marginTop: '10px',
                    }}
                  />
                )}
              </Tab>
            )
          }
        )}
      </Tabs>
    </div>
  )
}

export default DocumentsPreview
