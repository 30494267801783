import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table, Modal, Button} from 'rsuite'
import {isEmpty} from 'lodash'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Close, Search} from '@rsuite/icons'
import {UserActivityLogAll} from 'src/app/modules/common/components/AllActivityLog'
import {AccountStatusLog} from 'src/app/modules/common/components/AccountStatusLog'

const Cell = Table.Cell

const RpaIndividualJointMinorTable = ({
  newCIFandAccountListData,
  setParams,
  params,
  loading,
  roleName,
}: any) => {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [modalRowData, setModalRowData] = useState(null)

  const handleModalOpen = (rowData: any) => {
    setModalRowData(rowData) // Set the row data for the modal
    setShowModal(true)
  }

  const handleModalClose = () => {
    setModalRowData(null) // Clear the row data when closing the modal
    setShowModal(false)
  }

  const data =
    newCIFandAccountListData?.data?.preliminaries?.map((newAccount: any, i: any) => ({
      ...newAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      account_name: `${newAccount?.first_name.toUpperCase()} ${
        newAccount.middle_name ? newAccount.middle_name.toUpperCase() + ' ' : ''
      }${newAccount?.last_name.toUpperCase()}`,
      updatedOn: newAccount?.account_status?.updated_date,
      updatedBy: newAccount?.account_status?.updated_by,
      remarks: newAccount?.account_status?.remarks ?? '',
      status: newAccount?.account_status?.current_status ?? '',
      btnStatus: newAccount?.btn_status ?? '',
      branchCode: newAccount?.branch_code ?? '',
      customerCode: newAccount?.customer_code ?? '',
    })) ?? []



  const handleChangeLimit = (dataKey: any) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {


    const handleButtonClick = () => {
      switch (roleName) {
        case 'branch_maker':
          if (rowData.btnStatus === 'Continue') {
            history.push({
              pathname: `/new-cif-new-accounts/review-and-update/normal-preliminary-form/${rowData?.id}`,
              state: rowData,
            })
          } else if (rowData.btnStatus === 'Review') {
            history.push({
              pathname: `/new-cif-new-accounts/review-and-update/normal-preliminary-form/${rowData?.id}`,
              state: rowData,
            })
          } else if (rowData.btnStatus === 'Upload Document') {
            history.push({
              pathname: `/new-cif-new-accounts/normal-preliminary-form/document-upload/${rowData?.id}`,
              state: rowData,
            })
          } else {
            history.push({
              pathname: `/new-cif-new-accounts/create-new-cif-and-account/biometric-verification/${rowData?.id}`,
              state: rowData,
            })
          }
          break
        case 'branch_checker':
          if (rowData.btnStatus === 'Continue') {
            history.push({
              pathname: `/new-cif-new-accounts/branch-checker-normal/review/${rowData?.id}`,
              state: rowData,
            })
          } else if (rowData.btnStatus === 'Review') {
            history.push({
              pathname: `/new-cif-new-accounts/branch-checker-normal/review/${rowData?.id}`,
              state: rowData,
            })
          } else {
            history.push({
              pathname: `/new-cif-new-accounts/create-new-cif-and-account/biometric-verification/${rowData?.id}`,
              state: rowData,
            })
          }
          break
        case 'cpu_checker':
          if (rowData.btnStatus === 'Continue') {
            history.push({
              pathname: `/new-cif-new-accounts/individual/cpu-checker/${rowData?.id}`,
              state: rowData,
            })
          } else if (rowData.btnStatus === 'Review') {
            history.push({
              pathname: `/new-cif-new-accounts/individual/cpu-checker/${rowData?.id}`,
              state: rowData,
            })
          } else {
            history.push({
              pathname: `/new-cif-new-accounts/create-new-cif-and-account/biometric-verification/${rowData?.id}`,
              state: rowData,
            })
          }
          break
        case 'cpu_maker':
          if (rowData.btnStatus === 'Continue') {
            history.push({
              pathname: `/new-cif-new-accounts/individual-joint-minor/kyc/${rowData?.id}`,
              state: rowData,
            })
          } else if (rowData.btnStatus === 'Review') {
            history.push({
              pathname: `/new-cif-new-accounts/review-individual-joint-minor/kyc/${rowData?.id}`,
              state: rowData,
            })
          } else {
            history.push({
              pathname: `/create-new-cif-and-account/biometric-verification/${rowData?.id}`,
              state: rowData,
            })
          }
          break
        case 'qa':
          if (rowData.btnStatus === 'Continue') {
            history.push({
              pathname: `/new-cif-new-accounts/qa-review-and-update/normal-preliminary-form/${rowData?.id}`,
              state: rowData,
            })
          } else if (rowData.btnStatus === 'Review') {
            history.push({
              pathname: `/new-cif-new-accounts/qa-review-and-update/normal-preliminary-form/${rowData?.id}`,
              state: rowData,
            })
          } else {
            history.push({
              pathname: `/new-cif-new-accountscreate-new-cif-and-account/biometric-verification/${rowData?.id}`,
              state: rowData,
            })
          }
          break
        default:
          break
      }
    }

    return (
      <Cell {...props} className='link-group'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end', // Align buttons to the right
            alignItems: 'center', // Center-align buttons vertically
            gap: '10px', // Add spacing between buttons
          }}
        >
          <button
            className={
              rowData?.btnStatus == 'Continue'
                ? 'dt-btn dt-btn-outline-primary dt-btn-sm'
                : 'btn btn-primary btn-sm '
            }
            style={{
              // backgroundColor: rowData?.btnStatus === "Continue" ? "#fff" : "#007bff",
              // color: rowData?.btnStatus === "Continue" ? "#007bff" : "#fff",
              // border: rowData?.btnStatus === "Continue" ? "1px solid #007bff" : "none",
              // padding: "5px 10px",
              // fontSize: '12px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={handleButtonClick}
          >
            {rowData.btnStatus}
          </button>
          <button
            className='btn btn-secondary btn-sm ml-2'
            style={{
              // backgroundColor: "#6c757d", // Secondary button color
              // color: "#fff",
              // border: "none",
              // padding: "5px 10px",
              // fontSize: '12px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleModalOpen(rowData)}
          >
            History
          </button>
        </div>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 50,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Action',
      width: 230,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell fullText dataKey='remarks' />,
      sortable: false,
      width: 230,
    },

    {
      label: 'Status',
      dataKey: 'status',
      cell: <Cell dataKey='status' />,
      sortable: false,
      width: 180,
    },
    {
      label: 'Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Branch',
      dataKey: 'branchCode',
      cell: <Cell dataKey='branchCode' />,
      sortable: false,
      width: 65,
    },
    {
      label: 'Customer Code',
      dataKey: 'customerCode',
      cell: <Cell dataKey='customerCode' />,
      sortable: false,
      width: 130,
    },
    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 140,
    },
    {
      label: 'Updated By',
      dataKey: 'updatedBy',
      cell: <Cell dataKey='updatedBy' />,
      sortable: false,
      width: 160,
    },
  ]

  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params?.search}
          onChange={(value) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={newCIFandAccountListData?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>

      <Modal open={showModal} onClose={handleModalClose} size='lg'>
        <Modal.Body>
          <AccountStatusLog data={modalRowData} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModalClose} appearance='primary'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RpaIndividualJointMinorTable
